import React, { Component } from 'react';
import { SelectFormik } from '@massiveinfinity/components/Form/FormikInputs';
import { getUsersTiedToAccount } from '../api';
import { CircularProgress } from '@material-ui/core';

import _ from 'lodash';

class UsersSelect extends Component {
  state = {
    users: [],
    isUserLoading: false
  };

  componentDidMount() {
    this.setState({
      isUserLoading: true
    });
    getUsersTiedToAccount(this.props.accountID)
      .then(users => this.setFiltertoState(users.data, this.props.locationID))
      .catch(err => console.log(err));
  }

  setFiltertoState = (usersArray, locationID) => {
    let filteredUsersArray = _.remove(usersArray, function(item) {
      return (
        typeof item == 'object' &&
        (item.user_role == 20 || item.user_role == 0) &&
        (item.assigned_locations == locationID ||
          item.assigned_locations.length == 0)
      );
    });

    let newArr = _.map(filteredUsersArray, user =>
      _.pick(user, ['name', 'user_id'])
    );

    const mutatedUserArr = newArr.map(x => {
      return {
        label: x.name,
        value: x.user_id
      };
    });
    this.setState({
      users: mutatedUserArr,
      isUserLoading: false
    });
  };

  render() {
    const { users } = this.state;

    return this.state.isUserLoading ? (
      <CircularProgress></CircularProgress>
    ) : (
      <SelectFormik options={users} {...this.props} />
    );
  }
}

export default UsersSelect;
