import React from 'react';

const BellAlertIcon = ({ colorCode }) => (
  <svg
    width="50"
    height="30"
    viewBox="0 0 130.69 175.31"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={colorCode}
      d="M94.112,160.176c-4.133,9.343-13.486,15.882-24.342,15.882c-10.856,0-20.21-6.539-24.342-15.882
		H94.112z M69.768,16.821c7.164,0,14.043,1.264,20.427,3.578v-0.786C90.195,8.798,81.397,0,70.583,0h-1.625
		C58.143,0,49.344,8.798,49.344,19.613v0.776C55.725,18.082,62.602,16.821,69.768,16.821z M139.418,145.812
		c0.503-2.363-0.575-4.694-2.759-5.703c-0.519-0.336-4.42-3.064-8.326-11.319c-7.174-15.158-8.68-36.511-8.68-51.755
		c0-0.066-0.001-0.132-0.004-0.198c-0.106-27.414-22.441-49.683-49.881-49.683c-27.504,0-49.881,22.376-49.881,49.881
		c0,15.244-1.506,36.597-8.68,51.755c-3.906,8.254-7.808,10.983-8.326,11.319c-2.184,1.009-3.261,3.341-2.759,5.703
		c0.507,2.386,2.742,4.031,5.181,4.031h128.934C136.676,149.843,138.91,148.198,139.418,145.812z M136.717,140.145h0.003H136.717z"
    />
  </svg>
);

export default BellAlertIcon;
