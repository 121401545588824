import React, { useEffect, useState } from "react";
// how
import { Typography } from "@material-ui/core";

const LocationWrapper = ({}) => {
  const [countryName, setCountryName] = useState("");
  const [temperatureValue, setTemperatureValue] = useState("");
  const [windSpeed, setWindSpeed] = useState("");
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    try {
      getWeather();
    } catch (err) {
      console.log("err: ", err);
    }
  }, []);

  const getCurrentWeather = async () => {
    const response = await fetch(
      `https://api.openweathermap.org/data/2.5/weather?lat=1.290270&lon=103.851959&appid=2eb4b0a4563785179d165d2e7e4b96fc&units=metric`
    );
    const json = await response.json();
    return json;
  };

  const getWeather = async () => {
    const data = await getCurrentWeather();

    const { sys, main, wind, weather } = data;
    const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
    setCountryName(regionNames.of(sys.country));
    setIcon(getIcon(weather[0].icon));
    setTemperatureValue(main.temp);
    setWindSpeed(wind.speed);
  };

  const getIcon = (icon) => {
    switch (icon) {
      case "01d":
        return <i class="las la-sun"></i>;
      case "01n":
        return <i class="las la-moon"></i>;
      case "02d":
        return <i class="las la-cloud-sun"></i>;
      case "02n":
        return <i class="las la-cloud-moon"></i>;
      case "03d":
      case "03n":
      case "04d":
      case "04n":
        return <i class="las la-cloud"></i>;
      case "09d":
      case "09n":
        return <i class="las la-cloud-showers-heavy"></i>;
      case "10d":
        return <i class="las la-cloud-sun-rain"></i>;
      case "10n":
        return <i class="las la-cloud-moon-rain"></i>;
      case "11d":
      case "11n":
        return <i class="las la-bolt"></i>;
      case "13d":
      case "13n":
        return <i class="las la-snowflake"></i>;
      case "50d":
      case "50n":
        return <i class="las la-smog"></i>;
    }
  };

  return (
    <div className="flex items-center">
      {icon}
      <Typography className="text-white font-300 pl-8">
        {countryName}
      </Typography>
      <Typography className="text-white font-300 pl-8">
        {temperatureValue}&deg;C
      </Typography>
      <Typography className="text-white font-300 pl-8">;</Typography>
      <Typography className="text-white font-300 pl-8">
        {windSpeed}m/sec
      </Typography>
    </div>
  );
};

export default LocationWrapper;
