import * as Actions from '../../actions/pylon/index';

const initialState = {
    energy: {
        isLoading: true,
        success: false,
        message: '',
        data: {}
    },
    profile: {
        success: false,
        message: '',
        data: {}
    }
}

const toolbar = function(state = initialState, action) {
    switch (action.type) {
        case Actions.LOADING_GET_TOTAL_ENERGY_GENERATED: {
            return {
                ...state,
                energy: {
                    ...state.energy,
                    isLoading: true
                }
            };
        }

        case Actions.GET_TOTAL_ENERGY_GENERATED: {
            return {
                ...state,
                energy: {
                    isLoading: false,
                    success: true,
                    data: action.payload
                }
            };
        }

        case Actions.ERROR_GET_TOTAL_ENERGY_GENERATED: {
            return {
                ...state,
                energy: {
                    isLoading: false,
                    success: false,
                    message: 'An error has occurred',
                    data: {}
                }
            };
        }

        case Actions.GET_USER_PROFILE: {
            return {
                ...state,
                profile: {
                    success: true,
                    data: action.payload
                }
            };
        }

        case Actions.ERROR_GET_USER_PROFILE: {
            return {
                ...state,
                profile: {
                    success: false,
                    message: 'An error has occurred'
                }
            }
        }

        default: {
            return state;
        }
    }
}

export default toolbar;