import React, { Component } from "react";
import { bindActionCreators } from "redux";
import AssetTab from "./tabs/AssetTab";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import connect from "react-redux/es/connect/connect";

const styles = (theme) => ({});

class AssetPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: this.props.match.params.accountId,
      locationId: this.props.match.params.locationId,
      inverters: [],
    };
  }

  render() {
    const { accountId, locationId } = this.state;
    return (
      <div>
        <AssetTab
          accountId={this.state.accountId}
          locationId={this.state.locationId}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps({ loc }) {
  return bindActionCreators ({});
}

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(AssetPage)
  )
);
