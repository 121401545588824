import React, { Component } from "react";

class DisplayAccountType extends Component {
  state = {};
  render() {
    const { type } = this.props;
    return (
      <div>
        {type == 0
          ? "Member"
          : type == 10
          ? "Region Admin"
          : type == 20
          ? "Location Admin"
          : type == 30
          ? "Account Admin"
          : type == 99
          ? "Master"
          : "Unknown"}
      </div>
    );
  }
}

export default DisplayAccountType;
