import React from "react";
import PropTypes from "prop-types";
import SelectWithMUI from "../../../../SelectWithMUI";
import { fieldRenderProps } from "../../types";

const SelectFormik = ({
  field,
  form,
  options,
  isMulti = false,
  ...otherProps
}) => {
  const onChange = (selectedOption, { action }) => {
    form.setFieldValue(
      field.name,
      action === "clear"
        ? undefined
        : isMulti
        ? selectedOption.map(item => item.value)
        : selectedOption.value
    );
  };

  const getValue = () => {
    if (options && field.value) {
      return isMulti
        ? options.filter(option => field.value.indexOf(option.value) >= 0)
        : options.find(option => option.value === field.value) || "";
    } else {
      return isMulti ? [] : "";
    }
  };

  const handleBlur = () => form.setFieldTouched(field.name);
  // handle touched state implicitly by using form.setFieldTouched instead of field.onBlur
  // Formik's field.onBlur is not working well with react-select
  // See more: https://gist.github.com/hubgit/e394e9be07d95cd5e774989178139ae8#gistcomment-2890020

  const isFieldTouched = form.touched[field.name];
  const fieldError = form.errors[field.name];
  const isFieldErrored = Boolean(fieldError);

  return (
    <SelectWithMUI
      {...field}
      {...otherProps}
      error={isFieldTouched && isFieldErrored}
      helperText={isFieldTouched && fieldError}
      value={getValue()}
      onChange={onChange}
      onBlur={handleBlur}
      options={options}
      isMulti={isMulti}
    />
  );
};

SelectFormik.propTypes = {
  ...SelectWithMUI.propTypes,
  ...fieldRenderProps
};

export default SelectFormik;
