import React, { Component } from "react";
import Formsy from "formsy-react";
import { MIAutoFillTextFieldFormsy } from "@massiveinfinity";
import { withStyles, Typography, Button } from "@material-ui/core";
import { bindActionCreators } from "redux";
import { PUBLIC_URL } from "config";
import { withRouter, Link } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import classNames from "classnames";

const Logo = `${PUBLIC_URL}/LG.png`;
const Logo2x = `${PUBLIC_URL}/LG@2x.png`;

const styles = (theme) => ({
  root: {
    backgroundColor: "#0d191c",
    minWidth: "362px",
    height: "582px",
    color: theme.palette.primary.contrastText,
    borderBottom: "12px solid #3995b5",
    width: "100%",
  },
  logo: {
    position: "absolute",
    top: 0,
    marginTop: "-59px",
  },
  form: {
    margin: "auto",
    marginTop: "163px",
    width: "283px",
  },
  instructions: {
    fontSize: 14,
    letterSpacing: 1.4,
    marginBottom: 10,
    color: "#ffffff",
    textAlign: "center",
    fontWeight: 300,
  },
  field: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    width: "100%",
    ["& fieldset"]: {
      borderRadius: 0,
    },
    "& label": {
      fontWeight: 300,
      fontSize: 11,
      width: "100%",
      textAlign: "center",
      transformOrigin: "center",
      letterSpacing: "1.1px",
      pointerEvents: "none",
      color: "#ffffff",
      top: -16,
      "&.Mui-focused": {
        color: "#00BFFE",
      },
    },
  },
  input: {
    color: "#ffffff",
    textAlign: "center",
    fontSize: "11px",
    fontWeight: 300,
  },
  underline: {
    "&:hover": {
      "&:before": {
        borderBottom: "2px solid #fff !important",
      },
    },
    "&:before": {
      borderBottom: "1px solid rgba(255,255,255,0.3)",
    },
  },
  disabledButton: {
    backgroundColor: "rgba(0, 191, 254, 0.7) !important",
    opacity: 0.4,
    color: "#ffffff !important",
  },
  signInButton: {
    backgroundColor: "#00BFFE !important",
    color: "#ffffff !important",
  },
  goBack: {
    letterSpacing: 1.4,
    fontSize: 14,
    textAlign: "center",
    width: "100%",
    fontWeight: 300,
    position: "relative",
    marginTop: 24,
  },
  linkButton: {
    position: "absolute",
    bottom: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  icon: {
    color: "white",
  },
  link: {
    color: "#00BFFE",
    fontWeight: 300,
  },
  base: {
    marginTop: "0px !important",
  },
});

class ForgotPasswordTab extends Component {
  state = {
    canSubmit: false,
    isLoading: false,
  };

  form = React.createRef();

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  onSubmit = (model) => {
    // const { cookies } = this.props;
    // this.props.submitLoginWithFireBase(model, cookies);
    this.setState({
      isLoading: true,
    });
  };

  render() {
    const { classes } = this.props;
    const { canSubmit, isLoading } = this.state;

    return (
      <div className={classes.root}>
        <div className="flex justify-center mb-32">
          <img
            className={classes.logo}
            src={Logo}
            srcSet={`${Logo}, ${Logo2x}`}
          />
        </div>
        <Formsy
          onValidSubmit={this.onSubmit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
          ref={(form) => (this.form = form)}
          className={classNames([
            "flex flex-col justify-center overflow-hidden",
            classes.form,
          ])}
        >
          <div className="flex flex-col items-center mb-80">
            <Typography className={classes.instructions}>
              Enter your email and instructions will be sent to you
            </Typography>
            <i class="las la-envelope-open-text text-72"></i>
          </div>
          <div className="mb-16">
            <MIAutoFillTextFieldFormsy
              className={classNames(classes.field)}
              classes={{
                input: classes.input,
                underline: classes.underline,
                formControl: classes.base,
              }}
              type="text"
              name="email"
              label="Enter email address"
              validations={{
                minLength: 4,
              }}
              validationErrors={{
                minLength: "Min character length is 4",
              }}
              InputLabelProps={{
                required: false,
                classes: {
                  root: classNames([classes.emailLabel]),
                },
              }}
              // InputProps={{
              //   endAdornment: <InputAdornment position="start" />,
              // }}
              required
            />
            <Button
              disabled={!canSubmit || isLoading}
              classes={{
                disabled: classes.disabledButton,
                contained: classes.signInButton,
              }}
              type="submit"
              variant="contained"
              // color="#0eabe0"
              className={classNames(["w-full normal-case my-24"])}
              aria-label="Send reset link"
              value="legacy"
            >
              Send reset link
            </Button>
            <div className={classes.goBack}>
              <Link to="/login">
                <div className={classes.linkButton}>
                <i class="las la-angle-left text-32 text-white"></i>
                  {/* <ArrowBackIosIcon className={classes.icon} /> */}
                </div>
              </Link>
              <Typography className="text-white font-300 leading-none">
                Wait, I remember now...
              </Typography>
              <Link to="/login">
                <Typography className={classes.link}>GO BACK</Typography>
              </Link>
            </div>
          </div>
        </Formsy>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps({ auth }) {
  return {
    user: auth.user,
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(ForgotPasswordTab)
  )
);
