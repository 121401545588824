import React, { Component } from "react";
import { SelectFormik } from "@massiveinfinity/components/Form/FormikInputs";
import { getAccountlessUsers, getUsersTiedToAccount } from "../api";
import { CircularProgress } from "@material-ui/core";
import _ from "lodash";
import firestoreService from "../../../../firebaseService/firestoreService";

class MasterAdminAccountsSelect extends Component {
  state = {
    users: [],
    isUserLoading: false,
  };

  componentDidMount() {
    this.setFiltertoState(this.props.users);
    this.setState({
      isUserLoading: true,
    });
    // getUsersTiedToAccount(this.props.accountID).then((users) =>
    //   this.setFiltertoState(users.data)
    // );
    // .catch(err => console.log(err));
  }

  getAllUsersFn = () =>
    firestoreService
      .getAllAccountlessUsers()
      .then((res) => {
        let usersNotAssignedWithAccount = _.remove(res.data, function(item) {
          return item.assigned_accounts.length === 0 && item.user_role !== 99;
        });
        return usersNotAssignedWithAccount;
      })
      .catch((err) => console.log(err));

  setFiltertoState = (usersArray) => {
    let combinedArr = [];
    let newArr = [];
    let filteredUsersArray = _.remove(usersArray, function(item) {
      return typeof item == "object" && item.user_role != 99;
    });

    firestoreService.init();
    this.getAllUsersFn()
      .then((availableUser) => {
        return (combinedArr = filteredUsersArray.concat(availableUser));
      })
      .then((res) => {
        newArr = _.map(res, (user) =>
          _.pick(user, ["name", "user_id", "userid"])
        );

        const mutatedUserArr = newArr.map((x) => {
          return {
            label: x.name,
            value: x.user_id ? x.user_id : x.userid,
          };
        });
        this.setState({
          users: mutatedUserArr,
          isUserLoading: false,
        });
      });
  };

  render() {
    const { users } = this.state;
    return this.state.isUserLoading ? (
      <CircularProgress></CircularProgress>
    ) : (
      <SelectFormik options={users} {...this.props} />
    );
  }
}

export default MasterAdminAccountsSelect;
