import React, { Component } from "react";
import { SelectFormik } from "@massiveinfinity/components/Form/FormikInputs";
import { getAllAccounts } from "./api";
import _ from "lodash";
import firestoreService from "../../../../firebaseService/firestoreService";

class AccountsSelect extends Component {
  state = {
    accounts: [],
  };

  componentDidMount() {
    firestoreService.init();
    firestoreService
      .getAllAccount()
      .then((res) => this.setFilterToState(res))
      .catch((err) => console.log(err));
    // getAllAccounts()
    //   .then((res) => this.setFilterToState(res.data))
    //   .catch((err) => console.log(err));
  }

  setFilterToState = (accountsArray) => {
    // let newArr = _.map(accountsArray, (account) =>
    //   _.pick(account, ["accountdata"])
    // );
    accountsArray = _.filter(accountsArray, item => !_.isEmpty(item));

    let accountSelectArr = _.map(accountsArray, (account) =>
      _.pick(account, ["account_id", "name"])
    );
    console.log("asdf", accountSelectArr);
    const mutatedAccArr = accountSelectArr.map((account) => {
      return {
        label: account.name,
        value: account.account_id,
      };
    });
    this.setState({
      accounts: mutatedAccArr,
    });
  };

  render() {
    const { accounts } = this.state;
    return <SelectFormik options={accounts} {...this.props} />;
  }
}

export default AccountsSelect;
