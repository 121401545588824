import React from "react";
import Login from "./Login";
import FirebaseLoginTab from "./tabs/FirebaseLoginTab";

const LoginFormView = () => {
  return (
      <Login>
        <FirebaseLoginTab />
      </Login>
  );
};

export default LoginFormView;
