import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import classNames from 'classnames';
import { Typography, Button, Popover, MenuItem, ListItemIcon, ListItemText, Icon } from '@material-ui/core';
import {FuseAnimate} from '@fuse';
import PylonToolbarProfile from '../@pylon/components/PylonToolbarProfile';
import {connect} from 'react-redux';
import * as quickPanelActions from 'main/quickPanel/store/actions';
import {bindActionCreators} from 'redux';
import * as authActions from 'auth/store/actions';
import {Link} from 'react-router-dom';
import logo from './assets/pwm_logo.png';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
    root     : {
        display   : 'flex',
        alignItems: 'center',
        width     : '100%',
    },
    seperator: {
        width          : 1,
        height         : 24,
        margin: 'auto 0',
        backgroundColor: theme.palette.divider
    },
    logoIcon: {
        height: 24,
        transition: theme.transitions.create(['width', 'height'], {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut
        })
    },
    logoText: {
        color: '#FFFFFF'
    }
});

class MainToolbar extends Component {

    state = {
        userMenu: null
    };

    userMenuClick = event => {
        this.setState({userMenu: event.currentTarget});
    };

    userMenuClose = () => {
        this.setState({userMenu: null});
    };

    onClickAppLogo = () => {
        this.props.history.push({
            pathname: '/'
        });
    };

    onClickMenuViewProfile = () => {
        // Close the pop up.
        this.userMenuClose();

        this.props.history.push({
            pathname: '/profile',
            state: {
                isOwnProfile: true
            }
        });
    };

    onClickMenuLogout = () => {
        this.props.logout();
        this.userMenuClose();
    };
    
    render() {
        const { classes } = this.props;
        const { userMenu } = this.state;

        return (
            <div className={classNames(classes.root, "flex flex-row")}>

                <div className="flex items-center">
                    <Button className="h-64" onClick={this.onClickAppLogo}>
                        <img className={classNames(classes.logoIcon, "logo-icon")} src={logo} alt="logo"/>
                        <Typography className={classNames(classes.logoText, "text-16 ml-8 uppercase")}>PYLON CITY</Typography>
                        <Typography className={classNames(classes.logoText, "text-12 ml-4")}>:</Typography>
                        <Typography className={classNames(classes.logoText, "text-12 ml-4 uppercase")}>POWERMON</Typography>
                    </Button>
                </div>

                <div className="flex-1"></div>

                <div className="flex items-center">
                    <FuseAnimate delay={300}>
                        <Button className="h-64" onClick={this.userMenuClick}>
                            <PylonToolbarProfile />
                        </Button>
                    </FuseAnimate>

                    <Popover
                        open={Boolean(userMenu)}
                        anchorEl={userMenu}
                        onClose={this.userMenuClose}
                        anchorOrigin={{
                            vertical  : 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical  : 'top',
                            horizontal: 'center'
                        }}
                        classes={{
                            paper: "py-8 bg-white"
                        }}
                    >
                        <MenuItem
                            onClick={this.onClickMenuViewProfile}
                        >
                            <ListItemIcon>
                                <Icon>account_circle</Icon>
                            </ListItemIcon>
                            <ListItemText className="pl-0 text-black" primary="View Profile" classes={{ primary: 'text-14' }} />
                        </MenuItem>
                        <MenuItem
                            onClick={this.onClickMenuLogout}
                        >
                            <ListItemIcon>
                                <Icon>exit_to_app</Icon>
                            </ListItemIcon>
                            <ListItemText className="pl-0 text-14 text-black" primary="Logout" classes={{ primary: 'text-14' }} />
                        </MenuItem>
                    </Popover>
                </div>
            </div>
        );
    }

}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        logout          : authActions.logoutUser,
    }, dispatch);
}

function mapStateToProps({auth})
{
    return {
        user: auth.user
    }
}

export default withStyles(
    styles, 
    {
        withTheme: true
    }
)(withRouter(connect(mapStateToProps, mapDispatchToProps)(MainToolbar)));
