import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Card,
  CircularProgress,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl
} from '@material-ui/core';
import { Formik, Form, Field, validateYupSchema } from 'formik';
import validationSchema from './validationSchema';
import {
  TextFieldFormik,
  SubmitButtonFormik,
  DatePickerFormik
} from '@massiveinfinity/components/Form/FormikInputs';
import {
  canSubmitFormik,
  getSubmitFuncFormik,
  isSubmittingFormik
} from '@massiveinfinity/components/Form/FormikInputs/helpers';
import { FuseAnimate } from '@fuse';
import classNames from 'classnames';
import { Line, Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as locationActions from '../store/actions';
import { withRouter } from 'react-router-dom';
import _ from '@lodash';
import moment from 'moment';
import { generateReport } from './GenerateReportApi';
import * as Actions from 'store/actions';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const styles = theme => ({
  root: {
    width: '100%'
  },
  filterContainer: {
    listStyle: 'none',
    padding: '0px'
  },
  filterList: {
    display: 'inline'
  },
  filterItem: {
    display: 'inline-block',
    padding: '7px 15px 5px',
    color: 'black',
    cursor: 'pointer',
    borderWidth: '1px',
    borderRadius: '4px',
    '&.energy-generated': {
      borderColor: 'rgb(243,176,76)'
    },
    '&.energy-generated&.active': {
      backgroundColor: 'rgb(243,176,76)',
      color: 'white'
    },
    '&.energy-exported': {
      borderColor: 'rgb(212,23,17)'
    },
    '&.energy-exported&.active': {
      backgroundColor: 'rgb(212,23,17)',
      color: 'white'
    },
    '&.energy-consumed': {
      borderColor: 'rgb(183,139,64)'
    },
    '&.energy-consumed&.active': {
      backgroundColor: 'rgb(183,139,64)',
      color: 'white'
    }
  },
  filterTimeButton: {
    minWidth: '0px',
    minHeight: '0px',
    height: '24px',
    padding: '5px',
    '&.active': {
      backgroundColor: '#4C4C4C',
      color: 'white'
    }
  },
  buttonProgress: {
    // color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  centerProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '-20px'
  },
  exportButton: {
    minWidth: '100px'
  },
  formControl: {
    width: '100%'
  },
  dialogActionStyle: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  blackButton: {
    backgroundColor: 'black',
    color: 'white'
  },
  greyButton: {
    backgroundColor: 'grey',
    color: 'black'
  },
  titleText: {
    textTransform: 'uppercase',
    fontSize: '2.0rem'
  },
  buttonIcon: {
    marginLeft: theme.spacing.unit
  }
});

class LocationTab extends Component {
  chartReference = {};

  state = {
    isLoadingChart: true,
    isButtonLoading: false,
    showDialog: false,
    reportType: 'dateRange',
    chartLabels: [],
    chartEnergyGeneratedLabel: false,
    chartEnergyExportedLabel: true,
    chartTotalEnergyGenerated: [],
    chartTotalEnergyExported: [],
    chartEnergyGenerated: [],
    chartEnergyExported: [],
    timelineFilter: {},
    typeFilter: '',
    width: window.innerWidth,
    downloadFileType: ''
  };

  getChartTimelineFilter = () => {
    return [
      {
        id: 'timeline-filter-hour',
        label: 'Hour',
        unit: 'minute',
        unitStepSize: 5,
        max: null
      },
      {
        id: 'timeline-filter-day',
        label: 'Day',
        unit: 'hour',
        unitStepSize: 1,
        max: null
      },
      {
        id: 'timeline-filter-month',
        label: 'Month',
        unit: 'day',
        unitStepSize: 0.5,
        max: moment().startOf('day')
      },
      {
        id: 'timeline-filter-year',
        label: 'Year',
        unit: 'month',
        unitStepSize: 1,
        max: moment().startOf('month')
      }
    ];
  };

  getChartTypeFilter = () => {
    return [
      {
        id: 'cumulative-filter',
        label: 'Cumulative'
      },
      {
        id: 'discrete-filter',
        label: 'Discrete'
      }
    ];
  };

  componentDidMount() {
    // Automatically select the first timeline.
    this.onClickFilterTimeline(1);
    this.onClickFilterType(0);
    this.onClickActiveDataset(1);
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  componentDidUpdate(prevProps, prevState) {
    const { chart } = this.props;

    if (
      prevState.typeFilter &&
      this.state.typeFilter !== prevState.typeFilter
    ) {
      if (
        this.state.chartEnergyExportedLabel &&
        this.state.chartEnergyGeneratedLabel
      ) {
        this.updateDatalineVisibilityOnChart(1);
        this.updateDatalineVisibilityOnChart(0);
      } else if (this.state.chartEnergyExportedLabel) {
        this.updateDatalineVisibilityOnChart(1);
      } else if (this.state.chartEnergyGeneratedLabel) {
        this.updateDatalineVisibilityOnChart(0);
      }
    }

    if (chart.data == null || _.isEqual(prevProps.chart.data, chart.data)) {
      return;
    }

    this.setState({
      isLoadingChart: false,
      chartLabels: chart.data.x,
      chartTotalEnergyGenerated: chart.data.totalEnergyGenerated,
      chartTotalEnergyExported: chart.data.totalEnergyExported,
      chartEnergyGenerated: chart.data.energyGenerated,
      chartEnergyExported: chart.data.energyExported
    });
  }

  onClickActiveDataset = clickedIndex => {
    const { datasets } = this.getChartData();
    const clickedDataset = datasets[clickedIndex];
    const item = document.getElementById(clickedDataset.class);
    this.setActiveOrNotClass(item);
    this.updateDatalineVisibilityOnChart(clickedIndex);
  };

  onClickFilterType = clickedIndex => {
    const filters = this.getChartTypeFilter();
    const selectedFilter = filters[clickedIndex];

    if (this.state.typeFilter == selectedFilter) {
      return;
    }

    this.setState({
      isLoadingChart: true
    });

    filters.map((row, index) => {
      let item = document.getElementById(row.id);

      if (index == clickedIndex) {
        this.setTypeActiveClass(item);
      } else {
        this.removeTypeActiveClass(item);
      }
    });

    this.setState({ typeFilter: selectedFilter.label, isLoadingChart: false });
  };

  onClickFilterTimeline = clickedIndex => {
    const filters = this.getChartTimelineFilter();
    const selectedFilter = filters[clickedIndex];

    if (this.state.timelineFilter.unit == selectedFilter.unit) {
      return;
    }

    this.setState({
      isLoadingChart: true
    });

    filters.map((row, index) => {
      let item = document.getElementById(row.id);

      if (index == clickedIndex) {
        this.setActiveClass(item);
      } else {
        this.removeActiveClass(item);
      }
    });

    if (clickedIndex == 0) {
      this.props.getOverviewChartByHour(
        this.props.accountId,
        this.props.locationId
      );
    } else if (clickedIndex == 1) {
      this.props.getOverviewChartByDay(
        this.props.accountId,
        this.props.locationId
      );
    } else if (clickedIndex == 2) {
      this.props.getOverviewChartByMonth(
        this.props.accountId,
        this.props.locationId
      );
    } else if (clickedIndex == 3) {
      this.props.getOverviewChartByYear(
        this.props.accountId,
        this.props.locationId
      );
    }

    this.state.timelineFilter = {
      max: selectedFilter.max,
      unit: selectedFilter.unit,
      unitStepSize: selectedFilter.unitStepSize
    };
    this.chartReference.chartInstance.update();
  };

  updateDatalineVisibilityOnChart = index => {
    let meta = this.chartReference.chartInstance.getDatasetMeta(index);
    meta.hidden = meta.hidden === null ? true : null;
    this.chartReference.chartInstance.update();
    switch (index) {
      case 0:
        this.setState({
          chartEnergyGeneratedLabel: meta.hidden === null ? false : true
        });
        break;
      case 1:
        this.setState({
          chartEnergyExportedLabel: meta.hidden === null ? false : true
        });
        break;
    }
  };

  setActiveOrNotClass = item => {
    const classNames = item.className;
    if (classNames.indexOf('active') > -1) {
      this.removeActiveClass(item);
    } else {
      this.setActiveClass(item);
    }
  };

  setActiveClass = item => {
    if (item.className.indexOf('active') > -1) {
      return;
    }

    item.className += ' active';
  };

  removeActiveClass = item => {
    item.className = item.className.replace(' active', '');
  };

  setTypeActiveOrNotClass = item => {
    const classNames = item.className;

    if (classNames.indexOf('active') > -1) {
      this.removeActiveClass(item);
    } else {
      this.setActiveClass(item);
    }
  };

  setTypeActiveClass = item => {
    if (item.className.indexOf('active') > -1) {
      return;
    }

    item.className += ' active';
  };

  removeTypeActiveClass = item => {
    item.className = item.className.replace(' active', '');
  };

  getChartData = () => {
    const {
      chartLabels,
      chartEnergyGenerated,
      chartEnergyExported,
      chartTotalEnergyGenerated,
      chartTotalEnergyExported,
      typeFilter,
      chartEnergyExportedLabel,
      chartEnergyGeneratedLabel
    } = this.state;
    return {
      labels: chartLabels,
      datasets: [
        {
          label: 'Energy Generated',
          class: 'energy-generated',
          borderColor: 'rgb(243,176,76)',
          backgroundColor: 'rgb(243,176,76)',
          borderWidth: 2,
          fill: false,
          lineTension: 0,
          pointRadius: 2,
          pointHoverRadius: 3,
          hidden: chartEnergyGeneratedLabel,
          data:
            typeFilter == 'Cumulative'
              ? chartTotalEnergyGenerated
              : chartEnergyGenerated
        },
        {
          label: 'Energy Exported',
          class: 'energy-exported',
          borderColor: 'rgb(212,23,17)',
          backgroundColor: 'rgb(212,23,17)',
          borderWidth: 2,
          fill: false,
          lineTension: 0,
          pointRadius: 2,
          pointHoverRadius: 3,
          hidden: chartEnergyExportedLabel,
          data:
            typeFilter == 'Cumulative'
              ? chartTotalEnergyExported
              : chartEnergyExported
        }
        // {
        //     label: "Energy Consumed",
        //     class: 'energy-consumed',
        //     borderColor: 'rgb(183,139,64)',
        //     borderWidth: 2,
        //     fill: false,
        //     lineTension: 0,
        //     pointRadius: 0,
        //     data: []
        // }
      ]
    };
  };

  getFilterTotalValue = label => {
    switch (label) {
      case 'Energy Generated':
        return this.props.overview.data.total_energy_generated
          ? this.props.overview.data.total_energy_generated.toFixed(2)
          : '0';
      case 'Energy Exported':
        return this.props.overview.data.total_energy_exported
          ? this.props.overview.data.total_energy_exported.toFixed(2)
          : '0 ';
    }
  };

  closeDialog = () => {
    this.setState({
      showDialog: false
    });
  };

  openDialog = () => {
    this.setState({
      showDialog: true
    });
  };

  changeReportType = e => {
    this.setState({
      reportType: e.target.value
    });
  };

  render() {
    const { classes, overview } = this.props;
    const { timelineFilter, isLoadingChart, width } = this.state;
    const chartData = this.getChartData();
    const { distance_saved, carbon_saved, cost_saved } = overview.data;
    const isMobile = width <= 500;
    console.log('Check filter', this.state.typeFilter);
    console.log(
      'Check Enerygy exported label',
      this.state.chartEnergyExportedLabel
    );
    console.log(
      'Check energy generated label',
      this.state.chartEnergyGeneratedLabel
    );
    console.log('Timeline filter', this.state.timelineFilter.unit);

    const formData = (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Field
          name="start_date"
          component={DatePickerFormik}
          disabled={this.state.isButtonLoading}
        />
        <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>to</span>
        <Field
          name="end_date"
          component={DatePickerFormik}
          disabled={this.state.isButtonLoading}
        />
      </div>
    );

    return (
      <div className="flex flex-1 flex-col md:flex-col md:justify-start p-40">
        <div className="flex justify-between">
          <div style={{ width: '20%' }}></div>
          <div>
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
              <Typography className={classes.titleText}>
                Real time generation overview
              </Typography>
            </FuseAnimate>
          </div>
          <div
            style={{ display: 'flex', flex: '1 1', justifyContent: 'flex-end' }}
          >
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
              <Button
                variant="contained"
                color="primary"
                className={classNames(classes.exportButton)}
                onClick={this.openDialog}
              >
                Generate Report
              </Button>
            </FuseAnimate>
          </div>
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              start_date: moment(),
              end_date: moment()
            }}
            onSubmit={(values, { setSubmitting }) => {
              const splittedCookies = document.cookie.split(';');
              const formattedToken = splittedCookies.filter(cookieItem => {
                let splittedCookieItem = cookieItem.split('=');
                if (
                  splittedCookieItem[0] === ' auth_token' ||
                  splittedCookieItem[0] === 'auth_token'
                ) {
                  return splittedCookieItem;
                }
              });
              const accessToken = formattedToken.toString().split('=')[1];
              this.setState(
                {
                  isButtonLoading: true
                },
                () =>
                  generateReport({
                    accessToken,
                    accountId: this.props.match.params.accountId,
                    locationId: this.props.match.params.locationId,
                    start_date: moment(values.start_date).format('YYYY-MM-DD'),
                    end_date: moment(values.end_date).format('YYYY-MM-DD'),
                    downloadFileType: this.state.downloadFileType
                  })
                    .then(() => {
                      this.setState({
                        isButtonLoading: false
                      });
                    })
                    .catch(err => {
                      this.setState({
                        isButtonLoading: false
                      });
                      this.props.showErrorMessage(err);
                    })
              );
            }}
          >
            {formikRenderProps => (
              <Form>
                <Dialog
                  disableBackdropClick={true}
                  disableEscapeKeyDown={true}
                  open={this.state.showDialog}
                  onClose={this.closeDialog}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle
                    id="form-dialog-title"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    Download Report
                  </DialogTitle>
                  <DialogContent style={{ paddingTop: '2rem' }}>
                    <div>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <RadioGroup
                          name="dateRange_report"
                          className={classes.group}
                          value={this.state.reportType}
                          onChange={this.changeReportType}
                        >
                          <FormControlLabel
                            style={{ justifyContent: 'center' }}
                            value="dateRange"
                            control={<Radio />}
                            label={formData}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </DialogContent>
                  <DialogActions className={classes.dialogActionStyle}>
                    <Button
                      className={classes.blackButton}
                      onClick={() => {
                        const submitFormFn = getSubmitFuncFormik(
                          formikRenderProps
                        );
                        this.setState(
                          {
                            downloadFileType: 'pdf'
                          },
                          () => submitFormFn()
                        );
                      }}
                      isLoading={isSubmittingFormik(formikRenderProps)}
                      disabled={
                        this.state.isButtonLoading &&
                        !canSubmitFormik(formikRenderProps)
                      }
                      color="primary"
                      variant="contained"
                    >
                      PDF
                      <CloudDownloadIcon className={classes.buttonIcon} />
                    </Button>

                    <Button
                      className={classes.blackButton}
                      onClick={() => {
                        const submitFormFn = getSubmitFuncFormik(
                          formikRenderProps
                        );
                        this.setState(
                          {
                            downloadFileType: 'csv'
                          },
                          () => submitFormFn()
                        );
                      }}
                      isLoading={isSubmittingFormik(formikRenderProps)}
                      disabled={
                        this.state.isButtonLoading &&
                        !canSubmitFormik(formikRenderProps)
                      }
                      color="primary"
                      variant="contained"
                    >
                      CSV
                      <CloudDownloadIcon className={classes.buttonIcon} />
                    </Button>
                    {this.state.isButtonLoading && (
                      <CircularProgress
                        size={48}
                        className={classes.buttonProgress}
                      ></CircularProgress>
                    )}
                    <Button
                      className={classes.greyButton}
                      onClick={this.closeDialog}
                      color="primary"
                      variant="contained"
                      disabled={this.state.isButtonLoading}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              </Form>
            )}
          </Formik>
        </div>
        <div className="flex flex-row justify-end mt-8">
          {this.getChartTypeFilter().map((row, index) => {
            return (
              <Button
                id={row.id}
                className={classNames(classes.filterTimeButton, 'text-10')}
                onClick={e => {
                  this.onClickFilterType(index);
                }}
              >
                {row.label}
              </Button>
            );
          })}
        </div>
        <div className="w-full flex flex-row mt-16 ml-4 mr-4 mb-32">
          <div className="flex flex-row">
            {chartData.datasets.map((row, index) => {
              return (
                <div
                  id={row.class}
                  className={classNames(
                    classes.filterItem,
                    row.class,
                    'mr-8 active'
                  )}
                  onClick={e => {
                    this.onClickActiveDataset(index);
                  }}
                >
                  <p
                    className={
                      isMobile
                        ? 'uppercase text-8 font-sans'
                        : 'uppercase text-10 font-sans'
                    }
                  >
                    {row.label}
                  </p>
                  <div className="flex flex-row items-baseline mt-8">
                    <p
                      className={
                        isMobile
                          ? 'text-14 font-300 font-sans'
                          : 'text-24 font-300 font-sans'
                      }
                    >
                      {this.getFilterTotalValue(row.label)}
                    </p>
                    <p className="text-10 ml-6 font-sans">kWh</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex flex-1"></div>
          <div className="flex flex-row flex-wrap">
            {this.getChartTimelineFilter().map((row, index) => {
              return (
                <Button
                  id={row.id}
                  className={classNames(classes.filterTimeButton, 'text-10')}
                  onClick={e => {
                    this.onClickFilterTimeline(index);
                  }}
                >
                  {row.label}
                </Button>
              );
            })}
          </div>
        </div>

        <div
          style={{
            position: 'relative'
          }}
        >
          {this.state.typeFilter == 'Discrete' ? (
            <Bar
              ref={reference => (this.chartReference = reference)}
              data={chartData}
              height={this.state.width * 0.21}
              width={this.state.width - 150}
              backgroundColor
              options={{
                scales: {
                  xAxes: [
                    {
                      type: 'time',
                      time: {
                        format: 'HH:mm',
                        max: timelineFilter.max,
                        unit: timelineFilter.unit,
                        unitStepSize: timelineFilter.unitStepSize,
                        displayFormats: {
                          minute: 'HH:mm',
                          hour: 'HH:mm',
                          day: 'Do',
                          month: 'MMM'
                        }
                      },
                      gridLines: {
                        color: 'rgba(0,0,0,0.08)',
                        display: true,
                        drawBorder: false
                      },
                      ticks: {
                        maxRotation: 0,
                        minRotation: 0
                      }
                    }
                  ],
                  yAxes: [
                    {
                      ticks: {
                        display: true,
                        beginAtZero: false,
                        spanGaps: false
                      },
                      gridLines: {
                        display: false,
                        drawBorder: false
                      }
                    }
                  ]
                },
                legend: {
                  display: false
                }
              }}
            />
          ) : (
            <Line
              ref={reference => (this.chartReference = reference)}
              data={chartData}
              height={this.state.width * 0.21}
              width={this.state.width - 150}
              options={{
                scales: {
                  xAxes: [
                    {
                      type: 'time',
                      time: {
                        format: 'HH:mm',
                        max: timelineFilter.max,
                        unit: timelineFilter.unit,
                        unitStepSize: timelineFilter.unitStepSize,
                        displayFormats: {
                          minute: 'HH:mm',
                          hour: 'HH:mm',
                          day: 'Do',
                          month: 'MMM'
                        }
                      },
                      gridLines: {
                        color: 'rgba(0,0,0,0.08)',
                        display: true,
                        drawBorder: false
                      },
                      ticks: {
                        maxRotation: 0,
                        minRotation: 0
                      }
                    }
                  ],
                  yAxes: [
                    {
                      ticks: {
                        display: true,
                        beginAtZero: false,
                        spanGaps: false
                      },
                      gridLines: {
                        display: false,
                        drawBorder: false
                      }
                    }
                  ]
                },
                legend: {
                  display: false
                }
              }}
            />
          )}
          {isLoadingChart && (
            <CircularProgress
              className={classes.centerProgress}
              color="primary"
            />
          )}
        </div>

        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
          <Typography className={'ml-4 uppercase mt-16'}>
            All Time Stats (
            {this.state.typeFilter == 'Cumulative'
              ? 'Accumulative'
              : 'Discrete'}
            ){' '}
          </Typography>
        </FuseAnimate>

        <div className="flex flex-1 flex-row mt-32 mb-32 items-center justify-center w-full sm:w-1/2 md:w-full">
          <Grid container xs={12} md={12}>
            <Card
              elevation={1}
              className="flex flex-col min-h-84 min-w-320 mx-16 mb-4"
            >
              <FuseAnimate delay={600}>
                <Typography className="px-16 pt-8 text-14 font-400">
                  Total Amount of Savings
                </Typography>
              </FuseAnimate>

              <div className="widget w-full p-16">
                <Typography
                  style={{ display: 'inline-block' }}
                  className={'ml-4 text-12 font-400 uppercase'}
                >
                  S$
                </Typography>
                <Typography
                  style={{ display: 'inline-block' }}
                  className={'ml-4 text-32 font-400 uppercase'}
                >
                  {cost_saved ? cost_saved : '-'}
                </Typography>
              </div>
            </Card>
            <Card
              elevation={1}
              className="flex flex-col min-h-84 min-w-320 mx-16 mb-4"
            >
              <FuseAnimate delay={600}>
                <Typography className="px-16 pt-8 text-14 font-400">
                  Distance Travelled by a Car
                </Typography>
              </FuseAnimate>

              <div className="w-full p-16">
                <Typography
                  style={{ display: 'inline-block' }}
                  className={'ml-4 text-32 font-400 uppercase'}
                >
                  {distance_saved ? distance_saved : '-'}
                </Typography>
                <Typography
                  style={{ display: 'inline-block' }}
                  className={'ml-4 text-12 font-400 uppercase'}
                >
                  Km
                </Typography>
              </div>
            </Card>
            <Card
              elevation={1}
              className="flex flex-col min-h-84 min-w-320 mx-16 mb-4"
            >
              <FuseAnimate delay={600}>
                <Typography className="px-16 pt-8 text-14 font-400">
                  Total C02 Reduction
                </Typography>
              </FuseAnimate>

              <div className="widget w-full p-16">
                <Typography
                  style={{ display: 'inline-block' }}
                  className={'ml-4 text-32 font-400 uppercase'}
                >
                  {carbon_saved ? carbon_saved : '-'}
                </Typography>
                <Typography
                  style={{ display: 'inline-block' }}
                  className={'ml-4 text-12 font-400 uppercase'}
                >
                  kg
                </Typography>
              </div>
            </Card>
          </Grid>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getOverview: locationActions.getOverview,
      getOverviewChartByHour: locationActions.getOverviewChartByHour,
      getOverviewChartByDay: locationActions.getOverviewChartByDay,
      getOverviewChartByMonth: locationActions.getOverviewChartByMonth,
      getOverviewChartByYear: locationActions.getOverviewChartByYear,
      showSuccessMessage: Actions.showSuccessMessage,
      showErrorMessage: Actions.showErrorMessage
    },
    dispatch
  );
}

function mapStateToProps({ loc, auth }) {
  return {
    overview: loc.location.overview,
    chart: loc.location.chart,
    user: auth.user
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationTab))
);
