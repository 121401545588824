import { authRoles } from "auth";
import Accounts from "./Accounts";
import NewAccountPage from "./account/NewAccountPage";
import AccountPage from "./account/AccountPage";
import NewLocationPage from "./account/NewLocationPage";
import LocationPage from "./account/LocationPage";

export const AccountsConfig = {
  settings: {
    layout: {
      config: {
        toolbar: {
          simple: false
        }
      }
    }
  },
  auth: authRoles.account,
  routes: [
    {
      path: "/manage_account",
      component: Accounts,
      exact: true
    },
    {
      path: "/manage_account/new",
      component: NewAccountPage,
      exact: true
    },
    {
      path: "/manage_account/add_location/:acc_id",
      component: NewLocationPage,
      exact: true
    },
    // {
    //   path: "/manage_account/:acc_id/location/:loc_id",
    //   component: LocationPage,
    //   exact: true
    // },
    {
      path: "/manage_account/:id",
      component: AccountPage,
      exact: true
    }
  ]
};
