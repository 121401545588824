import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { FusePageSimple } from '@fuse';
import { PylonForm } from '@pylon';
import ProfileTab from './tabs/ProfileTab';
import withReducer from 'store/withReducer';
import reducer from './store/reducers';

const styles = theme => ({ });

class Profile extends Component {

    render() {
        const { classes } = this.props;

        return (
            <FusePageSimple 
                classes={{
                    root: classes.layoutRoot
                }}
                className="min-h-72 h-72 sm:h-136 sm:min-h-136"
                content={
                    <PylonForm
                        content={
                            <ProfileTab />
                        }
                    />
                }
            >
            </FusePageSimple>
        );
    }

}

export default withReducer('profile', reducer)(withStyles(styles, { withTheme: true })(withRouter(Profile)));