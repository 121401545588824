import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import firestoreService from "../../../firebaseService/firestoreService";
import { Typography } from "@material-ui/core";
import moment from "moment";

const USEPWrapper = ({}) => {
  const [currentUSEP, setCurrentUSEP] = useState(0);

  useEffect(() => {
    const data = getCurrentUSEP();
    data
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          const format = "HH:mm";
          let currTime = moment(moment(), format);
          for (let i = 0; i < data.price_array.length; i++) {
            const timePeriod = data.price_array[i].time_period.split("-");
            const beforeTime = moment(timePeriod[0], format);
            const afterTime = moment(timePeriod[1], format);
            if (currTime.isBetween(beforeTime, afterTime)) {
              setCurrentUSEP(data.price_array[i].usep_price);
            }
          }
        } else {
          console.log("No document");
        }
      })
      .catch((error) => {
        console.log("Error getting document: ", error);
      });
  }, []);

  const getCurrentUSEP = () => {
    firestoreService.init();
    let db = firebase.firestore();
    return db.collection("energy_price").doc("usep_price_display");
  };

  return (
    <div className="flex items-center">
      <i class="las la-tag text-16"></i>
      <Typography className="ml-12 font-300">
        Current USEP: ${currentUSEP} / kwh
      </Typography>
    </div>
  );
};

export default USEPWrapper;
