import axios from "axios";
import jwtDecode from "jwt-decode";
import FuseUtils from "@fuse/FuseUtils";
import { BASE_URL, SECRET_VALUE } from "fuse-configs/apiConfig";
import * as Global from "fuse-configs/globalConfig";
class jwtService extends FuseUtils.EventEmitter {
  init(cookies) {
    this.cookies = cookies;
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      err => {
        return new Promise((resolve, reject) => {
          if (
            err.response &&
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Invalid access_token");
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    let access_token = this.getAccessToken();
    console.log("## access token", access_token);

    if (!access_token) {
      this.emit("onUnauthenticated");
      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "Session has expired");
    }
  };

  createUser = data => {
    return new Promise((resolve, reject) => {
      axios.post("/api/auth/register", data).then(response => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL + "/user/auth", {
          email,
          password
        })
        .then(response => {
          if (response.data.user) {
            this.setSession(response.data.access_token);
            resolve(response.data.user);
          } else {
            reject(response.data.error);
          }
        });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      this.verifyUserSession()
        .then(response => {
          return this.getFormattedUserDataForMemoryFromServer(response.uid);
        })
        .then(user => {
          resolve(user);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  verifyUserSession = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL + "/user/auth", {
          idToken: this.getAccessToken()
        })
        .then(response => {
          if (response.data) {
            //this.setSession(response.data.access_token);
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          let errorMessage = "An error has occurred";

          if (error.response) {
            errorMessage = error.response.data;
          }

          reject(errorMessage);
        });
    });
  };

  getUserDataFromServer = uid => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL + "/user/" + uid)
        .then(response => {
          if (response.data !== undefined && response.data !== "") {
            resolve(response.data);
          } else {
            reject("Error has occurred");
          }
        })
        .catch(error => {
          let errorMessage = "An error has occurred";

          if (error.response) {
            errorMessage = error.response.data;
          }

          reject(errorMessage);
        });
    });
  };

  getFormattedUserDataForMemoryFromServer = uid => {
    return new Promise((resolve, reject) => {
      this.getUserDataFromServer(uid)
        .then(user => {
          const userData = {
            uid: uid,
            role: user.user_role, // Should be user_role
            from: "firebase",
            data: {
              displayName: user.name,
              photoURL: user.img_uri,
              email: user.email,
              assigned_accounts: user.assigned_accounts,
              assigned_locations: user.assigned_locations,
              assigned_regions: user.assigned_regions
            },
            isAuthenticated: true
          };
          console.log(userData);

          resolve(userData);
        })
        .catch(error => {
          let errorMessage = "An error has occurred";

          if (error.response) {
            errorMessage = error.response.data;
          }

          reject(errorMessage);
        });
    });
  };

  updateUserImage = (userId, imageURL) => {
    return new Promise((resolve, reject) => {
      axios
        .put(BASE_URL + "/user/" + userId, {
          img_uri: imageURL
        })
        .then(response => {
          console.log("## response", response);

          resolve(imageURL);
        })
        .catch(error => {
          console.log("## error", error);
          reject(error);
        });
    });
  };

  updateAccountImage = (accId, imageURL) => {
    return new Promise((resolve, reject) => {
      axios
        .put(BASE_URL + "/account/" + accId, {
          img_uri: imageURL
        })
        .then(response => {
          console.log("## response", response);

          resolve(imageURL);
        })
        .catch(error => {
          console.log("## error", error);
          reject(error);
        });
    });
  };

  updateLocationImage = (locId, imageURL) => {
    return new Promise((resolve, reject) => {
      axios
        .put(BASE_URL + "/location/" + locId, {
          img_uri: imageURL
        })
        .then(response => {
          console.log("## response", response);

          resolve(imageURL);
        })
        .catch(error => {
          console.log("## error", error);
          reject(error);
        });
    });
  };

  updateUserData = user => {
    return axios.post("/api/auth/user/update", {
      user: user
    });
  };

  setSession = access_token => {
    if (access_token) {
      this.saveSessionToCookie(access_token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
    } else {
      this.removeSessionFromCookie();
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  logout = () => {
    return new Promise((resolve, reject) => {
      axios.get(BASE_URL + "/api/admin/auth/logout").then(response => {
        if (response.data.success) {
          this.setSession(null);
          resolve(response.data.message);
        } else {
          reject(response.data.message);
        }
      });
    });
  };

  isAuthTokenValid = access_token => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  };

  getAccessToken = () => {
    if (this.cookies) {
      const { auth_token } = this.cookies.cookies;
      return auth_token;
    }

    return null;
  };

  saveSessionToCookie = access_token => {
    if (this.cookies) {
      const { auth_token } = this.cookies.cookies;

      // If token is not found in cookie or not the same, then override it.
      if (!auth_token || auth_token != access_token) {
        this.cookies.set("auth_token", access_token, {
          path: "/"
        });
      }
    }
  };

  removeSessionFromCookie = () => {
    console.log("## remove session from cokie");
    if (this.cookies) {
      console.log("## remove session from cokie YASSSSS");
      this.cookies.remove("auth_token", {
        path: "/"
      });
      console.log("## remove session from cokie asafdssafsdfdsfsaf");
    }
  };
}

const instance = new jwtService();

export default instance;
