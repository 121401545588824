import firestoreService from "../../../../../firebaseService/firestoreService";
import moment from "moment";

export const GET_LOCATION = "[PYLON] GET LOCATION";
export const ERROR_GET_LOCATION = "[PYLON] GET LOCATION ERROR";
export const GET_OVERVIEW = "[PYLON] GET OVERVIEW";
export const GET_CHART_OVERVIEW = "[PYLON] GET CHART OVERVIEW";
export const ERROR_GET_OVERVIEW = "[PYLON] GET OVERVIEW ERROR";
export const GET_METER_ID = "[PYLON] GET METER ID";
export const ERROR_GET_METER_ID = "[PYLON] GET METER ID ERROR";
export const GET_GEN_ID = "[PYLON] GET GEN ID";
export const ERROR_GET_GEN_ID = "[PYLON] GET GEN ID ERROR";
export const GET_ALERTS_AND_ALARMS_INITIAL_QUERY =
  "[PYLON] GET ALERTS AND ALARMS INITIAL QUERY";
export const GET_ALERTS_AND_ALARMS_NEXT_PAGE =
  "[PYLON] GET ALERTS AND ALARMS NEXT PAGE";
export const GET_ALERTS_AND_ALARMS_PREV_PAGE =
  "[PYLON] GET ALERTS AND ALARMS PREVIOUS PAGE";
export const ERROR_GET_ALERTS_AND_ALARMS =
  "[PYLON] GET ALERTS AND ALARMS ERROR";
export const SET_SELECTED_LOCATION = "[PYLON] SET SELECTED LOCATION";

export function getLocation(accountId, locationId) {
  firestoreService.init();
  return (dispatch) => {
    firestoreService
      .getAccountLocation(accountId, locationId)
      .then((data) => {
        return dispatch({
          type: GET_LOCATION,
          payload: data,
          accountId: accountId,
        });
      })
      .catch((error) => {
        return dispatch({
          type: ERROR_GET_LOCATION,
          payload: error,
        });
      });
  };
}

export function setSelectedLocation(location) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_LOCATION,
      payload: location,
    });
  };
}

export function getOverview(accountId, locationId) {
  firestoreService.init();
  return (dispatch) => {
    firestoreService.getOverview(accountId, locationId, (querySnapshot) => {
      let data = [];

      querySnapshot.forEach((doc) => {
        data = doc.data();
      });

      return dispatch({
        type: GET_OVERVIEW,
        payload: data,
      });
    });
  };
}

export function getMetersId(accountId, locationId) {
  firestoreService.init();
  return (dispatch) => {
    firestoreService
      .getMetersId(accountId, locationId)
      .then((metersId) => {
        return dispatch({
          type: GET_METER_ID,
          payload: metersId,
        });
      })
      .catch((error) => {
        return dispatch({
          type: ERROR_GET_METER_ID,
          payload: error,
        });
      });
  };
}

export function getGenMeter(accountId, locationId) {
  firestoreService.init();
  return (dispatch) => {
    firestoreService
      .getGenMeter(accountId, locationId)
      .then((genIds) => {
        console.log("## get gen id result", genIds);
        return dispatch({
          type: GET_GEN_ID,
          payload: genIds,
        });
      })
      .catch((error) => {
        console.log("## error gen id result", error);
        return dispatch({
          type: ERROR_GET_GEN_ID,
          payload: error,
        });
      });
  };
}

export function getOverviewChartByHour(accountId, locationId) {
  firestoreService.init();
  return (dispatch) => {
    firestoreService.getOverviewChartByHour(
      accountId,
      locationId,
      (querySnapshot) => {
        let dataForThePastHour = [];

        querySnapshot.forEach((doc) => {
          let docData = doc.data();

          let data = {
            hh_energy_generated: docData.energy_generated
              ? docData.energy_generated
              : 0,
            hh_energy_exported: docData.energy_exported
              ? docData.energy_exported
              : 0,
            total_energy_generated: docData.total_energy_generated
              ? docData.total_energy_generated
              : 0,
            total_energy_exported: docData.total_energy_exported
              ? docData.total_energy_exported
              : 0,
            timestamp: docData.timestamp.seconds
              ? new Date(docData.timestamp.seconds * 1000)
              : docData.timestamp,
          };

          dataForThePastHour.push(data);
        });

        return dispatch({
          type: GET_CHART_OVERVIEW,
          payload: dataForThePastHour,
        });
      }
    );
  };
}

export function getOverviewChartByDay(accountId, locationId) {
  firestoreService.init();
  return (dispatch) => {
    firestoreService.getOverviewChartByDay(
      accountId,
      locationId,
      (querySnapshot) => {
        let dataForThePastDay = [];

        querySnapshot.forEach((doc) => {
          let docData = doc.data();
          let data = {
            hh_energy_generated: docData.hh_energy_generated
              ? docData.hh_energy_generated
              : 0,
            hh_energy_exported: docData.hh_energy_exported
              ? docData.hh_energy_exported
              : 0,
            total_energy_generated: docData.total_energy_generated
              ? docData.total_energy_generated
              : 0,
            total_energy_exported: docData.total_energy_exported
              ? docData.total_energy_exported
              : 0,
            timestamp: docData.timestamp.seconds
              ? new Date(docData.timestamp.seconds * 1000)
              : docData.timestamp,
          };

          dataForThePastDay.push(data);
        });

        return dispatch({
          type: GET_CHART_OVERVIEW,
          payload: dataForThePastDay,
        });
      }
    );
  };
}

export function getOverviewChartByMonth(accountId, locationId) {
  firestoreService.init();
  return (dispatch) => {
    firestoreService.getOverviewChartByMonth(
      accountId,
      locationId,
      (querySnapshot) => {
        const daysInMonth = moment().daysInMonth();
        let dataForThePastMonth = [];

        let rollingDay = moment().startOf("month");
        for (let i = 0; i < daysInMonth; i++) {
          dataForThePastMonth.push({
            hh_energy_generated: 0,
            total_energy_generated: 0,
            timestamp: rollingDay.toDate(),
          });
          rollingDay = rollingDay.add(1, "days");
        }

        querySnapshot.forEach((doc) => {
          let docData = doc.data();

          let docTimestamp = docData.timestamp.seconds
            ? new Date(docData.timestamp.seconds * 1000)
            : docData.timestamp;
          let dayIndex = moment(docTimestamp).format("D") - 1;

          let data = dataForThePastMonth[dayIndex];
          data = {
            ...data,
            hh_energy_generated: docData.daily_energy_generated
              ? docData.daily_energy_generated
              : 0,
            hh_energy_exported: docData.daily_energy_exported
              ? docData.daily_energy_exported
              : 0,
            total_energy_generated: docData.total_energy_generated,
            total_energy_exported: docData.total_energy_exported,
          };

          dataForThePastMonth[dayIndex] = data;
        });

        return dispatch({
          type: GET_CHART_OVERVIEW,
          payload: dataForThePastMonth,
        });
      }
    );
  };
}

export function getOverviewChartByYear(accountId, locationId) {
  firestoreService.init();
  return (dispatch) => {
    firestoreService.getOverviewChartByYear(
      accountId,
      locationId,
      (querySnapshot) => {
        const monthsPerYear = 12;
        let dataForThePastYear = [];

        let rollingMonth = moment().startOf("year");
        for (let i = 0; i < monthsPerYear; i++) {
          dataForThePastYear.push({
            hh_energy_generated: 0,
            total_energy_generated: 0,
            timestamp: rollingMonth.toDate(),
          });
          rollingMonth = rollingMonth.add(1, "months");
        }

        querySnapshot.forEach((doc) => {
          let docData = doc.data();

          let docTimestamp = docData.timestamp.seconds
            ? new Date(docData.timestamp.seconds * 1000)
            : docData.timestamp;
          let monthIndex = moment(docTimestamp).format("M") - 1;

          let data = dataForThePastYear[monthIndex];
          data = {
            ...data,
            hh_energy_generated: docData.monthly_energy_generated
              ? docData.monthly_energy_generated
              : 0,
            hh_energy_exported: docData.monthly_energy_exported
              ? docData.monthly_energy_exported
              : 0,

            total_energy_generated: docData.total_energy_generated
              ? docData.total_energy_generated
              : 0,
            total_energy_exported: docData.total_energy_exported
              ? docData.total_energy_exported
              : 0,
          };

          dataForThePastYear[monthIndex] = data;
        });

        return dispatch({
          type: GET_CHART_OVERVIEW,
          payload: dataForThePastYear,
        });
      }
    );
  };
}

export function getInitialQueryAlarmAndAlerts(accountId, locationId) {
  firestoreService.init();
  return (dispatch) => {
    return firestoreService
      .getInitialQueryAlarmAndAlerts(accountId, locationId)
      .then((data) => {
        return dispatch({
          type: GET_ALERTS_AND_ALARMS_INITIAL_QUERY,
          payload: {
            ...data,
            page: 1,
          },
        });
      })
      .catch((error) => {
        return dispatch({
          type: ERROR_GET_ALERTS_AND_ALARMS,
          payload: error,
        });
      });
  };
}

export function getNextPageAlarmsAndAlerts(
  accountId,
  locationId,
  lastField,
  page
) {
  firestoreService.init();
  return (dispatch) => {
    return firestoreService
      .getNextPageAlarmsAndAlerts(accountId, locationId, lastField, page)
      .then((data) => {
        return dispatch({
          type: GET_ALERTS_AND_ALARMS_NEXT_PAGE,
          payload: {
            ...data,
            page: page + 1,
          },
        });
      })
      .catch((error) => {
        return dispatch({
          type: ERROR_GET_ALERTS_AND_ALARMS,
          payload: error,
        });
      });
  };
}

export function getPrevPageAlarmsAndAlerts(
  accountId,
  locationId,
  firstField,
  page
) {
  firestoreService.init();
  return (dispatch) => {
    return firestoreService
      .getPrevPageAlarmsAndAlerts(accountId, locationId, firstField, page)
      .then((data) => {
        return dispatch({
          type: GET_ALERTS_AND_ALARMS_PREV_PAGE,
          payload: {
            ...data,
            page: page - 1,
          },
        });
      })
      .catch((error) => {
        return dispatch({
          type: ERROR_GET_ALERTS_AND_ALARMS,
          payload: error,
        });
      });
  };
}
