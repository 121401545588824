import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import {
  TextFieldFormik,
  SubmitButtonFormik,
  SelectFormik,
} from "@massiveinfinity/components/Form/FormikInputs";
import { Paper, Divider, Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";
import * as Actions from "store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { AllRoleType, RoleTypeWithoutMasterAndRegionAdmin } from "./const";
import { postNewUser } from "../api";
import validationSchema from "./validationSchema";
import AccountsSelect from "../../locations/AddLocationComponent/AccountsSelect";

const styles = (theme) => ({
  submitButton: {
    backgroundColor: "#68f72a",
    margin: theme.spacing.unit * 2,
    textTransform: "uppercase",
  },
  createButton: {
    backgroundColor: "#4AA5D2",
    margin: theme.spacing.unit * 2,
    textTransform: "none",
    color: "white",
    borderRadius: 0,
  },
  cancelButton: {
    backgroundColor: "#5cccec",
    margin: theme.spacing.unit * 2,
    textTransform: "none",
    color: "white",
    borderRadius: 0,
  },
});

class AddUserContent extends Component {
  state = {};
  render() {
    const { classes, showErrorMessage, showSuccessMessage } = this.props;
    return (
      <Paper square className="mt-12">
        <div className="flex justify-between items-center p-6">
          <Typography className="m-12">ADD USER</Typography>
        </div>
        <Divider className="bg-grey" />
        <Formik
          onSubmit={(values, { setSubmitting }) => {
            postNewUser({
              assigned_regions: values.region,
              assigned_accounts: values.account,
              name: values.name,
              password: values.password,
              user_role: parseInt(values.role),
              contact: values.contact_number,
              email: values.email,
            })
              .then((res) => {
                showSuccessMessage("User successfully created");
                this.props.history.push({
                  pathname: "/manage_users",
                });
              })
              .catch((err) => {
                showErrorMessage(err.response.data.message);
              });
            setSubmitting(false);
          }}
          validationSchema={validationSchema}
          initialValues={
            this.props.role == 99
              ? {
                  region: "SG",
                }
              : {
                  region: "SG",
                  account: this.props.assigned_accounts[0],
                }
          }
        >
          {(formikRenderProps) => (
            <Form className="mx-12">
              <div className="flex flex-row">
                <div className="w-full">
                  <Typography variant="h5" className="my-12">
                    USER INFORMATION
                  </Typography>
                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Account:</div>
                    {this.props.role == 99 ? (
                      <Field
                        name="account"
                        component={AccountsSelect}
                        className="w-full"
                      />
                    ) : (
                      <Field
                        name="account"
                        className="w-full"
                        component={SelectFormik}
                        options={[
                          {
                            label: this.props.assigned_accounts[0],
                            value: this.props.assigned_accounts[0],
                          },
                        ]}
                        isDisabled
                      />
                    )}
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Region:</div>
                    <Field
                      name="region"
                      label="Region"
                      component={SelectFormik}
                      options={[
                        {
                          label: "SG",
                          value: "SG",
                        },
                      ]}
                      isDisabled
                      className="w-full"
                    />
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Name:</div>
                    <Field
                      name="name"
                      label="Name"
                      component={TextFieldFormik}
                      className="w-full"
                    />
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Password:</div>
                    <Field
                      type="password"
                      name="password"
                      label="Password"
                      component={TextFieldFormik}
                      className="w-full"
                    />
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Confirm Password:</div>
                    <Field
                      type="password"
                      name="confirmPassword"
                      label="Confirm Password"
                      component={TextFieldFormik}
                      className="w-full"
                    />
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Account Role:</div>
                    <Field
                      name="role"
                      label="Account Role"
                      component={SelectFormik}
                      options={
                        this.props.role == 30
                          ? RoleTypeWithoutMasterAndRegionAdmin
                          : AllRoleType
                      }
                      className="w-full"
                    />
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Contact Number:</div>
                    <Field
                      name="contact_number"
                      label="Contact Number"
                      component={TextFieldFormik}
                      className="w-full"
                    />
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Email:</div>
                    <Field
                      name="email"
                      label="Email"
                      component={TextFieldFormik}
                      className="w-full"
                    />
                  </div>

                  {/* <div className="flex flex-row items-center my-8">
                    <div>
                      <SubmitButtonFormik formikRenderProps={formikRenderProps}>
                        Submit
                      </SubmitButtonFormik>
                    </div>
                  </div> */}
                  <div className="flex justify-center">
                    <SubmitButtonFormik
                      formikRenderProps={formikRenderProps}
                      className={classes.createButton}
                    >
                      Create
                    </SubmitButtonFormik>
                    <Button
                      className={classes.cancelButton}
                      component={Link}
                      to="/manage_users"
                    >
                      Exit To User List
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Paper>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSuccessMessage: Actions.showSuccessMessage,
      showErrorMessage: Actions.showErrorMessage,
    },
    dispatch
  );
}

function mapStateToProps({ auth }) {
  const { role, data } = auth.user;
  const { assigned_accounts } = data;
  return {
    role,
    assigned_accounts,
  };
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(AddUserContent)
);
