import React, { Component } from "react";
import Formsy from "formsy-react";
import { MIAutoFillTextFieldFormsy } from "@massiveinfinity";
import {
  withStyles,
  Button,
  InputAdornment,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { bindActionCreators } from "redux";
import * as Actions from "auth/store/actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import _ from "lodash";
import { PUBLIC_URL } from "config";
import classNames from "classnames";
import { Link } from "react-router-dom";
import firebaseService from "../../../../firebaseService/firebaseService";

import { withCookies } from "react-cookie";

const Logo = `${PUBLIC_URL}/LG.png`;
const Logo2x = `${PUBLIC_URL}/LG@2x.png`;

const styles = (theme) => ({
  root: {
    backgroundColor: "#0d191c",
    minWidth: "362px",
    height: "582px",
    color: theme.palette.primary.contrastText,
    borderBottom: "12px solid #3995b5",
    width: "100%",
  },
  wrapper: {
    width: "100%",
    marginTop: "5px",
    marginBottom: "5px",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    color: "white",
  },
  logo: {
    position: "absolute",
    top: 0,
    marginTop: "-59px",
  },
  form: {
    margin: "auto",
    marginTop: "163px",
    width: "283px",
  },
  emailLabel: {
    opacity: "50%",
  },
  field: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    width: "100%",
    ["& fieldset"]: {
      borderRadius: 0,
    },
    "& label": {
      fontWeight: 300,
      fontSize: 11,
      width: "100%",
      textAlign: "center",
      transformOrigin: "center",
      letterSpacing: "1.1px",
      pointerEvents: "none",
      color: "#ffffff",
      marginTop: 0,
      top: -16,
      "&.Mui-focused": {
        color: "#00BFFE",
      },
    },
  },
  input: {
    color: "#ffffff",
    textAlign: "center",
    fontSize: "11px",
    fontWeight: 300,
  },
  underline: {
    "&:hover": {
      "&:before": {
        borderBottom: "2px solid #fff !important",
      },
    },
    "&:before": {
      borderBottom: "1px solid rgba(255,255,255,0.3)",
    },
  },
  signInButton: {
    backgroundColor: "#00BFFE !important",
    color: "#ffffff !important",
  },
  getStartedButton: {
    backgroundColor: "#1c1e1c !important",
    color: "#ffffff !important",
  },
  disabledButton: {
    backgroundColor: "rgba(0, 191, 254, 0.7) !important",
    opacity: 0.4,
    color: "#ffffff !important",
  },
  gap: {
    marginBottom: "51.25px",
  },
  faq: {
    color: "white",
    letterSpacing: 1.2,
    fontWeight: 300,
    fontSize: 12,
  },
  forgotPassword: {
    color: "#00BFFE",
    fontSize: 12,
    fontWeight: 300,
  },
  base: {
    marginTop: "0px !important",
  },
});

class FirebaseLoginTab extends Component {
  state = {
    canSubmit: false,
    isLoading: false,
  };

  form = React.createRef();

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  onSubmit = (model) => {
    const { cookies } = this.props;
    this.props.submitLoginWithFireBase(model, cookies);
    this.setState({
      isLoading: true,
    });
  };

  componentDidUpdate() {
    firebaseService.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { login } = this.props;

    if (
      login.error &&
      (login.error.email ||
        login.error.password ||
        login.error.tooManyRequests ||
        login.error.standardError)
    ) {
      if (!login.error.tooManyRequests) {
        const formErrors = _.pick(login.error, ["email", "password"]);
        this.form.updateInputsWithError({
          ...formErrors,
        });
      }
      console.log("Login Error: ", login.error);

      this.props.login.error = null;
      this.setState({
        isLoading: false,
      });
    }

    if (this.props.user.role !== "guest") {
      const pathname =
        this.props.location.state && this.props.location.state.redirectUrl
          ? this.props.location.state.redirectUrl
          : "/";
      this.props.history.push({
        pathname,
      });
    }
    return null;
  }

  render() {
    const { classes } = this.props;
    const { canSubmit, isLoading } = this.state;

    return (
      <div className={classes.root}>
        <div className="flex justify-center mb-32">
          <img
            className={classes.logo}
            src={Logo}
            srcSet={`${Logo}, ${Logo2x}`}
          />
        </div>
        <Formsy
          onValidSubmit={this.onSubmit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
          ref={(form) => (this.form = form)}
          className={classNames(["flex flex-col justify-center", classes.form])}
        >
          <div className="mb-16">
            <MIAutoFillTextFieldFormsy
              className={classNames(classes.field)}
              classes={{
                input: classes.input,
                underline: classes.underline,
                formControl: classes.base,
              }}
              type="text"
              name="email"
              label="Email"
              validations={{
                minLength: 4,
              }}
              validationErrors={{
                minLength: "Min character length is 4",
              }}
              InputLabelProps={{
                required: false,
                // classes: {
                //   root: classNames([classes.emailLabel]),
                // },
              }}
              // InputProps={{
              //   endAdornment: <InputAdornment position="start" />,
              // }}
              required
            />
          </div>
          <div className={classes.gap} />
          <div className="mb-16">
            <MIAutoFillTextFieldFormsy
              className={classNames(classes.field)}
              classes={{
                input: classes.input,
                underline: classes.underline,
                formControl: classes.base,
              }}
              type="password"
              name="password"
              label="Password"
              validations={{
                minLength: 4,
              }}
              validationErrors={{
                minLength: "Min character length is 4",
              }}
              InputLabelProps={{
                required: false,
              }}
              // InputProps={{
              //   endAdornment: <InputAdornment position="start" />,
              // }}
              required
            />
          </div>

          <div className={classes.wrapper}>
            <Button
              disabled={!canSubmit || isLoading}
              classes={{
                disabled: classes.disabledButton,
                contained: classes.signInButton,
              }}
              type="submit"
              variant="contained"
              // color="#0eabe0"
              className={classNames(["w-full normal-case"])}
              aria-label="SIGN IN"
              value="legacy"
            >
              SIGN IN
            </Button>
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
          {/* <div className={classes.wrapper}>
            <Button
              classes={{
                contained: classes.getStartedButton,
              }}
              variant="contained"
              // color="#0eabe0"
              className={classNames(["w-full normal-case"])}
              aria-label="Get Started"
              value="legacy"
            >
              Get Started
            </Button>
          </div> */}

          <div className="flex flex-col mt-32 text-center whitespace-pre-line">
            <Link to="/forgot-password">
              <Typography className={classes.forgotPassword}>
                Forgot Email / Password?
              </Typography>
            </Link>
            {/* <span>{`\n`}</span>
            <Link>
              <Typography className={classes.faq}>FAQs</Typography>
            </Link> */}
          </div>
        </Formsy>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitLoginWithFireBase: Actions.submitLoginWithFireBase,
    },
    dispatch
  );
}

function mapStateToProps({ auth }, { cookies }) {
  return {
    login: auth.login,
    user: auth.user,
    cookies: cookies,
  };
}

export default withCookies(
  withStyles(styles, {
    withTheme: true,
  })(
    withRouter(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(FirebaseLoginTab)
    )
  )
);
