import React from 'react';

const InverterIcon = ({ colorCode }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 130.69 175.31"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={colorCode}
      d="M113.163,0H17.539C7.873,0,0,7.857,0,17.523v130.69c0,9.666,7.873,17.539,17.539,17.539H28.26v6.367
   c0,1.757,1.415,3.192,3.175,3.192h19.129c1.757,0,3.187-1.434,3.187-3.192v-6.367h25.496v6.367c0,1.757,1.43,3.192,3.192,3.192
   h19.125c1.761,0,3.191-1.434,3.191-3.192v-6.367h8.408c9.666,0,17.527-7.873,17.527-17.539V17.523
   C130.69,7.857,122.829,0,113.163,0z M60.847,105.565c0.871-1.195,2.281-1.973,3.876-1.973c1.48,0,2.807,0.675,3.684,1.737
   c11.177,1.5,19.815,11.084,19.815,22.667c0,12.606-10.256,22.878-22.878,22.878c-12.606,0-22.878-10.272-22.878-22.878
   C42.467,116.915,50.384,107.66,60.847,105.565z M48.702,30.285c0-2.645,2.132-4.793,4.777-4.793h25.496
   c2.646,0,4.793,2.148,4.793,4.793c0,2.634-2.148,4.781-4.793,4.781H53.479C50.833,35.066,48.702,32.918,48.702,30.285z
    M52.037,127.996c0-5.415,3.251-10.076,7.893-12.158v2.101c0,2.634,2.148,4.781,4.793,4.781c2.63,0,4.777-2.147,4.777-4.781v-2.595
   c5.316,1.751,9.168,6.756,9.168,12.651c0,7.331-5.976,13.308-13.324,13.308C58.014,141.304,52.037,135.328,52.037,127.996z"
    />
  </svg>
);

export default InverterIcon;
