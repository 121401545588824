import React, { Component } from "react";
import PropTypes from "prop-types";
import { withFormsy } from "formsy-react";
import UploadInput from "./UploadInput";
import { isString } from "lodash";

class UploadInputContainer extends Component {
  static propTypes = {
    prop: PropTypes,
  };

  static defaultProps = {
    formatValue: value => value,
    formatOnChange: value => value,
  };

  handleOnChange = setFormValue => e => {
    if (e) {
      const file = e.target.files[0];
      setFormValue(file);
    } else {
      setFormValue(null);
    }
  };

  render() {
    const {
      label,
      setValue,
      getValue,
      getErrorMessage,
      formatValue,
      formatOnChange,
      ...otherProps
    } = this.props;

    const errorMessage = getErrorMessage();
    const formValue = getValue();
    let file;
    let value;

    // in create form, the form value is a File object
    // in edit form the value populated is going to be a URL of the uploaded file storage destination
    // since a file type input cannot be programmatically set the value attribute for security reason
    // the value is used to show a file list from the uploaded file storage URL
    if (formValue) {
      const formattedFormValue = formatValue(formValue);

      if (isString(formattedFormValue)) {
        file = formattedFormValue;
      } else {
        file = formattedFormValue.name;
      }
    }

    return (
      <UploadInput
        {...otherProps}
        label={label}
        error={Boolean(errorMessage)}
        helperText={errorMessage}
        file={file}
        onChange={this.handleOnChange(val =>
          setValue(formatOnChange(val, formValue))
        )}
      />
    );
  }
}

const UploadInputWithFormsy = withFormsy(UploadInputContainer);

export default UploadInputWithFormsy;
