import React, { PropTypes, Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import * as Actions from "store/actions";
import {
  Typography,
  ButtonBase,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import { FuseAnimate, FusePageSimple } from "@fuse";
import GoogleMapReact from "google-map-react";
import { GOOGLE_BROWSER_KEY } from "../../../fuse-configs/apiConfig";
import Popup from "reactjs-popup";
import axios from "axios";
import { BASE_URL, SECRET_VALUE } from "fuse-configs/apiConfig";
import firestoreService from "../../../firebaseService/firestoreService";
import { BiUpArrowCircle, BiDownArrowCircle } from "react-icons/bi";
import { Breadcrumbs } from "@material-ui/lab";

//assets
import pin_grey from "../../assets/pin_grey.png";
import pin_dark from "../../assets/pin_dark.png";
import pin_blue from "../../assets/pin_blue.png";
import placeholder from "../../assets/placeholder.jpg";
import { bindActionCreators } from "redux";
import { firestore } from "firebase";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    backgroundColor: "white",
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  header: {
    position: "absolute",
    width: "100%",
    top: 0,
    zIndex: 2,
  },
  headerText: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    height: 40,
    paddingLeft: 24,
  },
  headerTabWrapper: {
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    height: 180,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  TabWrapper: {
    backgroundColor: "white",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
});

const Tab = ({ title, icon, content, status, unit, unitPos, hasLeading }) => {
  const numberWithCommas = (x) => {
    if (hasLeading) {
      x = x.toFixed(2);
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div
      style={{
        height: 125,
        width: 300,
        padding: 20,
      }}
    >
      <div className="flex justify-between">
        <Typography>{title}</Typography>
        {status === "up" ? (
          <BiUpArrowCircle color="#3CB878" />
        ) : (
          <BiDownArrowCircle color="##F47560" />
        )}
      </div>
      <div className="flex items-center">
        <i class={`${icon} text-32`}></i>
        {unitPos === "left" && unit && (
          <Typography className="ml-8">{unit}</Typography>
        )}
        <Typography className="ml-8 font-300 text-30">
          {numberWithCommas(content)}
        </Typography>
        {unitPos === "right" && unit && (
          <Typography className="ml-8 font-300 text-16">{unit}</Typography>
        )}
      </div>
    </div>
  );
};

const Marker = ({ key, place, onClickLocation }) => {
  let pin = null;
  switch (place.status) {
    case true:
      pin = pin_blue;
      break;
    case false:
      pin = pin_grey;
      break;
  }

  return (
    <div key={key}>
      <Popup
        trigger={(open) => (
          <img
            style={{ height: 35, width: 25, resizeMode: "center" }}
            src={open ? (pin = pin_dark) : pin}
            alt="Logo"
          />
        )}
        position="right center"
        closeOnDocumentClick
        contentStyle={{ height: 80, width: 345, padding: 0 }}
      >
        <ButtonBase className="w-full">
          <div
            className="flex flex-row w-full"
            onClick={() => onClickLocation(place)}
          >
            <img
              className="flex"
              style={{ height: 79, width: 80, resizeMode: "center" }}
              src={place.img_uri == "" ? placeholder : place.img_uri}
              alt="Image"
            />
            <div className="flex flex-1 flex-col ml-8 mr-12 pt-6 pb-6">
              <Typography className="text-13 font-400 normal-case text-left leading-none">
                {place.name}
              </Typography>
              <div className="flex flex-row mt-6">
                <div className="flex flex-col mr-6">
                  <div className="flex flex-row">
                    <Typography className="text-28 font-400 normal-case leading-none">
                      {(place.locationenergy * 1).toFixed(2)}
                    </Typography>
                    <Typography className="text-6 text-center ml-6 mt-18">
                      MWh
                    </Typography>
                  </div>
                  <Typography className="text-6 uppercase text-left mt-4">
                    Energy Generated today
                  </Typography>
                </div>
                <div className="flex flex-col ml-6">
                  <div className="flex flex-row">
                    <Typography className="text-28 font-300 normal-case leading-none">
                      {(place.locationpower * 1).toFixed(2)}
                    </Typography>
                    <Typography className="text-6 text-center normal-case ml-6 mt-18">
                      kW
                    </Typography>
                  </div>
                  <Typography className="text-6 uppercase text-left mt-4">
                    Power Generation
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </ButtonBase>
      </Popup>
    </div>
  );
};

class DashboardApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      center: {
        lat: 1.35,
        lng: 103.82,
      },
      zoom: 12,
      locations: [],
      isLoading: true,
      width: window.innerWidth,
    };
  }

  getAllAccount = () => {
    return new Promise((resolve, reject) => {
      firestoreService.init();
      firestoreService.getAllAccountId().then((data) => {
        let accounts = [];
        data.map((account) => accounts.push(account));
        resolve(accounts);
      });
    });
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    const role = this.props.user.role;

    if (role == 99) {
      const arrAccount = await this.getAllAccount();
      const arrPromise = arrAccount.map((accountId) => {
        return this.getLocation(accountId);
      });

      Promise.all(arrPromise)
        .then((arrLocation) => {
          let locations = [];
          arrLocation.forEach((loc) => {
            locations = [...locations, ...loc];
          });
          this.setState({ locations: locations, isLoading: false });
        })
        .catch((err) => {
          // throw err;
        });
    } else if (role == 30) {
      if (this.props.user.data.assigned_accounts[0] !== undefined) {
        return new Promise((resolve, reject) => {
          axios
            .get(
              BASE_URL +
                `/account/${this.props.user.data.assigned_accounts[0]}/location`
            )
            .then((response) => {
              if (response.status) {
                let data = response.data;
                let newData = [];
                let locations = [];
                data.forEach((map) => {
                  newData = {
                    ...map,
                    accountId: this.props.user.data.assigned_accounts[0],
                  };
                  locations.push(newData);
                });

                this.setState({ locations: locations, isLoading: false });
                resolve(response.data.message);
              } else {
                reject(response.data.message);
              }
            });
        });
      } else {
        this.props.history.push({
          pathname: `/support`,
        });
      }
    } else {
      return;
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  isMobileDevice = () => {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  };

  getLocation = (accountId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL + `/account/${accountId}/location`)
        .then((response) => {
          if (response.status) {
            let data = response.data;
            let newData = [];
            let locations = [];
            data.forEach((map) => {
              newData = { ...map, accountId: accountId };
              locations.push(newData);
            });
            resolve(locations);
          } else {
            reject(response.data.message);
          }
        });
    });
  };

  onClickLocation = (place) => {
    console.log("click", place);
    this.props.history.push({
      pathname: `/location/${place.accountId}/${place.location_id}`,
      state: {
        place: place,
      },
    });
  };

  renderMarkers = () => {
    if (this.state.locations.length > 0) {
      return this.state.locations.map((place) => {
        return (
          <Marker
            key={place.name}
            place={place}
            lat={place.coordinates ? place.coordinates._latitude : 0}
            lng={place.coordinates ? place.coordinates._longitude : 0}
            onClickLocation={this.onClickLocation}
          />
        );
      });
    }
    return null;
  };

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <div>
        <CircularProgress className={classes.progress} />
      </div>
    );
  };

  renderHeader = () => {
    const { classes } = this.props;

    return (
      <div className={classes.header}>
        <Paper square>
          <Breadcrumbs
            separator={<i class="las la-angle-right"></i>}
            arial-label="Breadcrumb"
            className="p-6"
          >
            <Link to="/dashboard">
              <Typography className="font-600">
                <i class="las la-map-marked-alt mr-6 text-14 text-black"></i>
                ALL LOCATIONS
              </Typography>
            </Link>
          </Breadcrumbs>
        </Paper>
        {/* <div className={classes.headerText}></div> */}
        <div className={classes.headerTabWrapper}>
          <div className={classes.TabWrapper}>
            <Tab
              title="NO. OF LOCATIONS"
              status="up"
              icon="las la-map-marker"
              content={this.state.locations.length}
            />
            <div
              style={{
                height: 125,
                backgroundColor: "rgba(0,0,0,0.25)",
                width: 1,
              }}
            />
            <Tab
              title="TOTAL GENERATION (YEAR)"
              status="up"
              icon="las la-atom"
              content={16514.26}
              unit="kWh"
              hasLeading
              unitPos="right"
            />
          </div>
        </div>
      </div>
    );
  };

  createMapOptions = (maps) => {
    return {
      fullscreenControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
      },
      mapTypeControl: false,
    };
  };

  render() {
    const { classes } = this.props;
    const { width } = this.state;
    // const isMobile = width <= 768;
    const isMobile = this.isMobileDevice();
    const mobileCenter = {
      lat: 1.29,
      lng: 103.95,
    };
    return (
      // <div>
      //   asdasdadas
      //   <div style={{ height: "100vh", width: "100vw" }}>
      //     <GoogleMapReact
      //       bootstrapURLKeys={{ key: GOOGLE_BROWSER_KEY }}
      //       defaultCenter={{ lat: 1.35, lng: 103.82 }}
      //       defaultZoom={12}
      //     />
      //   </div>
      //   ddddddd
      // </div>
      <FusePageSimple
        classes={{
          root: classes.layoutRoot,
        }}
        content={
          <div
            style={
              isMobile
                ? { height: "1500px", width: "1000px", position: "relative" }
                : { height: "100%", position: "relative" }
            }
          >
            {this.renderHeader()}
            <GoogleMapReact
              bootstrapURLKeys={{ key: GOOGLE_BROWSER_KEY }}
              defaultCenter={isMobile ? mobileCenter : this.state.center}
              defaultZoom={this.state.zoom}
              options={this.createMapOptions}
            >
              {this.state.isLoading && this.renderLoading()}
              {this.renderMarkers()}
            </GoogleMapReact>
          </div>
        }
      />
      // <div style={{ height: "100%", width: "100%" }}>
      //   <GoogleMapReact
      //     bootstrapURLKeys={{ key: GOOGLE_BROWSER_KEY }}
      //     defaultCenter={{ lat: 1.35, lng: 103.82 }}
      //     defaultZoom={12}
      //   />
      // </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps({ auth }) {
  return {
    user: auth.user,
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardApp))
);
