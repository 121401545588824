import firebase from "firebase/app";
import moment from "moment";
import firestoreService from "../../../../firebaseService/firestoreService";

export const utilGetInitialPageAlarmAndAlerts = (accountId, locationId) => {
  firestoreService.init();
  let db = firebase.firestore();

  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("overview")
    .doc("alarm_history")
    .collection("log")
    .orderBy("group", "desc")
    .orderBy("timestamp", "desc");
  // .limit(15);
};

export const utilGetNextPageAlarmAndAlerts = (
  accountId,
  locationId,
  paramLastField
) => {
  firestoreService.init();
  let db = firebase.firestore();

  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("overview")
    .doc("alarm_history")
    .collection("log")
    .orderBy("group", "desc")
    .orderBy("timestamp", "desc")
    .startAfter(paramLastField)
    .limit(15);
};

export const utilGetPrevPageAlarmsAndAlerts = (
  accountId,
  locationId,
  paramFirstField
) => {
  firestoreService.init();
  let db = firebase.firestore();
  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("overview")
    .doc("alarm_history")
    .collection("log")
    .orderBy("group", "desc")
    .orderBy("timestamp", "desc")
    .endBefore(paramFirstField)
    .limitToLast(15);
};

export const utilGetAlarmCollectionCount = (accountId, locationId) => {
  firestoreService.init();
  let db = firebase.firestore();
  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("overview")
    .doc("alarm_history")
    .collection("log");
};

////////////////////////////
//     Meter Data        //
////////////////////////////

export const getInverterMeter = (accountId, locationId) => {
  firestoreService.init();
  let db = firebase.firestore();
  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("inverter")
    .orderBy("status_code", "desc");
};

export const getExpMeter = (accountId, locationId) => {
  firestoreService.init();
  let db = firebase.firestore();
  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("export_meter");
};

export const getIRMeter = (accountId, locationId) => {
  firestoreService.init();
  let db = firebase.firestore();
  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("ir_meter");
};

export const getGenMeter = (accountId, locationId) => {
  firestoreService.init();
  let db = firebase.firestore();
  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("gen_meter");
};

export const getGenMeterIdDetail = (accountId, locationId, genMeterId) => {
  firestoreService.init();
  let db = firebase.firestore();
  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("gen_meter")
    .doc(genMeterId)
    .collection("log")
    .orderBy("timestamp", "desc")
    .limit(1);
};

export const getExportMeterDetail = (accountId, locationId, exportMeterId) => {
  firestoreService.init();
  let db = firebase.firestore();
  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("export_meter")
    .doc(exportMeterId)
    .collection("log")
    .orderBy("timestamp", "desc")
    .limit(1);
};

export const getInverterIdDetail = (accountId, locationId, inverterId) => {
  firestoreService.init();
  let db = firebase.firestore();
  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("inverter")
    .doc(inverterId)
    .collection("log")
    .orderBy("timestamp", "desc")
    .limit(1);
};

export const getIRMeterDetail = (accountId, locationId, irMeterId) => {
  firestoreService.init();
  let db = firebase.firestore();
  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("ir_meter")
    .doc(irMeterId)
    .collection("log")
    .orderBy("timestamp", "desc")
    .limit(1);
};
export const getInverterIdMaintenance = (
  accountId,
  locationId,
  inverterId,
  docId
) => {
  firestoreService.init();
  let db = firebase.firestore();
  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("inverter")
    .doc(inverterId)
    .collection("log")
    .doc(docId)
    .collection("maintenance");
};

export const getLatestInverterAlarmLog = (
  accountId,
  locationId,
  inverterId
) => {
  firestoreService.init();
  let db = firebase.firestore();
  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("inverter")
    .doc(inverterId)
    .collection("alarm_log")
    .orderBy("timestamp", "desc")
    .limit(1);
};

export const getAlarmLog = (accountId, locationId, inverterId, docId) => {
  firestoreService.init();
  let db = firebase.firestore();
  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("inverter")
    .doc(inverterId)
    .collection("alarm_log")
    .doc(docId)
    .collection("log")
    .orderBy("status_code", "desc");
};

// Overview
export const getOverviewLog = (accountId, locationId, overviewId) => {
  let startOfYear = moment().startOf("year");
  startOfYear = startOfYear.toDate();

  let startOfMonth = moment().startOf("month");
  startOfMonth = startOfMonth.toDate();

  let startOfDay = moment().startOf("day");
  startOfDay = startOfDay.toDate();

  let whereCondition = startOfYear;
  let overview = "monthly";

  switch (overviewId) {
    case "yearly":
      whereCondition = startOfYear;
      overview = "monthly";
      break;
    case "monthly":
      whereCondition = startOfMonth;
      overview = "daily";
      break;
    case "daily":
      whereCondition = startOfDay;
      overview = "half_hourly";
      break;
    case "half_hourly":
      whereCondition = startOfDay;
      overview = "half_hourly";
      break;
    case "minute":
      whereCondition = startOfDay;
      overview = "minute";
      break;
  }

  firestoreService.init();
  let db = firebase.firestore();
  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("overview")
    .doc(overview)
    .collection("log")
    .where("timestamp", ">=", whereCondition)
    .orderBy("timestamp", "desc");
};

// Revenue
export const getRevenueLog = (accountId, locationId, overviewId) => {
  let startOfYear = moment().startOf("year");
  startOfYear = startOfYear.toDate();

  let startOfMonth = moment().startOf("month");
  startOfMonth = startOfMonth.toDate();

  let whereCondition = startOfYear;
  let overview = "monthly";

  switch (overviewId) {
    case "yearly":
      whereCondition = startOfYear;
      overview = "monthly";
      break;
    case "monthly":
      whereCondition = startOfMonth;
      overview = "daily";
      break;
  }

  firestoreService.init();
  let db = firebase.firestore();
  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("overview")
    .doc(overview)
    .collection("log")
    .where("timestamp", ">=", whereCondition)
    .orderBy("timestamp", "desc");
};

export const getActivityLog = (accountId, locationId) => {
  firestoreService.init();
  let db = firebase.firestore();
  // let docs = [];
  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("ev_charger");
};

export const getTransactionLog = (accountId, locationId, docId) => {
  firestoreService.init();
  let db = firebase.firestore();
  return db
    .collection("account")
    .doc(accountId)
    .collection("location")
    .doc(locationId)
    .collection("ev_charger")
    .doc(docId)
    .collection("transaction_log")
    .orderBy("timestamp", "desc");
};
