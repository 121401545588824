import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import { Breadcrumbs } from "@material-ui/lab";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  breadcrumbText: {
    color: "#363636 !important",
    textDecoration: "none",
    textTransform: "uppercase",
    fontWeight: "300",
  },
  titleText: {
    textTransform: "uppercase",
    textDecoration: "none",
  },
  linkText: {
    marginLeft: "1.5rem",
    marginTop: "0.5rem",
  },
  textLastEdited: {
    color: "#898989",
  },
  table: {
    minWidth: 700,
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "0.6rem",
    backgroundColor: "#e8e8e8",
  },
});

class AddUserHeader extends Component {
  state = {};
  render() {
    const { classes } = this.props;

    console.log("thisd props", this.props);

    return (
      <div className="w-full">
        <div className="flex flex-col">
          <div className={classes.header}>
            <i class="las la-user-friends text-24 mr-12"></i> MANAGE USERS
          </div>
          <Paper square>
            <Breadcrumbs
              separator={<i class="las la-angle-right"></i>}
              arial-label="Breadcrumb"
              className="p-6"
            >
              <Link to="/dashboard">
                <Typography className="flex font-600">
                  <i class="las la-home text-20"></i>
                </Typography>
              </Link>
              <Link to={`/dashboard`}>
                <Typography className="font-600">Dashboard</Typography>
              </Link>
              <Link to={`/manage_users`}>
                <Typography className="font-600">User List</Typography>
              </Link>
              <Link to={`/manage_users/${this.props.userid}`}>
                <Typography className="font-600">Edit User</Typography>
              </Link>
            </Breadcrumbs>
          </Paper>
          {/* <div className="flex mt-40 ml-64">
            <Typography
              to="/dashboard"
              component={Link}
              className={classes.breadcrumbText}
            >
              Dashboard
            </Typography>
            <p>&nbsp;>&nbsp;</p>
            <Typography
              className={classes.breadcrumbText}
              to="/manage_users"
              component={Link}
            >
              Manage Users
            </Typography>
            <p>&nbsp;>&nbsp;</p>
            <Typography className={classes.breadcrumbText}>User</Typography>
          </div>
          <div className="flex mt-16 ml-64">
            <Typography className={classes.titleText} variant="h5">
              Edit User Profile
            </Typography>
          </div> */}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AddUserHeader);
