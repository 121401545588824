import React, { useState, useEffect } from "react";
import {
  Typography,
  withStyles,
  AppBar,
  Toolbar,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  TablePagination,
} from "@material-ui/core";
import moment from "moment";
import { getActivityLog, getTransactionLog } from "../tabs/utils";
import _ from "lodash";

const styles = (theme) => ({
  whiteBg: {
    backgroundColor: "white",
  },
  button: {
    backgroundColor: "#eaf1fe",
    margin: "0 5px",
    fontWeight: 300,
  },
  subText: {
    color: "#707070",
    fontSize: 12,
    fontWeight: 300,
  },
  iconCircle: {
    backgroundColor: "#eaf1fe",
    border: "1px solid #eaf1fe",
    borderRadius: 50,
    padding: 5,
    display: "flex",
    alignItems: "center",
  },
});

const ActivityGrid = ({ classes, accountId, locationId }) => {
  const [transactions, setTransactions] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [noOfItems, setNoOfItems] = useState(0);
  const [editedTransactions, setEditedTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const docId = [];
    const transactionLog = [];

    getActivityLog(accountId, locationId).onSnapshot((collectionSnapshot) => {
      collectionSnapshot.forEach(function(doc) {
        docId.push(doc.id);
      });

      docId.forEach(function(docId) {
        getTransactionLog(accountId, locationId, docId).onSnapshot(
          (collectionSnapshot) => {
            collectionSnapshot.forEach(function(doc) {
              transactionLog.push(doc.data());
            });
            setTransactions(transactionLog);
          }
        );
      });
    });
  }, []);

  useEffect(() => {
    setIsLoading(false);
    setEditedTransactions(_.slice(transactions, page, rowsPerPage));
    setNoOfItems(transactions.length);
  }, [transactions]);

  const handleChangePage = (event, currPage) => {
    setPage(currPage);
    currPage === 0
      ? setEditedTransactions(_.slice(transactions, currPage, rowsPerPage))
      : setEditedTransactions(
          _.slice(
            transactions,
            rowsPerPage * currPage,
            rowsPerPage * currPage + rowsPerPage
          )
        );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setEditedTransactions(_.slice(transactions, page, event.target.value));
  };

  return (
    <div className="bg-white w-full">
      <AppBar
        position="static"
        color="default"
        classes={{ colorDefault: classes.whiteBg }}
      >
        <Toolbar>
          <div className="flex items-center flex-grow">
            <Typography className="text-16 font-300">Activities</Typography>
          </div>
          {/* <div>
            <Button classes={{ root: classes.button }}>View All</Button>
          </div> */}
        </Toolbar>
      </AppBar>
      <div className="p-20">
        {isLoading ? (
          <div className="flex justify-center my-20">
            <CircularProgress />
          </div>
        ) : (
          <div className="w-full">
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date Time</TableCell>
                    <TableCell>Feed</TableCell>
                    <TableCell>Paid</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {editedTransactions.map((item) => (
                    <TableRow key={item.transactionId}>
                      <TableCell>
                        {moment
                          .unix(item.timestamp.seconds)
                          .format("YYYY-MM-DD HH:mm:ss")}
                      </TableCell>
                      <TableCell>{item.status}</TableCell>
                      <TableCell>
                        ${item.amount_paid ? item.amount_paid : 0}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={noOfItems}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(ActivityGrid);
