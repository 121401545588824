import config from "./firebaseServiceConfig";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";
import { meters } from "../fuse-configs/enums";
import moment from "moment";
import _ from "lodash";

const { INVERTER, GEN, EXPORT, IR } = meters;

const settings = { timestampsInSnapshots: true };

class firestoreService {
  init() {
    if (firebase.apps.length) {
      return;
    }
    firebase.initializeApp(config);
    this.db = firebase.database();
    this.auth = firebase.auth();
    this.storage = firebase.storage();
    this.chartSubscriber = null;
    this.overviewSubscriber = null;
    this.inverterSubscriber = null;
    this.genSubscriber = null;
    this.exportSubscriber = null;
    this.irSubscriber = null;
    firebase.firestore().settings(settings);
  }

  getAllAccountId = () => {
    let data = [];
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("account").onSnapshot(function(doc) {
        doc.forEach((doc) => {
          const { account_id } = doc.data();
          data.push(account_id);
          resolve(data);
        });
      });
    });
  };

  getAllAccount = () => {
    let data = [];
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("account").onSnapshot(function(doc) {
        doc.forEach((doc) => {
          data.push(doc.data());
          resolve(data);
        });
      });
    });
  };

  getAccount = (accountId) => {
    let data = {};
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("account")
        .doc(accountId)
        .onSnapshot((snapshot) => {
          data = snapshot.data();
          resolve(data);
        });
    });
  };

  deleteAccount = (accountId) => {
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("account")
        .doc(accountId)
        .delete()
        .then(() => {
          resolve("Account successfully deleted");
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getAllLocationId = () => {
    let data = [];
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("location").onSnapshot(function(doc) {
        doc.forEach((doc) => {
          const { location_id } = doc.data();
          data.push(location_id);
          resolve(data);
        });
      });
    });
  };

  getAllLocation = () => {
    let data = [];
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("location").onSnapshot(function(doc) {
        doc.forEach((doc) => {
          data.push(doc.data());
          resolve(data);
        });
      });
    });
  };

  getLocation = (locationId) => {
    let data = {};
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("location")
        .doc(locationId)
        .onSnapshot((snapshot) => {
          data = snapshot.data();
          resolve(data);
        });
    });
  };

  getAccountLocation(accountId, locationId) {
    let data = {};
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("account")
        .doc(accountId)
        .collection("location")
        .doc(locationId)
        .onSnapshot((snapshot) => {
          data = snapshot.data();
          resolve(data);
        });
    });
  }

  getAllUsers = () => {
    let data = [];
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("user").onSnapshot(function(doc) {
        doc.forEach((doc) => {
          data.push(doc.data());
          resolve(data);
        });
      });
    });
  };

  getAllAccountlessUsers = () => {
    let array = [];
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("user").onSnapshot(function(doc) {
        doc.forEach((doc) => {
          const data = doc.data();
          if (_.isEmpty(data.assigned_accounts) || !data.assigned_accounts) {
            array.push(doc.data());
          }
          resolve(array);
        });
      });
    });
  };

  getOverview = (accountId, locationId, onQuerySnapshot) => {
    if (this.overviewSubscriber != null) {
      this.overviewSubscriber();
    }

    let db = firebase.firestore();
    this.overviewSubscriber = db
      .collection("account")
      .doc(accountId)
      .collection("location")
      .doc(locationId)
      .collection("overview")
      .doc("aggregated")
      .collection("log")
      .orderBy("timestamp", "desc")
      .limit(1)
      .onSnapshot(onQuerySnapshot);
  };

  getMetersId = (accountId, locationId) => {
    let meters = [];
    return new Promise((resolve, reject) => {
      this.getInverterId(accountId, locationId)
        .then((inverters) => {
          meters.push(inverters);
          return this.getGenMeter(accountId, locationId);
        })
        .then((gens) => {
          meters.push(gens);
          return this.getExpId(accountId, locationId);
        })
        .then((exp) => {
          meters.push(exp);
          return this.getIRId(accountId, locationId);
        })
        .then((ir) => {
          meters.push(ir);
          resolve({ meters });
        });
    });
  };

  getInverterId = (accountId, locationId) => {
    const inverters = [];
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("account")
        .doc(accountId)
        .collection("location")
        .doc(locationId)
        .collection("inverter")
        .orderBy("status_code", "desc")
        .onSnapshot(function(doc) {
          doc.forEach((doc) => {
            const { inverter_id, status, label } = doc.data();
            inverters.push({
              inverter_id,
              label,
              status,
            });
          });

          resolve({ type: INVERTER, inverters: inverters });
        });
    });
  };

  getGenMeter(accountId, locationId) {
    const gen_ids = [];
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("account")
        .doc(accountId)
        .collection("location")
        .doc(locationId)
        .collection("gen_meter")
        .onSnapshot(function(doc) {
          doc.forEach((doc) => {
            const { gen_meter_id, status, label } = doc.data();
            gen_ids.push({
              gen_meter_id,
              label,
              status,
            });
          });
          resolve({ type: GEN, gens: gen_ids });
        });
    });
  }

  getExpId = (accountId, locationId) => {
    const exp_meter = [];
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("account")
        .doc(accountId)
        .collection("location")
        .doc(locationId)
        .collection("export_meter")
        .onSnapshot(function(doc) {
          doc.forEach((doc) => {
            const { exp_meter_id, status, label } = doc.data();
            exp_meter.push({
              exp_meter_id,
              label,
              status,
            });
          });
          resolve({ type: EXPORT, exp: exp_meter });
        });
    });
  };

  getIRId = (accountId, locationId) => {
    const ir_meter = [];
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("account")
        .doc(accountId)
        .collection("location")
        .doc(locationId)
        .collection("ir_meter")
        .onSnapshot(function(doc) {
          doc.forEach((doc) => {
            const { ir_meter_id, status, label } = doc.data();
            ir_meter.push({
              ir_meter_id,
              label,
              status,
            });
          });
          resolve({ type: IR, ir: ir_meter });
        });
    });
  };

  getInverterDetails = (
    accountId,
    locationId,
    inverterId,
    status,
    onQuerySnapshot
  ) => {
    // if (this.inverterSubscriber != null) {
    //     this.inverterSubscriber();
    // }

    if (inverterId) {
      let db = firebase.firestore();
      this.inverterSubscriber = db
        .collection("account")
        .doc(accountId)
        .collection("location")
        .doc(locationId)
        .collection("inverter")
        .doc(inverterId)
        .collection("log")
        .orderBy("timestamp", "desc")
        .limit(1)
        .onSnapshot(onQuerySnapshot);
    }
  };

  getInverterMaintenance = (
    accountId,
    locationId,
    inverterId,
    inverters,
    docId
  ) => {
    let maintenance = [];
    var db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("account")
        .doc(accountId)
        .collection("location")
        .doc(locationId)
        .collection("inverter")
        .doc(inverterId)
        .collection("log")
        .doc(docId)
        .collection("maintenance")
        .onSnapshot((onQuerySnapshot) => {
          onQuerySnapshot.forEach((doc) => {
            const data = doc.data();
            maintenance.push(data);
          });
          resolve({ inverter: inverters, maintenance: maintenance });
        });
    });
  };

  getSingleInverter = (accountId, locationId, inverterId, status) => {
    return new Promise((resolve, reject) => {
      this.getInverterDetails(
        accountId,
        locationId,
        inverterId,
        status,
        (querySnapshot) => {
          let inverters = [];

          querySnapshot.forEach((doc) => {
            let docId = doc.id;
            const { energy, frequency, power } = doc.data();
            inverters.push({
              docId,
              inverterId,
              status,
              energy,
              frequency,
              power,
            });
          });
          resolve(inverters);
        }
      );
    });
  };

  getAllInverterDetail = (accountId, locationId, inverterId, status) => {
    return new Promise((resolve, reject) => {
      this.getSingleInverter(accountId, locationId, inverterId, status)
        .then((inverters) => {
          return this.getInverterMaintenance(
            accountId,
            locationId,
            inverterId,
            inverters,
            inverters[0].docId
          );
        })
        .then((maintenance) => {
          resolve({ maintenance });
        });
    });
  };

  getGenDetails = (accountId, locationId, genId, status, onQuerySnapshot) => {
    // if (this.genSubscriber != null) {
    //     this.genSubscriber();
    // }

    let db = firebase.firestore();
    this.genSubscriber = db
      .collection("account")
      .doc(accountId)
      .collection("location")
      .doc(locationId)
      .collection("gen_meter")
      .doc(genId)
      .collection("log")
      .orderBy("timestamp", "desc")
      .limit(1)
      .onSnapshot(onQuerySnapshot);
  };

  getExpDetails = (accountId, locationId, expId, status, onQuerySnapshot) => {
    // if (this.exportSubscriber != null) {
    //     this.exportSubscriber();
    // }

    let db = firebase.firestore();
    this.exportSubscriber = db
      .collection("account")
      .doc(accountId)
      .collection("location")
      .doc(locationId)
      .collection("export_meter")
      .doc(expId)
      .collection("log")
      .orderBy("timestamp", "desc")
      .limit(1)
      .onSnapshot(onQuerySnapshot);
  };

  getIRDetails = (accountId, locationId, irId, status, onQuerySnapshot) => {
    // if (this.irSubscriber != null) {
    //     this.irSubscriber();
    // }

    let db = firebase.firestore();
    this.irSubscriber = db
      .collection("account")
      .doc(accountId)
      .collection("location")
      .doc(locationId)
      .collection("ir_meter")
      .doc(irId)
      .collection("log")
      .orderBy("timestamp", "desc")
      .limit(1)
      .onSnapshot(onQuerySnapshot);
  };

  getOverviewChartByHour = (accountId, locationId, onQuerySnapshot) => {
    if (this.chartSubscriber != null) {
      this.chartSubscriber();
    }

    // Want to obtain data from the last hour onwards.
    let lastHour = moment().subtract(1, "hours");
    lastHour = lastHour.toDate();
    console.log("## last hour: ", lastHour);

    let db = firebase.firestore();
    this.chartSubscriber = db
      .collection("account")
      .doc(accountId)
      .collection("location")
      .doc(locationId)
      .collection("overview")
      .doc("five_minute")
      .collection("log")
      .where("timestamp", ">=", lastHour)
      .onSnapshot(onQuerySnapshot);
  };

  getOverviewChartByDay = (accountId, locationId, onQuerySnapshot) => {
    if (this.chartSubscriber != null) {
      this.chartSubscriber();
    }

    // Want to obtain data from 7am onwards.
    let morning = moment()
      .hour(7)
      .minute(0)
      .second(0);
    morning = morning.toDate();
    let db = firebase.firestore();
    this.chartSubscriber = db
      .collection("account")
      .doc(accountId)
      .collection("location")
      .doc(locationId)
      .collection("overview")
      .doc("half_hourly")
      .collection("log")
      .where("timestamp", ">=", morning)
      .onSnapshot(onQuerySnapshot);
  };

  getOverviewChartByMonth = (accountId, locationId, onQuerySnapshot) => {
    if (this.chartSubscriber != null) {
      this.chartSubscriber();
    }

    // Want to obtain data from the last hour onwards.
    let startOfMonth = moment().startOf("month");
    startOfMonth = startOfMonth.toDate();

    let db = firebase.firestore();
    this.chartSubscriber = db
      .collection("account")
      .doc(accountId)
      .collection("location")
      .doc(locationId)
      .collection("overview")
      .doc("daily")
      .collection("log")
      .where("timestamp", ">=", startOfMonth)
      .onSnapshot(onQuerySnapshot);
  };

  getOverviewChartByYear = (accountId, locationId, onQuerySnapshot) => {
    if (this.chartSubscriber != null) {
      this.chartSubscriber();
    }

    // Want to obtain data from the last hour onwards.
    let startOfYear = moment().startOf("year");
    startOfYear = startOfYear.toDate();

    let db = firebase.firestore();
    this.chartSubscriber = db
      .collection("account")
      .doc(accountId)
      .collection("location")
      .doc(locationId)
      .collection("overview")
      .doc("monthly")
      .collection("log")
      .where("timestamp", ">=", startOfYear)
      .onSnapshot(onQuerySnapshot);
  };

  getAlarmCollectionCount = (accountId, locationId) => {
    let db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("account")
        .doc(accountId)
        .collection("location")
        .doc(locationId)
        .collection("overview")
        .doc("alarm_history")
        .collection("log")
        .onSnapshot((collectionSnapshot) => {
          let collectionCount = collectionSnapshot.docs.length;
          resolve(collectionCount);
        });
    });
  };

  getInitialQueryAlarmAndAlerts = (accountId, locationId) => {
    let db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("account")
        .doc(accountId)
        .collection("location")
        .doc(locationId)
        .collection("overview")
        .doc("alarm_history")
        .collection("log")
        .orderBy("timestamp", "desc")
        .limit(15)
        .onSnapshot((documentSnapshot) => {
          let data = [];

          let lastField =
            documentSnapshot.docs[documentSnapshot.docs.length - 1];

          documentSnapshot.forEach((doc) => {
            data.push(doc.data());
          });

          resolve({ data, lastField, collectionCount: 41 });
        });
    });
  };

  getNextPageAlarmsAndAlerts = (accountId, locationId, paramLastField) => {
    let data = [];
    let db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("account")
        .doc(accountId)
        .collection("location")
        .doc(locationId)
        .collection("overview")
        .doc("alarm_history")
        .collection("log")
        .orderBy("timestamp", "desc")
        .startAfter(paramLastField)
        .limit(15)
        .onSnapshot(function(documentSnapshots) {
          let lastField =
            documentSnapshots.docs[documentSnapshots.docs.length - 1];

          let firstField = documentSnapshots.docs[0];

          documentSnapshots.forEach((doc) => {
            data.push(doc.data());
            // resolve({ data, lastField, firstField });
          });

          db.collection("account")
            .doc(accountId)
            .collection("location")
            .doc(locationId)
            .collection("overview")
            .doc("alarm_history")
            .collection("log")
            .get()
            .then((collectionSnapshot) => {
              let collectionCount = collectionSnapshot.docs.length;
              resolve({ data, lastField, firstField, collectionCount });
            });
        });
    });
  };

  getPrevPageAlarmsAndAlerts = (accountId, locationId, paramFirstField) => {
    let data = [];
    let db = firebase.firestore();
    return new Promise((resolve, reject) => {
      db.collection("account")
        .doc(accountId)
        .collection("location")
        .doc(locationId)
        .collection("overview")
        .doc("alarm_history")
        .collection("log")
        .orderBy("timestamp", "desc")
        .endBefore(paramFirstField)
        .limitToLast(15)
        .onSnapshot(function(documentSnapshots) {
          let lastField =
            documentSnapshots.docs[documentSnapshots.docs.length - 1];

          let firstField = documentSnapshots.docs[0];

          documentSnapshots.forEach((doc) => {
            data.push(doc.data());
            // resolve({ data, lastField, firstField });
          });

          db.collection("account")
            .doc(accountId)
            .collection("location")
            .doc(locationId)
            .collection("overview")
            .doc("alarm_history")
            .collection("log")
            .get()
            .then((collectionSnapshot) => {
              let collectionCount = collectionSnapshot.docs.length;
              resolve({ data, lastField, firstField, collectionCount });
            });
        });
    });
  };
}
const instance = new firestoreService();

export default instance;
