import React, { Component } from "react";
import { withFormsy } from "formsy-react";
import { filter, includes } from "lodash";
import PropTypes from "prop-types";

const applyWithFormsyToReactSelect = WrappedComponent => {
  class SelectWithMUIFormsy extends Component {
    static propTypes = {
      ...withFormsy.propTypes,
      valueKey: PropTypes.string,
      entity: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      parseFormDataToValue: PropTypes.func,
      parseValueToFormData: PropTypes.func,
      parseEntityToOption: PropTypes.func,
    };

    static defaultProps = {
      valueKey: "id",
      parseValueToFormData: value => (value ? value.value : null),
      parseFormDataToValue: formData => formData,
      parseEntityToOption: entity => entity,
    };

    changeValue = value => {
      const { isMulti } = this.props;
      const formData = isMulti
        ? value.map(v => this.props.parseValueToFormData(v))
        : this.props.parseValueToFormData(value);

      this.props.setValue(formData);

      if (this.props.onChange) {
        this.props.onChange(value);
      }
    };

    parseEntityToOptions = (entity = []) =>
      entity.map(entry => this.props.parseEntityToOption(entry));

    calculateValue = () => {
      const { isMulti } = this.props;
      const filteredEntity = this.filterEntityByIds();
      const options = this.parseEntityToOptions(filteredEntity);
      const value = isMulti ? options : options[0];
      return value;
    };

    getId = () =>
      this.props.parseFormDataToValue(this.props.getValue()) || null;

    getIds = () => {
      const formData = this.props.getValue() || [];
      return formData.map(value => this.props.parseFormDataToValue(value));
    };

    filterEntityByIds = () => {
      const { isMulti, entity, valueKey } = this.props;
      const filteredEntity = filter(entity, entry => {
        return includes(
          isMulti ? this.getIds() : [this.getId()],
          entry[valueKey] || entry // for the case where the entity props is an array instead of an array of object
        );
      });

      return filteredEntity;
    };

    render() {
      const {
        hasValue,
        getValue,
        setValue,
        resetValue,
        getErrorMessage,
        getErrorMessages,
        isValid,
        isValidValue,
        setValidations,
        validationError,
        validationErrors,
        isRequired,
        showRequired,
        showError,
        isPristine,
        isFormDisabled,
        isFormSubmitted,
        disabled,
        classes,
        entity,
        ...otherProps
      } = this.props;
      //separating props injected by withFormsy HOC and withStyle HOC from props that are passed to this component

      const errorMessage = getErrorMessage();
      const value = this.calculateValue() || [];
      const shouldDisable = isFormDisabled() || disabled;

      return (
        <WrappedComponent
          {...otherProps}
          value={value}
          onChange={this.changeValue}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          isDisabled={shouldDisable}
          options={this.parseEntityToOptions(entity)}
        />
      );
    }
  }

  const ExportedComponent = withFormsy(SelectWithMUIFormsy);
  ExportedComponent.displayName = "SelectWithMUIFormsy";
  ExportedComponent.propTypes = SelectWithMUIFormsy.propTypes;

  return ExportedComponent;
};

applyWithFormsyToReactSelect.propTypes = {
  WrappedComponent: PropTypes.element,
};

export default applyWithFormsyToReactSelect;
