import React, { useState, useEffect } from "react";
import {
  Popover,
  Typography,
  Button,
  MenuItem,
  ListItemText,
  withStyles,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
import firestoreService from "../../../firebaseService/firestoreService";
import axios from "axios";
import { BASE_URL } from "fuse-configs/apiConfig";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as locationActions from "../location/store/actions";
import reducer from "../location/store/reducers";
import withReducer from "store/withReducer";

const styles = (theme) => ({
  locationButton: {
    backgroundColor: "white",
    width: "100%",
    marginLeft: 20,
    marginRight: 20,
    "&:hover": {
      backgroundColor: "white",
    },
  },
});

const NavItem = ({ key, place, onClickMenu }) => {
  if (place.status) {
    return (
      <MenuItem
        key={key}
        onClick={() => {
          onClickMenu(
            `/location/${place.accountId}/${place.location_id}`,
            place
          );
        }}
      >
        <ListItemText className="ml-12" primary={place.name} />
      </MenuItem>
    );
  }
  return null;
};

const LocationSwitcher = (props) => {
  const { classes, history, selectedLocation } = props;

  const [locationMenu, setLocationMenu] = useState(null);
  const [locations, setLocations] = useState([]);

  const onClickAllLocation = (url) => {
    setLocationMenu(null);
    props.setSelectedLocation(null);
    history.push(url);
  };

  const onClickMenu = (url, place) => {
    setLocationMenu(null);
    props.setSelectedLocation(place);
    // setCurrentLocation(text);
    history.push({
      pathname: url,
      state: {
        place: place
      }
    });
  };

  useEffect(() => {
    const getAllAccount = () => {
      return new Promise((resolve, reject) => {
        firestoreService.init();
        firestoreService.getAllAccountId().then((data) => {
          let accounts = [];
          data.map((account) => accounts.push(account));
          resolve(accounts);
        });
      });
    };

    const getLocation = (accountId) => {
      return new Promise((resolve, reject) => {
        axios
          .get(BASE_URL + `/account/${accountId}/location`)
          .then((response) => {
            if (response.status) {
              let data = response.data;
              let newData = [];
              let locations = [];
              data.forEach((map) => {
                newData = { ...map, accountId: accountId };
                locations.push(newData);
              });
              resolve(locations);
            } else {
              reject(response.data.message);
            }
          });
      });
    };

    const getAllLocations = async () => {
      const arrAccount = await getAllAccount();
      const arrPromise = arrAccount.map((accountId) => {
        return getLocation(accountId);
      });

      Promise.all(arrPromise)
        .then((arrLocation) => {
          let locations = [];
          arrLocation.forEach((loc) => {
            locations = [...locations, ...loc];
          });
          setLocations(locations);
        })
        .catch((err) => {
          throw new Error(err);
        });
    };

    getAllLocations();
  }, []);

  useEffect(() => {
    console.log({selectedLocation})
  }, [selectedLocation])

  return (
    <div>
      <div className="flex justify-center w-full">
        <Button
          className={classes.locationButton}
          onClick={(event) => {
            setLocationMenu(event.currentTarget);
          }}
        >
          <div className="flex items-center">
            <i class="las la-map-marked-alt text-14"></i>
            <Typography className="normal-case ml-12">
              {selectedLocation.data
                ? selectedLocation.data.name
                : "Location Switcher"}
            </Typography>
            <BsChevronDown className="ml-52" />
          </div>
        </Button>
      </div>

      <Popover
        open={Boolean(locationMenu)}
        anchorEl={locationMenu}
        onClose={() => {
          setLocationMenu(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <React.Fragment>
          <MenuItem
            onClick={() => {
              onClickAllLocation("/dashboard");
            }}
          >
            <ListItemText className="ml-12" primary="All Location" />
          </MenuItem>
          {locations.map((item, index) => (
            <NavItem
              key={item + index}
              place={item}
              onClickMenu={onClickMenu}
            />
          ))}
        </React.Fragment>
      </Popover>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSelectedLocation: locationActions.setSelectedLocation,
    },
    dispatch
  );
}

function mapStateToProps({ loc }) {
  return {
    selectedLocation: loc.location.selectedLocation,
  };
}

export default withReducer("loc", reducer)(
  withStyles(styles, { withTheme: true })(
    withRouter(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(LocationSwitcher)
    )
  )
);
