import React from "react";
import { fieldToError } from "../../helpers";

const fieldToDateTimePicker = WrappedDateTimePicker => ({
  field,
  form,
  ...otherProps
}) => {
  const { error, errorMessage } = fieldToError({ field, form });

  const handleChange = changedDate =>
    form.setFieldValue(field.name, changedDate);
  // cannot just use field.onChange() to handle the changes,
  // because field.onChange() are using event.target.value to set to form
  // but material-ui-pickers onChange directly return the value without event

  const handleBlur = () => form.setFieldTouched(field.name);

  return (
    <WrappedDateTimePicker
      {...field}
      {...otherProps}
      error={error}
      helperText={errorMessage}
      value={field.value}
      onChange={handleChange}
      onClose={handleBlur}
      //onBlur is not working properly in material-ui-pickers v2
      //temp fix: set the form field to be touched when user closes the datetime picker
      //see https://github.com/mui-org/material-ui-pickers/issues/825
    />
  );
};

export default fieldToDateTimePicker;
