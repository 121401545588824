import React from "react";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";

const UploadedFile = ({ uploadUrl, onChange }) => {
  return (
    <List dense>
      <ListItem component="a" target="_blank" href={uploadUrl} button>
        <ListItemIcon>
          <AttachFileIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={uploadUrl} />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={() => onChange(undefined)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
};

UploadedFile.propTypes = {
  uploadUrl: PropTypes.string,
  onChange: PropTypes.func,
};

UploadedFile.defaultProps = {
  uploadUrl: null,
  onChange: () => {},
};

export default UploadedFile;
