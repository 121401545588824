import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import { Link } from "react-router-dom";
import {
  Avatar,
  Button,
  Typography,
  CircularProgress,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Divider,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import moment from "moment";
import _ from "lodash";

import DisplayAccountType from "./DisplayAccountType";
import defaultImg from "assets/default.svg";
import { getAllUsers, getUserOfAnAccount, getAUser, deleteAUser } from "../api";
import { connect } from "react-redux";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  breadcrumbText: {
    color: "#363636 !important",
    textDecoration: "none",
    textTransform: "uppercase",
    fontWeight: "300",
  },
  titleText: {
    textTransform: "uppercase",
    textDecoration: "none",
  },
  linkText: {
    marginLeft: "1.5rem",
    marginTop: "0.5rem",
  },
  textLastEdited: {
    color: "#898989",
  },
  table: {
    minWidth: 350,
    // overflowX: "auto",
    border: "1px solid #e8e8e8",
  },
  avatar: {
    margin: 10,
  },
  addUserButton: {
    textTransform: "none",
    color: "white",
    borderRadius: 0,
  },
  deleteButton: {
    backgroundColor: "#c32a3b",
    color: "white",
  },
  editButton: {
    backgroundColor: "#22292f",
    color: "white",
  },
  searchButton: {
    backgroundColor: "#3e75b4",
    borderRadius: 0,
    textTransform: "none",
  },
  resetButton: {
    backgroundColor: "#dfe5f1",
    borderRadius: 0,
    textTransform: "none",
  },
  actionCell: {
    backgroundColor: "white",
    border: "1px solid #e8e8e8",
  },
  input: {
    padding: "6px 0 7px",
  },
  outline: {
    borderRadius: 0,
  },
});

class ManageUsersContent extends Component {
  state = {
    users: [],
    isLoading: false,
    rowsPerPage: 5,
    page: 0,
    editedUsers: [],
    searchBar: "",
    noOfItems: 0,
    deleteDialog: false,
    selectedUser: null,
  };

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.loadData();
  }

  loadData = () => {
    if (this.props.role == 99) {
      getAllUsers().then((res) =>
        this.setState({
          users: res.data,
          isLoading: false,
          editedUsers: _.slice(
            res.data,
            this.state.page,
            this.state.rowsPerPage
          ),
          noOfItems: res.data.length,
        })
      );
      // .catch(err => console.log(err));
    } else if (this.props.role == 30) {
      let allAssignedUsers = [];

      getAUser(this.props.uid).then((res) => {
        allAssignedUsers.push({
          userid: this.props.uid,
          ...res.data,
        });
      });
      // .catch(err => console.log(err));

      async function batchGetMultipleUsers(users) {
        for (let userIndex in users) {
          const resolved = await getUserOfAnAccount(users[userIndex]);
          for (let i = 0; i < resolved.data.length; i++) {
            resolved.data[i].userid = resolved.data[i]["user_id"];
            delete resolved.data[i].user_id;
          }
          allAssignedUsers.push(...resolved.data);
        }
        return allAssignedUsers;
      }
      batchGetMultipleUsers(this.props.assigned_accounts).then((val) => {
        let uniqueUsersArray = _.uniqBy(val, "userid");
        this.setState({
          users: uniqueUsersArray,
          isLoading: false,
          editedUsers: _.slice(
            uniqueUsersArray,
            this.state.page,
            this.state.rowsPerPage
          ),
          noOfItems: uniqueUsersArray.length,
        });
      });
    }
  };

  handleChangePage = (event, page) => {
    this.setState({
      page,
    });
    page == 0
      ? this.setState({
          editedUsers: _.slice(this.state.users, page, this.state.rowsPerPage),
        })
      : this.setState({
          editedUsers: _.slice(
            this.state.users,
            this.state.rowsPerPage * page,
            this.state.rowsPerPage * page + this.state.rowsPerPage
          ),
        });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: event.target.value,
      editedUsers: _.slice(
        this.state.users,
        this.state.page,
        event.target.value
      ),
    });
  };

  searchKeyword = () => {
    const { searchBar } = this.state;
    this.setState({ isLoading: true });

    setTimeout(() => {
      const results = this.state.editedUsers.filter(
        (item) =>
          item.name.toLowerCase().includes(searchBar.toLowerCase()) ||
          item.email.toLowerCase().includes(searchBar.toLowerCase())
      );
      this.setState({
        isLoading: false,
        editedUsers: results,
        noOfItems: results.length,
      });
    }, 3000);
  };

  resetSearch = () => {
    this.setState({ isLoading: true, searchBar: "" });
    setTimeout(() => {
      this.setState({
        isLoading: false,
        editedUsers: _.slice(
          this.state.users,
          this.state.page,
          this.state.rowsPerPage
        ),
        noOfItems: this.state.users.length,
      });
    }, 3000);
  };

  openDialog = (userid) => {
    let user = this.state.users.find((item) => item.userid === userid);

    this.setState({
      deleteDialog: true,
      selectedUser: user,
    });
  };

  closeDialog = () => {
    this.setState({
      deleteDialog: false,
      selectedUser: null,
    });
  };

  deleteUser = () => {
    const { selectedUser } = this.state;
    this.setState({ isLoading: true });
    deleteAUser(selectedUser.userid).then(() => {
      setTimeout(() => {
        this.loadData();
      }, 3000);
    });
  };

  render() {
    const { classes } = this.props;
    const { users, isLoading, editedUsers, searchBar } = this.state;

    console.log({ editedUsers });

    return (
      <Paper square className="mt-12">
        <div className="flex justify-between items-center p-6">
          <Typography className="ml-12">USER TABLE</Typography>
          {this.props.role !== 30 && this.props.role !== 20 && (
            <div className="mr-12">
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to={"/manage_users/new_user"}
                classes={{ containedSecondary: classes.addUserButton }}
              >
                Add User
              </Button>
            </div>
          )}
        </div>
        <Divider className="bg-grey" />
        <div className="ml-12 m-12 w-1/2">
          <div className="flex justify-between items-center">
            <Typography>
              <i class="las la-filter mr-6"></i>
              <span className="text-grey">Filter</span>
            </Typography>
            <Tooltip title="Search by User Name, Email">
              <IconButton>
                <i class="las la-info-circle"></i>
              </IconButton>
            </Tooltip>
          </div>
          <TextField
            label="Keyword"
            variant="outlined"
            margin="normal"
            value={searchBar}
            fullWidth
            onChange={(event) =>
              this.setState({ searchBar: event.target.value })
            }
            InputProps={{
              classes: {
                input: classes.input,
                notchedOutline: classes.outline,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <i class="las la-search"></i>
                </InputAdornment>
              ),
            }}
          />
          <div className="flex">
            <Button
              className="mx-6 text-white"
              classes={{ root: classes.searchButton }}
              onClick={this.searchKeyword}
            >
              Search
            </Button>
            <Button
              className="mx-6"
              classes={{ root: classes.resetButton }}
              onClick={this.resetSearch}
            >
              Reset
            </Button>
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center py-64">
            <CircularProgress size={48} />
          </div>
        ) : (
          <div className="flex flex-col">
            <div className={classes.root}>
              {/* <div className="flex ml-64 items-end"> */}
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Account Type</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Last Activity</TableCell>
                    <TableCell>Date Created</TableCell>
                    <TableCell className={classes.actionCell}>
                      Actions
                    </TableCell>
                    {/* <TableCell>Name</TableCell>
                  <TableCell>Account</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Account Type</TableCell>
                  <TableCell>Last Activity</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell /> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {editedUsers.map(
                    ({
                      name,
                      userid,
                      assigned_accounts,
                      assigned_locations,
                      email,
                      user_role,
                      updated_on,
                      created_on,
                      img_uri,
                    }) => (
                      <TableRow key={userid} hover={true}>
                        <TableCell>{name}</TableCell>
                        <TableCell>
                          {email}
                          {/* {assigned_accounts && assigned_accounts[0]
                          ? assigned_accounts[0]
                          : "-"} */}
                        </TableCell>
                        <TableCell>
                          <DisplayAccountType type={user_role} />
                        </TableCell>
                        <TableCell>
                          {!_.isEmpty(assigned_accounts) ||
                          !_.isEmpty(assigned_locations)
                            ? "Assigned"
                            : "Unassigned"}
                        </TableCell>
                        <TableCell>
                          {updated_on
                            ? moment
                                .unix(updated_on._seconds)
                                .format("DD MMMM YYYY @ HH:mm")
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {created_on
                            ? moment
                                .unix(created_on._seconds)
                                .format("DD MMMM YYYY @ HH:mm")
                            : "-"}
                        </TableCell>
                        <TableCell className={classes.actionCell}>
                          <div className="flex justify-center">
                            <Button
                              variant="contained"
                              classes={{ contained: classes.editButton }}
                              component={Link}
                              to={`/manage_users/${userid}`}
                            >
                              <i class="las la-pen text-20"></i>
                            </Button>
                            <Button
                              variant="contained"
                              classes={{ contained: classes.deleteButton }}
                              onClick={() => this.openDialog(userid)}
                            >
                              <i class="las la-trash text-20"></i>
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
              {/* </div> */}
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={this.state.noOfItems}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </div>
        )}
        {this.state.selectedUser && (
          <Dialog open={this.state.deleteDialog} onClose={this.closeDialog}>
            <DialogTitle>Delete Users</DialogTitle>
            <DialogContent>
              <DialogContentText>{`Are you sure you want to delete ${this.state.selectedUser.name}?`}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.deleteLocation()}>Confirm</Button>
              <Button onClick={this.closeDialog}>Cancel</Button>
            </DialogActions>
          </Dialog>
        )}
      </Paper>
    );
  }
}

function mapStateToProps({ auth }) {
  const { role, uid, data } = auth.user;
  const { assigned_accounts, assigned_locations } = data;

  return {
    role,
    uid,
    assigned_accounts,
    assigned_locations,
  };
}
export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, null)(ManageUsersContent)
);
