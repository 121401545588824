import React from "react";
import {
  withStyles,
  AppBar,
  Typography,
  Avatar,
  Hidden,
  TextField,
} from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import { FuseNavigation, FuseLayouts } from "@fuse";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import classNames from "classnames";
import { countries } from "country-data";
import flagLogo from "./assets/flag_sg.png";
import { PUBLIC_URL } from "config";
import LocationSwitcher from "./content/component/LocationSwitcher";
import withReducer from "store/withReducer";
import reducer from "./content/location/store/reducers";
import * as locationActions from "./content/location/store/actions";

const Logo = `${PUBLIC_URL}/LG.png`;
const Logo2x = `${PUBLIC_URL}/LG@2x.png`;

const styles = (theme) => ({
  root: {
    "& .user": {
      "& .username, & .email": {
        transition: theme.transitions.create("opacity", {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.easeInOut,
        }),
      },
    },
  },
  avatar: {
    width: 72,
    height: 72,
    position: "absolute",
    //top       : 84,
    top: 60,
    padding: 8,
    // background: theme.palette.background.default,
    boxSizing: "content-box",
    left: "50%",
    transform: "translateX(-50%)",
    "& > img": {
      borderRadius: "50%",
      border: "10px solid white",
    },
  },
  logo: {
    position: "absolute",
    top: -40,
    left: 0,
    right: 0,
    margin: "auto",
    width: 157,
  },
  logoWrapper: {
    position: "relative",
    height: 110,
    width: "100%",
  },
  locationButton: {
    backgroundColor: "white",
    width: "100%",
    marginLeft: 20,
    marginRight: 20,
    "&:hover": {
      backgroundColor: "white",
    },
  },
});

const MainNavbar = ({
  classes,
  navigation,
  selectedLocation,
  layoutStyle,
  user,
  setSelectedLocation
}) => {
  // Flag here to just hide fields that are currently not needed.
  // Not sure if it'll be needed in the future.
  const hideUnusedFields = true;
  const assignedRegions = user.data.assigned_regions;

  let countryName;
  if (assignedRegions && assignedRegions.length > 0) {
    countryName = countries[assignedRegions[0]].name;
  } else {
    countryName = "Not Available";
  }

  function UserHeader() {
    return (
      <AppBar
        position="static"
        color="primary"
        elevation={0}
        className="user relative flex flex-col items-center justify-center pt-16 pb-56 mb-24 z-0"
      >
        <Typography
          className="text-16 font-600 uppercase whitespace-no-wrap"
          style={{ color: "white" }}
        >
          - {countryName} -
        </Typography>

        <Typography
          className="text-13 font-200 mt-8 whitespace-no-wrap"
          style={{ color: "white" }}
        >
          {!hideUnusedFields && "4 Active Pylons"}
        </Typography>

        <Avatar
          className={classNames(classes.avatar, "avatar")}
          src={flagLogo}
        />
      </AppBar>
    );
  }

  const navigationLayout = FuseLayouts[layoutStyle].type;

  return (
    <div className={classes.root}>
      {navigationLayout === "vertical" ? (
        <React.Fragment>
          {/* <UserHeader /> */}
          <Link
            to="/dashboard"
            onClick={() => {
              setSelectedLocation(null);
            }}
          >
            <div className={classes.logoWrapper}>
              <img
                className={classes.logo}
                src={Logo}
                srcSet={`${Logo}, ${Logo2x}`}
              />
            </div>
          </Link>

          <LocationSwitcher />

          {!hideUnusedFields ? (
            <TextField
              className="ml-24 mr-24"
              name="search"
              placeholder="Search"
              margin="dense"
              variant="outlined"
            />
          ) : (
            <div className="h-4 mb-16" />
          )}

          <FuseNavigation
            navigation={navigation}
            selectedLocation={selectedLocation}
            layout={navigationLayout}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Hidden lgUp>
            <UserHeader />
          </Hidden>
          <FuseNavigation
            navigation={navigation}
            selectedLocation={selectedLocation}
            layout={navigationLayout}
          />
        </React.Fragment>
      )}
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSelectedLocation: locationActions.setSelectedLocation,
    },
    dispatch
  );
}

function mapStateToProps({ fuse, auth, loc }) {
  return {
    navigation: fuse.navigation,
    layoutStyle: fuse.settings.current.layout.style,
    user: auth.user,
    selectedLocation: loc.location.selectedLocation,
  };
}

export default withReducer("loc", reducer)(
  withStyles(styles, { withTheme: true })(
    withRouter(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(MainNavbar)
    )
  )
);
