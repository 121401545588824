import React from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@material-ui/core";
import { FuseAnimate } from "@fuse";

const HeaderActionButton = ({
  isLoading,
  children,
  onClick,
  disabled,
  ...otherProps
}) => {
  return (
    <FuseAnimate animation="transition.slideRightIn" delay={300}>
      <div className="flex flex-row items-center">
        {/* <div className="ml-16"> */}
        <div>
          <Button
            className="whitespace-no-wrap"
            variant="contained"
            disabled={disabled || isLoading}
            onClick={onClick}
            {...otherProps}
          >
            {children}
            {isLoading && (
              <CircularProgress size={24} color="primary" className="ml-8" />
            )}
          </Button>
        </div>
      </div>
    </FuseAnimate>
  );
};

HeaderActionButton.defaultProps = {
  isLoading: false,
  disabled: false
};

HeaderActionButton.propTypes = {
  /** If true, a loading indicator will be shown and the button will be disabled. */
  isLoading: PropTypes.bool,
  /** The button text to be shown */
  children: PropTypes.node.isRequired,
  /** Callback function that is triggerred on the clicked of the button. */
  onClick: PropTypes.func.isRequired,
  /** If true, the button will be disabled. */
  disabled: PropTypes.bool
};

export default HeaderActionButton;
