import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import {
  TextFieldFormik,
  SubmitButtonFormik,
  SelectFormik,
} from "@massiveinfinity/components/Form/FormikInputs";
import {
  Button,
  Typography,
  InputAdornment,
  Paper,
  Divider,
} from "@material-ui/core";
import { sectorSelect, timezoneSelect } from "./const";
import { withStyles } from "@material-ui/core/styles/index";
import * as Actions from "store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import AccountsSelect from "./AccountsSelect";
import { addLocationForAnAccount } from "./api";
import validationSchema from "./validationSchema";
import validationSchemaForNonMaster from "./validationSchemaForNonMaster";

const styles = (theme) => ({
  submitButton: {
    backgroundColor: "#68f72a",
    margin: theme.spacing.unit * 2,
    textTransform: "uppercase",
  },
  createButton: {
    backgroundColor: "#4AA5D2",
    margin: theme.spacing.unit * 2,
    textTransform: "none",
    color: "white",
    borderRadius: 0,
  },
  cancelButton: {
    backgroundColor: "#5cccec",
    margin: theme.spacing.unit * 2,
    textTransform: "none",
    color: "white",
    borderRadius: 0,
  },
});

class AddLocationContent extends Component {
  state = {
    pre_filled_location_id: "",
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.pre_filled_location_id !== nextState.pre_filled_location_id
    ) {
      return true;
    }
  }

  render() {
    const { classes, showErrorMessage, showSuccessMessage } = this.props;
    return (
      <Paper square className="mt-12">
        <div className="flex justify-between items-center p-6">
          <Typography className="m-12">ADD LOCATION</Typography>
        </div>
        <Divider className="bg-grey" />

        <Formik
          onSubmit={(values, { setSubmitting }) => {
            addLocationForAnAccount(values.account, {
              ...values,
              location_id:
                this.state.pre_filled_location_id + values.location_id,
            })
              .then((res) => {
                showSuccessMessage("Location successfully added");
                this.props.history.push({
                  pathname: "/manage_locations",
                });
              })
              .catch((err) => {
                showErrorMessage(err.response.data.message);
              });
            setSubmitting(false);
          }}
          validationSchema={
            this.props.role == 99
              ? validationSchema
              : validationSchemaForNonMaster
          }
          initialValues={
            this.props.role == 30
              ? {
                  timezone: "UTC+8",
                  account: this.props.assigned_accounts[0],
                }
              : {
                  timezone: "UTC+8",
                }
          }
        >
          {(formikRenderProps) => (
            <Form className="mx-12">
              {formikRenderProps.values.account &&
                this.setState({
                  pre_filled_location_id:
                    formikRenderProps.values.account + "_",
                })}
              <div className="flex flex-row">
                <div className="w-full">
                  <Typography variant="h5" className="my-12">
                    LOCATION INFORMATION
                  </Typography>
                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Account:</div>
                    <div className="w-full">
                      {this.props.role == 30 ? (
                        <Field
                          name="account"
                          component={SelectFormik}
                          options={[
                            {
                              label: this.props.assigned_accounts[0],
                              value: this.props.assigned_accounts[0],
                            },
                          ]}
                          isDisabled
                        />
                      ) : (
                        <Field name="account" component={AccountsSelect} />
                      )}
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Location name:</div>
                    <div className="w-full">
                      <Field
                        name="name"
                        label="Location Name"
                        component={TextFieldFormik}
                        fullWidth
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Location ID:</div>
                    <div className="w-full flex">
                      <Field
                        name="pre_filled_location_id"
                        component={TextFieldFormik}
                        disabled
                        fullWidth
                        value={this.state.pre_filled_location_id}
                      />
                      <Field
                        name="location_id"
                        label="Location ID"
                        component={TextFieldFormik}
                        fullWidth
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Sector:</div>
                    <div className="w-full">
                      <Field
                        name="sector"
                        label="Sector"
                        component={SelectFormik}
                        options={sectorSelect}
                        fullWidth
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Address:</div>
                    <div className="w-full">
                      <Field
                        name="address"
                        label="Address"
                        multiline
                        component={TextFieldFormik}
                        fullWidth
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Timezone:</div>
                    <div className="w-full">
                      <Field
                        name="timezone"
                        label="Time zone"
                        component={SelectFormik}
                        options={timezoneSelect}
                        isDisabled
                        fullWidth
                      />
                    </div>
                  </div>

                  {this.props.role == 99 ? (
                    <div className="flex flex-row items-center my-8">
                      <div className="w-2/5">Invested Amount:</div>
                      <div className="w-full">
                        <Field
                          type="number"
                          name="invested_amt"
                          label="SGD $"
                          component={TextFieldFormik}
                          fullWidth
                        />
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Tariff:</div>
                    <div className="w-full">
                      <Field
                        type="number"
                        name="tariff"
                        label="SGD $"
                        component={TextFieldFormik}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">/kWh</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <Typography variant="h5" className="my-12">
                    SOLAR SYSTEM INFORMATION
                  </Typography>
                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Panel Brand:</div>
                    <div className="w-full">
                      <Field
                        name="panel_brand"
                        label="Panel brand"
                        component={TextFieldFormik}
                        fullWidth
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Panel Count:</div>
                    <div className="w-full">
                      <Field
                        type="number"
                        name="panel_count"
                        label="Panel count"
                        component={TextFieldFormik}
                        fullWidth
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Panel Rating:</div>
                    <div className="w-full">
                      <Field
                        type="number"
                        name="panel_rating"
                        label="Panel rating"
                        component={TextFieldFormik}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">/W</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">System Size:</div>
                    <div className="w-full">
                      <Field
                        type="number"
                        name="system_size"
                        label="System size"
                        component={TextFieldFormik}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">/kWp</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Area of one panel:</div>
                    <div className="w-full">
                      <Field
                        type="number"
                        name="panel_area"
                        label="Area of one panel"
                        component={TextFieldFormik}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              m<sup>2</sup>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center my-8">
                    <div className="w-2/5">Panel Efficiency:</div>
                    <div className="w-full">
                      <Field
                        type="number"
                        name="panel_efficiency"
                        label="Panel Efficiency"
                        component={TextFieldFormik}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex justify-center">
                    <SubmitButtonFormik
                      formikRenderProps={formikRenderProps}
                      className={classes.createButton}
                    >
                      Create
                    </SubmitButtonFormik>
                    <Button
                      className={classes.cancelButton}
                      component={Link}
                      to="/manage_locations"
                    >
                      Exit To Location List
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Paper>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSuccessMessage: Actions.showSuccessMessage,
      showErrorMessage: Actions.showErrorMessage,
    },
    dispatch
  );
}

function mapStateToProps({ auth }) {
  const { role, uid, data } = auth.user;
  const { assigned_accounts } = data;
  return {
    role,
    uid,
    assigned_accounts,
  };
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(AddLocationContent)
);
