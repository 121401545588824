import React, { useEffect, useMemo, useState } from "react";
import {
  Typography,
  withStyles,
  Button,
  AppBar,
  Toolbar,
  Grid,
  Paper,
} from "@material-ui/core";
import {
  BsArrowLeftRight,
  BsArrowUpShort,
  BsArrowDownShort,
  BsCloudSun,
  BsThermometerHigh,
} from "react-icons/bs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// all values here to be changed

const styles = (theme) => ({
  chart: {
    maxHeight: "100%",
    width: "100%",
  },
});

const ChartDisplay = ({ classes, type, options, series }) => {
  const chartOptions = useMemo(() => options, [options]);
  const chartSeries = useMemo(() => series, [series]);

  if (!options || !series) {
    return null;
  }

  if (type === "line") {
    return (
      <div className={classes.chart}>
        <Line options={chartOptions} data={chartSeries} />
      </div>
    );
  }

  if (type === "bar") {
    return (
      <div className={classes.chart}>
        <Bar options={chartOptions} data={chartSeries} />
      </div>
    );
  }

  return null;
};

export default withStyles(styles, { withTheme: true })(ChartDisplay);
