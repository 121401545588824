import React from "react";
import PropTypes from "prop-types";
import HeaderActionButton from "@massiveinfinity/components/HeaderActionButton";
import {
  canSubmitFormik,
  getSubmitFuncFormik,
  isSubmittingFormik,
} from "../../helpers";
import { formRenderProps } from "../../types";

const SubmitButtonFormik = ({ formikRenderProps, children, ...otherProps }) => {
  return (
    <HeaderActionButton
      onClick={getSubmitFuncFormik(formikRenderProps)}
      isLoading={isSubmittingFormik(formikRenderProps)}
      disabled={!canSubmitFormik(formikRenderProps)}
      {...otherProps}
    >
      {children}
    </HeaderActionButton>
  );
};

SubmitButtonFormik.propTypes = {
  formikRenderProps: formRenderProps,
  children: PropTypes.node,
};

export default SubmitButtonFormik;
