import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import { Formik, Form, Field } from "formik";
import { Link } from "react-router-dom";
import {
  TextFieldFormik,
  SubmitButtonFormik,
  SelectFormik,
} from "@massiveinfinity/components/Form/FormikInputs";
import {
  CircularProgress,
  Button,
  Paper,
  Divider,
  Typography,
} from "@material-ui/core";
import {
  canSubmitFormik,
  getSubmitFuncFormik,
  isSubmittingFormik,
} from "../../../../@massiveinfinity/components/Form/FormikInputs/helpers";
import {
  AllRoleType,
  RoleTypeWithoutMasterAndRegionAdmin,
} from "../AddUserComponent/const";
import { getAUser, editUser } from "../api";

import * as Actions from "store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import UserAvatar from "./UserImageComponent";
import validationSchema from "./validationSchema";
import firebaseService from "firebaseService";
import * as userActions from "auth/store/actions";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  breadcrumbText: {
    color: "#363636 !important",
    textDecoration: "none",
    textTransform: "uppercase",
    fontWeight: "300",
  },
  titleText: {
    textTransform: "uppercase",
    textDecoration: "none",
  },
  linkText: {
    marginLeft: "1.5rem",
    marginTop: "0.5rem",
  },
  textLastEdited: {
    color: "#898989",
    fontStyle: "italic",
    marginTop: "0.5rem",
    marginLeft: "2rem",
    fontSize: "1.125rem",
  },
  textSupportLink: {
    color: "#01c0ff !important",
    fontWeight: "300",
    fontStyle: "italic",
    fontSize: "1.3rem",
  },
  submitButton: {
    backgroundColor: "#68f72a",
    margin: theme.spacing.unit * 2,
    textTransform: "uppercase",
  },
  margin: {
    margin: theme.spacing.unit * 2,
  },
  createButton: {
    backgroundColor: "#4AA5D2",
    margin: theme.spacing.unit * 2,
    textTransform: "none",
    color: "white",
    borderRadius: 0,
  },
  cancelButton: {
    backgroundColor: "#5cccec",
    margin: theme.spacing.unit * 2,
    textTransform: "none",
    color: "white",
    borderRadius: 0,
  },
});

class EditUserContent extends Component {
  state = {
    data: {},
    isLoading: false,
    redirectToUserPage: false,
  };

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.getUserDataToSetState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.avatar !== this.props.avatar) {
      this.getUserDataToSetState();
    }

    if (
      this.props.userid == this.props.loginID &&
      prevState.data.user_role &&
      prevState.data.user_role != this.state.data.user_role
    ) {
      this.props.logout();
    }
  }

  getUserDataToSetState = () => {
    getAUser(this.props.userid).then((res) => {
      this.setState({
        data: {
          ...res.data,
          user_role: res.data.user_role.toString(),
          password: "",
          confirmPassword: "",
        },
      });
      this.setState({ isLoading: false });
    });
    // .catch(err => console.log(err));
  };

  render() {
    const { data, isLoading } = this.state;
    const {
      userid,
      classes,
      showErrorMessage,
      showSuccessMessage,
      loginID,
      loginUserRole,
    } = this.props;
    return (
      <Paper square className="mt-12">
        <div className="flex justify-between items-center p-6">
          <Typography className="m-12">EDIT USER</Typography>
        </div>
        <Divider className="bg-grey" />
        {isLoading ? (
          <div className="flex justify-center py-64">
            <CircularProgress size={48} />
          </div>
        ) : (
          <div className="m-12">
            <Formik
              onSubmit={(values, { setSubmitting }) => {
                if (values.password || values.confirmPassword) {
                  if (values.email == data.email) {
                    // console.log("Password edited Email same");
                    firebaseService
                      .authenticateWithEmailAndPassword(
                        values.email,
                        values.password
                      )
                      .then((res) =>
                        editUser(userid, {
                          name: values.name,
                          password: values.confirmPassword,
                          contact: values.contact,
                          user_role: parseInt(values.user_role),
                        })
                          .then(() => {
                            let messagePromise = new Promise(function(
                              resolve,
                              reject
                            ) {
                              showSuccessMessage(
                                "User data and password successfully edited"
                              );
                              resolve("Form successfully Edited");
                            });

                            return messagePromise.then(() => {
                              setSubmitting(false);
                              this.state.redirectToUserPage
                                ? this.props.history.push({
                                    pathname: "/manage_users",
                                  })
                                : console.log("done");
                            });
                          })
                          .catch((err) => {
                            showErrorMessage(err.response.data.message);
                            setSubmitting(false);
                          })
                      )
                      .catch((err) => {
                        this.props.showErrorMessage(err.message);
                        setSubmitting(false);
                      });
                  } else {
                    // console.log("Password edited Email not same");
                    firebaseService
                      .authenticateWithEmailAndPassword(
                        values.email,
                        values.password
                      )
                      .then((res) =>
                        editUser(userid, {
                          name: values.name,
                          password: values.confirmPassword,
                          email: values.email,
                          contact: values.contact,
                          user_role: parseInt(values.user_role),
                        })
                          .then(() => {
                            let messagePromise = new Promise(function(
                              resolve,
                              reject
                            ) {
                              showSuccessMessage(
                                "User data and password successfully edited"
                              );
                              resolve("Form successfully Edited");
                            });

                            return messagePromise.then(() => {
                              setSubmitting(false);
                              this.state.redirectToUserPage
                                ? this.props.history.push({
                                    pathname: "/manage_users",
                                  })
                                : console.log("done");
                            });
                          })
                          .catch((err) => {
                            showErrorMessage(err.response.data.message);
                            setSubmitting(false);
                          })
                      )
                      .catch((err) => {
                        this.props.showErrorMessage(err.message);
                        setSubmitting(false);
                      });
                  }
                } else {
                  if (values.email == data.email) {
                    // console.log("Password not edited Email same");
                    editUser(userid, {
                      name: values.name,
                      // email: values.email,
                      contact: values.contact,
                      user_role: parseInt(values.user_role),
                    })
                      .then(() => {
                        let messagePromise = new Promise(function(
                          resolve,
                          reject
                        ) {
                          showSuccessMessage("User data successfully edited");
                          resolve("Form successfully Edited");
                        });

                        return messagePromise.then(() => {
                          setSubmitting(false);
                          this.state.redirectToUserPage
                            ? this.props.history.push({
                                pathname: "/manage_users",
                              })
                            : console.log("done");
                        });
                      })
                      .catch((err) => {
                        showErrorMessage(err.response.data.message);
                        setSubmitting(false);
                      });
                  } else {
                    // console.log("Password not edited Email not same");
                    editUser(userid, {
                      name: values.name,
                      email: values.email,
                      contact: values.contact,
                      user_role: parseInt(values.user_role),
                    })
                      .then(() => {
                        let messagePromise = new Promise(function(
                          resolve,
                          reject
                        ) {
                          showSuccessMessage("User data successfully edited");
                          resolve("Form successfully Edited");
                        });

                        return messagePromise.then(() => {
                          setSubmitting(false);
                          this.state.redirectToUserPage
                            ? this.props.history.push({
                                pathname: "/manage_users",
                              })
                            : console.log("done");
                        });
                      })
                      .catch((err) => {
                        showErrorMessage(err.response.data.message);
                        setSubmitting(false);
                      });
                  }
                }
              }}
              initialValues={data}
              enableReinitialize
              validationSchema={validationSchema}
            >
              {(formikRenderProps) => (
                <Form className="mx-12">
                  <Typography variant="h5" className="my-12">
                    USER INFORMATION
                  </Typography>
                  <div className="flex flex-row">
                    <div>
                      <UserAvatar
                        alt=""
                        src={data.img_uri}
                        name={data.name}
                        userId={userid}
                      />
                    </div>
                    <div className="w-full pl-32">
                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Name:</div>
                        <Field
                          name="name"
                          label="Name"
                          component={TextFieldFormik}
                          className="w-full"
                          // disabled={
                          //   loginUserRole == 30 &&
                          //   data.user_role == 30 &&
                          //   loginID != userid
                          // }
                        />
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Account Role:</div>
                        <Field
                          name="user_role"
                          label="Account Role"
                          component={SelectFormik}
                          options={
                            loginUserRole == 30
                              ? RoleTypeWithoutMasterAndRegionAdmin
                              : AllRoleType
                          }
                          className="w-full"
                          // isDisabled={
                          // (loginUserRole == 30 && userid == loginID) ||
                          // (loginUserRole == 30 &&
                          //   data.user_role == 30 &&
                          //   loginID != userid)

                          // loginUserRole == 30
                          // }
                        />
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Old Password:</div>
                        <Field
                          name="password"
                          label="Old Password"
                          component={TextFieldFormik}
                          className="w-full"
                          type="password"
                          // disabled={
                          //   loginUserRole == 30 &&
                          //   data.user_role == 30 &&
                          //   loginID != userid
                          // }
                        />
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">New Password:</div>
                        <Field
                          name="confirmPassword"
                          label="New Password"
                          component={TextFieldFormik}
                          className="w-full"
                          type="password"
                          // disabled={
                          //   loginUserRole == 30 &&
                          //   data.user_role == 30 &&
                          //   loginID != userid
                          // }
                        />
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Assigned Accounts:</div>
                        <Field
                          name="assigned_accounts"
                          label="Assigned Accounts"
                          component={TextFieldFormik}
                          className="w-full"
                          disabled
                        />
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Assigned Locations:</div>
                        <Field
                          name="assigned_locations"
                          label="Assigned Locations"
                          component={TextFieldFormik}
                          className="w-full"
                          disabled
                        />
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Region:</div>
                        <Field
                          name="assigned_regions"
                          label="Region"
                          component={TextFieldFormik}
                          className="w-full"
                          disabled
                        />
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Contact Number:</div>
                        <Field
                          name="contact"
                          label="Contact Number"
                          component={TextFieldFormik}
                          className="w-full"
                          // disabled={
                          //   loginUserRole == 30 &&
                          //   data.user_role == 30 &&
                          //   loginID != userid
                          // }
                        />
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Email:</div>
                        <Field
                          name="email"
                          label="Email"
                          component={TextFieldFormik}
                          className="w-full"
                          // disabled={
                          //   loginUserRole == 30 &&
                          //   data.user_role == 30 &&
                          //   loginID != userid
                          // }
                        />
                      </div>

                      {/* <div className="flex flex-row items-center my-8">
                        <Button
                          variant="contained"
                          className={classes.submitButton}
                          onClick={() => {
                            const submitFormFn = getSubmitFuncFormik(
                              formikRenderProps
                            );
                            submitFormFn().then(() => {
                              this.setState({
                                redirectToUserPage: false,
                              });
                            });
                          }}
                          isLoading={isSubmittingFormik(formikRenderProps)}
                          disabled={!canSubmitFormik(formikRenderProps)}
                        >
                          Save
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.submitButton}
                          onClick={() => {
                            const submitFormFn = getSubmitFuncFormik(
                              formikRenderProps
                            );
                            submitFormFn().then((res) => {
                              this.setState({
                                redirectToUserPage: true,
                              });
                            });
                          }}
                          isLoading={isSubmittingFormik(formikRenderProps)}
                          disabled={!canSubmitFormik(formikRenderProps)}
                        >
                          Save & Exit
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.cancelButton}
                          component={Link}
                          to={"/manage_users"}
                        >
                          Cancel
                        </Button>
                      </div> */}
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <Button
                      variant="contained"
                      className={classes.submitButton}
                      onClick={() => {
                        const submitFormFn = getSubmitFuncFormik(
                          formikRenderProps
                        );
                        submitFormFn().then((res) => {
                          this.setState({
                            redirectToUserPage: true,
                          });
                        });
                      }}
                      isLoading={isSubmittingFormik(formikRenderProps)}
                      disabled={!canSubmitFormik(formikRenderProps)}
                    >
                      Save
                    </Button>
                    <Button
                      className={classes.cancelButton}
                      component={Link}
                      to="/manage_users"
                    >
                      Exit To User List
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </Paper>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSuccessMessage: Actions.showSuccessMessage,
      showErrorMessage: Actions.showErrorMessage,
      logout: userActions.logoutUser,
    },
    dispatch
  );
}

function mapStateToProps({ users, auth }) {
  const { avatar } = users;
  const { success, message } = avatar;

  const { user } = auth;
  const { data, role, uid } = user;
  const { email } = data;

  return {
    avatar: {
      success,
      message,
    },
    email,
    loginUserRole: role,
    loginID: uid,
  };
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(EditUserContent)
);
