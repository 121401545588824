import React from "react";
import moment from "moment";
import { Typography } from "@material-ui/core";

const DateWrapper = () => {
  return (
    <div className="flex items-center">
      <i class="las la-calendar text-16"></i>
      <Typography className="ml-8 font-300">
        {moment().format("dddd, Do MMMM YYYY")}
      </Typography>
    </div>
  );
};

export default DateWrapper;
