import React, { Component } from "react";
import { SelectFormik } from "@massiveinfinity/components/Form/FormikInputs";
import { CircularProgress } from "@material-ui/core";
import _ from "lodash";
import firestoreService from "../../../../firebaseService/firestoreService";

class MasterAdminLocationsSelect extends Component {
  state = {
    locations: [],
    isLocationLoading: false,
  };

  componentDidMount() {
    this.setFilterToState(this.props.locations);
    this.setState({
      isLocationLoading: true,
    });
  }

  getAllLocationFn = () =>
    firestoreService
      .getAllLocation()
      .then((res) => {
        let locationsWithLocationId = _.remove(res, function(item) {
          return item.location_id;
        });
        return locationsWithLocationId;
      })
      .catch((err) => console.log(err));

  setFilterToState = (locationsArray) => {
    let combinedArr = [];
    let newArr = [];
    firestoreService.init();
    this.getAllLocationFn()
      .then((availableLocation) => {
        return (combinedArr = locationsArray.concat(availableLocation));
      })
      .then((res) => {
        newArr = _.map(res, (location) =>
          _.pick(location, ["name", "location_id"])
        );
        const mutatedLocationsArr = newArr.map((x) => {
          return {
            label: x.name,
            value: x.location_id,
          };
        });
        this.setState({
          locations: mutatedLocationsArr,
          isLocationLoading: false,
        });
      });
  };

  render() {
    const { locations } = this.state;

    return this.state.isLocationLoading ? (
      <CircularProgress></CircularProgress>
    ) : (
      <SelectFormik options={locations} {...this.props} />
    );
  }
}

export default MasterAdminLocationsSelect;
