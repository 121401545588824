import React from 'react';

const EnergyIcon = ({ colorCode }) => (
  <svg
    width="50"
    height="30"
    viewBox="0 0 130.69 175.31"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={colorCode}
      d="M124.264,87.031c-0.413-1.746-1.696-3.15-3.397-3.715l-25.361-8.455l18.011-36.022c1.131-2.265,0.456-5.022-1.599-6.506
			c-2.064-1.479-4.891-1.256-6.678,0.54l-51.69,51.69c-1.267,1.267-1.788,3.1-1.378,4.84c0.414,1.746,1.696,3.15,3.397,3.715
			l25.361,8.455l-18.011,36.022c-1.131,2.267-0.454,5.021,1.599,6.506c2.058,1.475,4.88,1.26,6.678-0.54l51.69-51.69
			C124.153,90.604,124.673,88.774,124.264,87.031L124.264,87.031z M124.264,87.031"
    />
    <path
      fill={colorCode}
      d="M88.217,0C61.983,0,37.204,12.201,20.676,31.886V26.19c0-2.857-2.312-5.169-5.169-5.169s-5.169,2.312-5.169,5.169v21.957
      c0,4.934,6.371,5.639,5.169,5.169c0.106,0,0.212-0.004,0.317-0.009L36.5,52.024c2.852-0.176,5.018-2.63,4.841-5.476
      c-0.176-2.849-2.664-4.993-5.476-4.841l-10.474,0.65c14.507-19.734,37.981-32.019,62.826-32.019
      c42.756,0,77.879,35.124,77.879,77.879c0,2.857,2.312,5.169,5.169,5.169c2.857,0,5.169-2.312,5.169-5.169
      C176.435,39.764,136.671,0,88.217,0L88.217,0z M88.217,0"
    />
    <path
      fill={colorCode}
      d="M160.61,123.128l-20.676,1.283c-2.852,0.176-5.018,2.63-4.841,5.476c0.176,2.849,2.721,5.018,5.476,4.841l10.474-0.65
        c-14.507,19.734-37.981,32.019-62.826,32.019c-42.756,0-77.879-35.124-77.879-77.879c0-2.857-2.312-5.169-5.169-5.169
        S0,85.36,0,88.217c0,48.454,39.764,88.217,88.217,88.217c26.235,0,51.013-12.201,67.541-31.886v5.696
        c0,2.857,2.312,5.169,5.169,5.169c2.857,0,5.169-2.312,5.169-5.169v-21.957C166.097,125.447,163.374,122.972,160.61,123.128
        L160.61,123.128z M160.61,123.128"
    />
  </svg>
);

export default EnergyIcon;
