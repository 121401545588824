import {authRoles} from 'auth';
import Support from './Support';

export const SupportConfig = {
    settings: {
        layout: {
            config: {
                toolbar: {
                    simple: true
                },
                navbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    disply: false
                }
            }
        }
    },
    auth: authRoles.new,
    routes: [
        {
            path: '/support',
            component: Support
        }
    ]
}