import React, { PropTypes, Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withReducer from "store/withReducer";
import reducer from "../store/reducers";
import { withRouter, Link } from "react-router-dom";
import {
  Typography,
  Card,
  Grid,
  Paper,
  Avatar,
  Button,
} from "@material-ui/core";
import { FuseAnimate, FusePageSimple } from "@fuse";
import _ from "lodash";
import {
  getInverterMeter,
  getExpMeter,
  getIRMeter,
  getGenMeter,
  getInverterIdDetail,
  getInverterIdMaintenance,
  getGenMeterIdDetail,
  getIRMeterDetail,
  getExportMeterDetail,
  getLatestInverterAlarmLog,
  getAlarmLog,
} from "./utils";
import InverterAssetPanel from "./assetsComponent/Inverter";
import IrMeterPanel from "./assetsComponent/IrMeter";
import GenMeterPanel from "./assetsComponent/GenMeter";
import ExpMeterPanel from "./assetsComponent/ExpMeter";
import * as locationActions from "../store/actions";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  inverterText: {
    color: "#A1F95A",
  },
});

class AssetTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inverters: {},
      export_meters: {},
      gen_meters: {},
      ir_meters: {},
    };
  }

  componentDidMount() {
    this.getInverterSnapshot();
    this.getGenMeterSnapshot();
    this.getIrMeterSnapshot();
    this.getExpMeterSnapshot();
    this.props.getLocation(this.props.accountId, this.props.locationId);
  }

  getInverterSnapshot = () => {
    getInverterMeter(this.props.accountId, this.props.locationId).onSnapshot(
      (docs) => {
        let inverterGeneralInfoObj = {};
        docs.forEach((doc) => {
          const { inverter_id, status, label, inverter_serial_no } = doc.data();
          inverterGeneralInfoObj[inverter_id] = {
            inverter_id,
            inverter_serial_no,
            label,
            status,
          };
          this.getInverterIdDetailSnapshot(inverter_id, inverterGeneralInfoObj);
        });
      }
    );
  };

  getInvAlarmStatus = (inverterId, inverterObj) => {
    getLatestInverterAlarmLog(
      this.props.accountId,
      this.props.locationId,
      inverterId
    ).onSnapshot((docs) => {
      docs.forEach((doc) => {
        this.getLatestAlarmLog(inverterId, doc.id, inverterObj);
      });
    });
  };

  getLatestAlarmLog = (inverterId, docId, inverterObj) => {
    getAlarmLog(
      this.props.accountId,
      this.props.locationId,
      inverterId,
      docId
    ).onSnapshot((docs) => {
      let arr = [];
      docs.forEach((doc) => {
        arr.push(doc.data());
      });
      inverterObj[inverterId] = {
        ...inverterObj[inverterId],
        alarmLog: arr,
      };
      this.setState({
        inverters: inverterObj,
      });
    });
  };

  getGenMeterSnapshot = () => {
    getGenMeter(this.props.accountId, this.props.locationId).onSnapshot(
      (docs) => {
        let genMeterObj = {};
        docs.forEach((doc) => {
          const { gen_meter_id, status, label, model } = doc.data();
          genMeterObj[gen_meter_id] = {
            gen_meter_id,
            status,
            label,
            model,
          };
          this.getGenMeterIdDetailSnapshot(gen_meter_id, genMeterObj);
        });
      }
    );
  };

  getIrMeterSnapshot = () => {
    getIRMeter(this.props.accountId, this.props.locationId).onSnapshot(
      (docs) => {
        let irMeterObj = {};
        docs.forEach((doc) => {
          const { ir_meter_id, status, label } = doc.data();
          irMeterObj[ir_meter_id] = {
            ir_meter_id,
            label,
            status,
          };
          this.getIRMeterDetailSnapshot(ir_meter_id, irMeterObj);
        });
      }
    );
  };

  getExpMeterSnapshot = () => {
    getExpMeter(this.props.accountId, this.props.locationId).onSnapshot(
      (docs) => {
        let expMeterObj = {};
        docs.forEach((doc) => {
          const { exp_meter_id, status, label } = doc.data();
          expMeterObj[exp_meter_id] = {
            exp_meter_id,
            label,
            status,
          };
          this.getExpMeterIdDetailSnapshot(exp_meter_id, expMeterObj);
        });
      }
    );
  };

  getInverterIdDetailSnapshot = (inverterId, inverterObj) => {
    getInverterIdDetail(
      this.props.accountId,
      this.props.locationId,
      inverterId
    ).onSnapshot((docs) => {
      docs.forEach((doc) => {
        const { energy, frequency, power } = doc.data();
        inverterObj[inverterId] = {
          ...inverterObj[inverterId],
          energy,
          power,
          frequency,
        };
        this.getInverterIdMaintenanceSnapshot(inverterId, doc.id, inverterObj);
      });
    });
  };

  getGenMeterIdDetailSnapshot = (genMeterId, genMeterObj) => {
    getGenMeterIdDetail(
      this.props.accountId,
      this.props.locationId,
      genMeterId
    ).onSnapshot((docs) => {
      docs.forEach((doc) => {
        const {
          gen_active_energy,
          gen_active_power,
          gen_frequency,
          gen_power_factor,
          logger_cpu_temp,
        } = doc.data();
        genMeterObj[genMeterId] = {
          ...genMeterObj[genMeterId],
          gen_active_energy,
          gen_active_power,
          gen_frequency,
          gen_power_factor,
          logger_cpu_temp,
        };
        this.setState({
          gen_meters: genMeterObj,
        });
      });
    });
  };

  getIRMeterDetailSnapshot = (irMeterId, irMeterObj) => {
    getIRMeterDetail(
      this.props.accountId,
      this.props.locationId,
      irMeterId
    ).onSnapshot((docs) => {
      docs.forEach((doc) => {
        const { ir_panel_temp, ir_sensor_data, logger_cpu_temp } = doc.data();
        irMeterObj[irMeterId] = {
          ...irMeterObj[irMeterId],
          ir_panel_temp,
          ir_sensor_data,
          logger_cpu_temp,
        };
        this.setState({
          ir_meters: irMeterObj,
        });
      });
    });
  };

  getExpMeterIdDetailSnapshot = (expMeterId, expMeterObj) => {
    getExportMeterDetail(
      this.props.accountId,
      this.props.locationId,
      expMeterId
    ).onSnapshot((docs) => {
      docs.forEach((doc) => {
        const {
          export_active_energy,
          export_active_power,
          export_frequency,
          export_power_factor,
          import_active_energy,
          logger_cpu_temp,
        } = doc.data();
        expMeterObj[expMeterId] = {
          ...expMeterObj[expMeterId],
          export_active_energy,
          export_active_power,
          export_frequency,
          export_power_factor,
          import_active_energy,
          logger_cpu_temp,
        };
        this.setState({
          export_meters: expMeterObj,
        });
      });
    });
  };

  getInverterIdMaintenanceSnapshot = (inverterId, docId, inverterObj) => {
    getInverterIdMaintenance(
      this.props.accountId,
      this.props.locationId,
      inverterId,
      docId
    ).onSnapshot((docs) => {
      docs.forEach((doc) => {
        const data = doc.data();
        inverterObj[inverterId] = {
          ...inverterObj[inverterId],
          [doc.id]: data,
        };
      });
      this.getInvAlarmStatus(inverterId, inverterObj);
    });
  };

  renderInvertersBanner = (inverters) => {
    return (
      <>
        {Object.keys(inverters).map(function(key) {
          return <InverterAssetPanel data={inverters[key]} />;
        })}
      </>
    );
  };

  renderExportMeter = (exportMeter) => {
    return (
      <>
        {Object.keys(exportMeter).map(function(key) {
          return <ExpMeterPanel data={exportMeter[key]} />;
        })}
      </>
    );
  };

  renderGenMeter = (genMeter) => {
    return (
      <>
        {Object.keys(genMeter).map(function(key) {
          return <GenMeterPanel data={genMeter[key]} />;
        })}
      </>
    );
  };

  renderIRMeter = (irMeter) => {
    return (
      <>
        {Object.keys(irMeter).map(function(key) {
          return <IrMeterPanel data={irMeter[key]} />;
        })}
      </>
    );
  };

  render() {
    const { inverters, gen_meters, ir_meters, export_meters } = this.state;
    return (
      <div>
        {this.renderInvertersBanner(inverters)}
        {this.renderGenMeter(gen_meters)}
        {this.renderExportMeter(export_meters)}
        {this.renderIRMeter(ir_meters)}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLocation: locationActions.getLocation,
    },
    dispatch
  );
}

function mapStateToProps({ loc, auth }) {
  return {
    metersId: loc.location.metersId,
    user: auth.user,
  };
}

export default withReducer("loc", reducer)(
  withStyles(styles, { withTheme: true })(
    withRouter(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(AssetTab)
    )
  )
);
