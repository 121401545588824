import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import { Link } from "react-router-dom";
import {
  Button,
  Typography,
  CircularProgress,
  Icon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Divider,
} from "@material-ui/core";
import { Breadcrumbs } from "@material-ui/lab";
import { Form, Field, Formik } from "formik";
import {
  TextFieldFormik,
  SelectFormik,
  SubmitButtonFormik,
} from "@massiveinfinity/components/Form/FormikInputs";
import _ from "@lodash";
import {
  canSubmitFormik,
  getSubmitFuncFormik,
  isSubmittingFormik,
} from "../../../../@massiveinfinity/components/Form/FormikInputs/helpers";

import {
  getAnAccount,
  editAccount,
  removeAccount,
  assignUsersToAccount,
  deAssignUsersToAccount,
} from "../api";
import firebaseService from "firebaseService";
import firestoreService from "../../../../firebaseService/firestoreService";
import { getLocation } from "../api";

import validationSchema from "./validationSchema";
import UploadAccountImage from "./UploadAccountImage";
import MasterAdminAccountsSelect from "./MasterAdminAccountsSelect";
import AccAdminAccountsSelect from "./AccAdminAccountsSelect";

import * as Actions from "store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MasterAdminLocationsSelect from "./MasterAdminLocationsSelect";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  breadcrumbText: {
    color: "#363636 !important",
    textDecoration: "none",
    textTransform: "uppercase",
    fontWeight: "300",
  },
  titleText: {
    textTransform: "uppercase",
    textDecoration: "none",
  },
  linkText: {
    marginLeft: "1.5rem",
    marginTop: "0.5rem",
  },
  textLastEdited: {
    color: "#898989",
    fontStyle: "italic",
    marginTop: "0.5rem",
    marginLeft: "2rem",
    fontSize: "1.125rem",
  },
  textSupportLink: {
    color: "#01c0ff !important",
    fontWeight: "300",
    fontStyle: "italic",
    fontSize: "1.3rem",
  },
  submitButton: {
    backgroundColor: "#4AA5D2",
    margin: theme.spacing.unit * 2,
    textTransform: "none",
    color: "white",
    borderRadius: 0,
  },
  cancelButton: {
    backgroundColor: "#5cccec",
    margin: theme.spacing.unit * 2,
    textTransform: "none",
    color: "white",
    borderRadius: 0,
  },
  margin: {
    margin: theme.spacing.unit * 2,
  },
});

class EditAccountForm extends Component {
  state = {
    locations: [],
    account: "",
    isLoading: false,
    open: false,
    passwordPrompt: false,
    redirectToAccountPage: false,
  };

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.getAccDataToSetState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.avatar !== this.props.avatar) {
      this.getAccDataToSetState();
    }
  }

  getAccDataToSetState = async () => {
    firestoreService.init();

    const account = await firestoreService.getAccount(
      this.props.match.params.id
    );
    const location = await getLocation(account.account_id);
    this.setState({
      account: account,
      locations: location.data,
      isLoading: false,
    });
    // getAnAccount(this.props.match.params.id).then((res) =>
    //   this.setState({
    //     account: res.data.account,
    //     locations: res.data.locations,
    //     isLoading: false,
    //   })
    // );
    // .catch(err => console.log(err));
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  openPasswordPrompt = () => {
    this.handleClose();
    this.setState({
      passwordPrompt: true,
    });
  };

  closePasswordPrompt = () => {
    this.setState({
      passwordPrompt: false,
    });
  };

  confirmDeleteAccount = (id) => {
    removeAccount(id)
      .then((res) => {
        this.props.showSuccessMessage("Account Successfully deleted");
        this.setState({ open: false });
        this.props.history.push({
          pathname: "/manage_account",
        });
      })
      .catch((err) => {
        this.props.showErrorMessage(err.response.data.message);
        this.setState({ open: false });
      });
  };

  determinePostOrDeleteUser = (acc_id, initialUser, editedUser) => {
    //async function to assign users
    async function batchAssignUsersToAccount(arr) {
      let results = [];
      for (let i = 0; i < arr.length; i++) {
        const resolved = await assignUsersToAccount(acc_id, arr[i]);
        results.push(resolved);
      }
      return results;
    }

    //async function to deassign users
    async function batchDeassignUsersToAccount(arr) {
      let results = [];
      for (let i = 0; i < arr.length; i++) {
        const resolved = await deAssignUsersToAccount(acc_id, arr[i]);
        results.push(resolved);
      }
      return results;
    }

    //Start condition after this line
    if (initialUser.length == 0) {
      //initially no users assigned, then assign whatever user input to api call
      batchAssignUsersToAccount(editedUser)
        .then((res) => {
          this.props.showSuccessMessage("Form successfully edited");
          this.getAccDataToSetState();
        })
        .catch((err) => {
          this.props.showErrorMessage(err.response.data.message);
        });
    } else {
      const initialDiff = _.difference(initialUser, editedUser);
      const editedDiff = _.difference(editedUser, initialUser);

      if (editedDiff.length == 0) {
        //only deassign users from initial value
        batchDeassignUsersToAccount(initialDiff)
          .then((res) => {
            this.props.showSuccessMessage("Form successfully edited");
            this.getAccDataToSetState();
          })
          .catch((err) => {
            this.props.showErrorMessage(err.response.data.message);
          });
      } else if (initialDiff.length == 0) {
        //only assign extra user from initial value
        batchAssignUsersToAccount(editedDiff)
          .then((res) => {
            this.props.showSuccessMessage("Form successfully edited");
            this.getAccDataToSetState();
          })
          .catch((err) => {
            this.props.showErrorMessage(err.response.data.message);
          });
      } else {
        // console.log("Removing user", initialDiff);
        // console.log("Assigning user", editedDiff);

        batchDeassignUsersToAccount(initialDiff)
          .then(() => {
            // console.log("Finish deassign");
            return batchAssignUsersToAccount(editedDiff);
          })
          .then((res) => {
            this.props.showSuccessMessage("Form successfully edited");
            this.getAccDataToSetState();
          })
          .catch((err) => {
            this.props.showErrorMessage(err.response.data.message);
          });
      }
    }
  };

  checkEditedAccID = (initialID, editedID) => {
    if (initialID == editedID) {
      return true;
    } else {
      return false;
    }
  };

  onKeyDown = (keyEvent) => {
    if (keyEvent.keyCode === 13) {
      keyEvent.preventDefault();
    }
  };

  render() {
    const { classes } = this.props;
    const { account, locations } = this.state;
    const { account_id } = account;

    let locValue = locations.map(function(item) {
      if (item.location_id) {
        return item.location_id;
      }
      return;
    });

    return (
      <div className="w-full">
        <div className="flex flex-col">
          <div className="flex items-center p-6">
            <i class="las la-database text-24 mr-12"></i> EDIT ACCOUNT
          </div>
          <Paper square>
            <Breadcrumbs
              separator={<i class="las la-angle-right"></i>}
              arial-label="Breadcrumb"
              className="p-6"
            >
              <Link to="/dashboard">
                <Typography className="flex font-600">
                  <i class="las la-home text-20"></i>
                </Typography>
              </Link>
              <Link to={`/dashboard`}>
                <Typography className="font-600">Dashboard</Typography>
              </Link>
              <Link to={`/manage_account`}>
                <Typography className="font-600">Account List</Typography>
              </Link>
              <Link to={`/manage_account/${account_id}`}>
                <Typography className="font-600">Edit Account</Typography>
              </Link>
            </Breadcrumbs>
          </Paper>

          <Paper square className="mt-12">
            <div className="flex justify-between items-center p-6">
              <Typography className="ml-12">EDIT ACCOUNT</Typography>
              <div className="mr-12">
                <Button className="text-red" onClick={this.handleClickOpen}>
                  <div className="flex items-center">
                    Delete Account
                    <i class="las la-trash-alt text-20 text-red"></i>
                  </div>
                </Button>
              </div>
            </div>
            <Divider className="bg-grey" />
            <div className="flex mt-16 ml-12 flex-row justify-between">
              <div>
                <Typography className={classes.titleText} variant="h5">
                  Account Information
                </Typography>
              </div>
              {/* <div>
                <Typography className={classes.textLastEdited}>
                  Last Edited On:
                  {"  "}
                  {account.updated_on
                    ? moment
                        .unix(account.updated_on._seconds)
                        .format("DD MMMM YYYY @ HH:MM")
                    : "Not Available"}
                </Typography>
              </div> */}
            </div>

            {this.state.isLoading ? (
              <div className="flex justify-center my-64">
                <CircularProgress size={48} />
              </div>
            ) : (
              <div className="mt-16 ml-12">
                <Formik
                  onSubmit={(
                    {
                      account_id,
                      contact_number,
                      contact_person,
                      name,
                      region,
                      users,
                    },
                    { setSubmitting }
                  ) => {
                    const editedObject = this.checkEditedAccID(
                      this.state.account.account_id,
                      account_id
                    )
                      ? {
                          contact_number,
                          contact_person,
                          name,
                          region,
                        }
                      : {
                          account_id,
                          contact_number,
                          contact_person,
                          name,
                          region,
                        };
                    return locations.length > 0
                      ? editAccount(account.account_id, {
                          contact_number,
                          contact_person,
                          name,
                          region,
                        })
                          .then(() =>
                            this.determinePostOrDeleteUser(
                              account.account_id,
                              account.users,
                              users
                            )
                          )
                          .then(() => {
                            setSubmitting(false);
                            this.state.redirectToAccountPage
                              ? this.props.history.push({
                                  pathname: "/manage_account",
                                })
                              : console.log("done");
                          })
                          .catch(
                            (err) => console.log(err),
                            setSubmitting(false)
                          )
                      : editAccount(account.account_id, editedObject)
                          .then(() =>
                            this.determinePostOrDeleteUser(
                              account.account_id,
                              account.users,
                              users
                            )
                          )
                          .then(() => {
                            this.state.redirectToAccountPage
                              ? this.props.history.push({
                                  pathname: "/manage_account",
                                })
                              : console.log("else edited");
                          })
                          .catch(
                            (err) => console.log(err),
                            setSubmitting(false)
                          );
                  }}
                  validationSchema={validationSchema}
                  initialValues={{
                    name: account.name,
                    account_id: account.account_id,
                    contact_person: account.contact_person,
                    contact_number: account.contact_number,
                    region: account.region,
                    users: account.users,
                    locations: locValue,
                  }}
                  enableReinitialize
                >
                  {(formikRenderProps) => (
                    <Form onKeyDown={this.onKeyDown}>
                      <div className="flex flex-row">
                        <div>
                          <UploadAccountImage
                            accountID={account.account_id}
                            src={account.img_uri}
                          />
                        </div>
                        <div className="w-full pl-32">
                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Account Name:</div>
                            <div className="w-full pr-40">
                              <Field
                                name="name"
                                component={TextFieldFormik}
                                fullWidth
                              />
                            </div>
                          </div>

                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Account ID:</div>
                            <div className="w-full pr-40">
                              <Field
                                name="account_id"
                                component={TextFieldFormik}
                                disabled={locations.length > 0 ? true : false}
                                fullWidth
                              />
                            </div>
                          </div>

                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Contact Person:</div>
                            <div className="w-full pr-40">
                              <Field
                                name="contact_person"
                                component={TextFieldFormik}
                                fullWidth
                              />
                            </div>
                          </div>

                          <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Contact Number:</div>
                            <div className="w-full pr-40">
                              <Field
                                name="contact_number"
                                component={TextFieldFormik}
                                fullWidth
                              />
                            </div>
                          </div>

                          {/* <div className="flex flex-row items-center my-8">
                            <div className="w-2/5">Region:</div>
                            <div className="w-full pr-40">
                              <Field
                                name="region"
                                component={SelectFormik}
                                options={[
                                  {
                                    label: "SG",
                                    value: "SG",
                                  },
                                ]}
                                isDisabled
                                fullWidth
                              />
                            </div>
                          </div> */}
                          {/* 
                          <div className="flex flex-col my-8">
                            <div>Locations</div>
                            <div className="pr-40">
                              {locations.map((location) => {
                                return (
                                  <Button
                                    className="w-full mb-4"
                                    variant="outlined"
                                    component={Link}
                                    to={`/manage_account/${account.account_id}/location/${location.location_id}`}
                                  >
                                    {location.name}
                                  </Button>
                                );
                              })}
                              <Button
                                className="w-full"
                                style={{ backgroundColor: "gray" }}
                                variant="outlined"
                                component={Link}
                                to={`/manage_account/add_location/${account.account_id}`}
                              >
                                +Add New
                              </Button>
                            </div>
                          </div> */}
                          <div className="flex flex-col my-8 mt-4">
                            <div>Locations</div>
                            <div className="mr-40">
                              {locations && (
                                <Field
                                  name="locations"
                                  component={
                                    this.props.user.role == 99
                                      ? MasterAdminLocationsSelect
                                      : AccAdminAccountsSelect
                                  }
                                  isMulti
                                  locations={locations}
                                />
                              )}
                            </div>
                          </div>

                          <div className="flex flex-col my-8 mt-4">
                            <div>Users</div>
                            <div className="mr-40">
                              {account && (
                                <Field
                                  name="users"
                                  component={
                                    this.props.user.role == 99
                                      ? MasterAdminAccountsSelect
                                      : AccAdminAccountsSelect
                                  }
                                  isMulti
                                  users={account.users}
                                />
                              )}
                            </div>
                          </div>

                          <Dialog
                            open={this.state.open}
                            onClose={this.handleClose}
                          >
                            <DialogTitle>
                              {"Are you sure to delete this account?"}
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                This process is irreversible
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={this.handleClose}
                                color="primary"
                              >
                                No
                              </Button>
                              <Button
                                // onClick={account =>
                                //   this.confirmDeleteAccount(account_id)
                                // }
                                onClick={this.openPasswordPrompt}
                                color="primary"
                                autoFocus
                              >
                                Yes
                              </Button>
                            </DialogActions>
                          </Dialog>

                          <Dialog
                            open={this.state.passwordPrompt}
                            onClose={this.closePasswordPrompt}
                          >
                            <DialogTitle>Password Verification</DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                Input your password below to confirm the
                                removing of user's account
                              </DialogContentText>
                              <Formik
                                onSubmit={(values, { setSubmitting }) => {
                                  firebaseService
                                    .signInWithEmailAndPassword(
                                      this.props.email,
                                      values.password
                                    )
                                    .then(() => {
                                      setSubmitting(false);
                                      this.confirmDeleteAccount(account_id);
                                    })
                                    .catch((err) => {
                                      this.props.showErrorMessage(err.message);
                                      setSubmitting(false);
                                    });
                                }}
                                isInitialValid
                              >
                                {(formikRenderProps) => (
                                  <Form>
                                    <Field
                                      type="password"
                                      name="password"
                                      label="Password"
                                      component={TextFieldFormik}
                                      fullWidth
                                    />
                                    <SubmitButtonFormik
                                      formikRenderProps={formikRenderProps}
                                    >
                                      Submit
                                    </SubmitButtonFormik>
                                  </Form>
                                )}
                              </Formik>
                            </DialogContent>
                          </Dialog>
                        </div>
                      </div>
                      <div className="flex flex-row my-8 justify-center">
                        <Button
                          variant="contained"
                          className={classes.submitButton}
                          // onClick={getSubmitFuncFormik(formikRenderProps)}
                          onClick={() => {
                            const submitFormFn = getSubmitFuncFormik(
                              formikRenderProps
                            );
                            submitFormFn().then(() => {
                              this.setState({
                                isLoading: true,
                                redirectToAccountPage: false,
                              });
                              getAnAccount(this.props.match.params.id)
                                .then((res) =>
                                  this.setState({
                                    account: res.data.account,
                                    locations: res.data.locations,
                                    isLoading: false,
                                  })
                                )
                                .catch((err) => console.log(err));
                            });
                          }}
                          isLoading={isSubmittingFormik(formikRenderProps)}
                          disabled={!canSubmitFormik(formikRenderProps)}
                        >
                          Save
                        </Button>
                        {/* <Button
                              variant="contained"
                              className={classes.submitButton}
                              onClick={() => {
                                const submitFormFn = getSubmitFuncFormik(
                                  formikRenderProps
                                );
                                submitFormFn().then(() => {
                                  this.setState({
                                    redirectToAccountPage: true,
                                  });
                                  // this.props.history.push({
                                  //   pathname: "/manage_account"
                                  // });
                                });
                              }}
                              isLoading={isSubmittingFormik(formikRenderProps)}
                              disabled={!canSubmitFormik(formikRenderProps)}
                            >
                              Save & Exit
                            </Button> */}
                        <Button
                          className={classes.cancelButton}
                          component={Link}
                          to={"/manage_account"}
                        >
                          Exit To Account List
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </Paper>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSuccessMessage: Actions.showSuccessMessage,
      showErrorMessage: Actions.showErrorMessage,
    },
    dispatch
  );
}

function mapStateToProps({ accounts, auth }) {
  const { avatar } = accounts;
  const { success, message } = avatar;

  const { user } = auth;
  const { data } = user;
  const { email } = data;

  return {
    avatar: {
      success,
      message,
    },
    email,
    user,
  };
}
export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(EditAccountForm)
);
