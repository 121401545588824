import axios from "axios";
import { BASE_URL } from "fuse-configs/apiConfig";

axios.defaults.baseURL = BASE_URL;

// Add a response interceptor
// axios.interceptors.response.use(
//   function(response) {
//     // Do something with response data
//     const { data } = response;
//     if (data) {
//       return data;
//     } else {
//       return null;
//     }
//   },
//   function(error) {
//     // Do something with response error
//     return Promise.reject(error);
//   }
// );
