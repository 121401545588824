import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import { Link } from "react-router-dom";
import {
  Button,
  Typography,
  CircularProgress,
  Paper,
  Divider,
} from "@material-ui/core";
import { Formik } from "formik";
import { Form, Field } from "formik";
import {
  TextFieldFormik,
  SubmitButtonFormik,
  SelectFormik,
} from "@massiveinfinity/components/Form/FormikInputs";

import validationSchema from "./validationSchema";
import { createAccount } from "../api";
import * as Actions from "store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Breadcrumbs } from "@material-ui/lab";

const styles = (theme) => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3,
    // overflowX: "auto",
  },
  breadcrumbText: {
    color: "#363636 !important",
    textDecoration: "none",
    textTransform: "uppercase",
    fontWeight: "300",
  },
  titleText: {
    textTransform: "uppercase",
    textDecoration: "none",
  },
  linkText: {
    marginLeft: "1.5rem",
    marginTop: "0.5rem",
  },
  textLastEdited: {
    color: "#898989",
    fontStyle: "italic",
    marginTop: "0.5rem",
    marginLeft: "2rem",
    fontSize: "1.125rem",
  },
  textSupportLink: {
    color: "#01c0ff !important",
    fontWeight: "300",
    fontStyle: "italic",
    fontSize: "1.3rem",
  },
  createButton: {
    backgroundColor: "#4AA5D2",
    margin: theme.spacing.unit * 2,
    textTransform: "none",
    color: "white",
    borderRadius: 0,
  },
  cancelButton: {
    backgroundColor: "#5cccec",
    margin: theme.spacing.unit * 2,
    textTransform: "none",
    color: "white",
    borderRadius: 0,
  },
});

class NewAccountPage extends Component {
  state = {};
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className="flex flex-col">
          <div className="flex items-center p-6">
            <i class="las la-database text-24 mr-12"></i> ADD ACCOUNTS
          </div>
          <Paper square>
            <Breadcrumbs
              separator={<i class="las la-angle-right"></i>}
              arial-label="Breadcrumb"
              className="p-6"
            >
              <Link to="/dashboard">
                <Typography className="font-600">
                  <i class="las la-home text-20"></i>
                </Typography>
              </Link>
              <Link to={`/dashboard`}>
                <Typography className="font-600">Dashboard</Typography>
              </Link>
              <Link to={`/manage_account`}>
                <Typography className="font-600">Account List</Typography>
              </Link>
              <Link to={`/manage_account/new`}>
                <Typography className="font-600">Add Account</Typography>
              </Link>
            </Breadcrumbs>
          </Paper>

          <Paper square className="my-12">
            <div className="p-6">
              <Typography className="ml-12">EDIT ACCOUNT</Typography>
            </div>
            <Divider className="bg-grey" />
            <div className="flex mt-16 mx-12">
              <Typography className={classes.titleText} variant="h5">
                Account Information
              </Typography>
              {/* <Typography className={classes.textLastEdited}>
              Last Edited On: Data unavailable
            </Typography> */}
            </div>
            <div className="mt-16 mx-12">
              <Formik
                onSubmit={(values, { setSubmitting }) =>
                  createAccount({ ...values, region: "SG" })
                    .then((res) => {
                      this.props.showSuccessMessage(
                        "Account successfully added"
                      );
                      this.props.history.push({
                        pathname: "/manage_account",
                      });
                      setSubmitting(false);
                    })
                    .catch((err) => {
                      this.props.showErrorMessage(err.response.data.message);
                      setSubmitting(false);
                    })
                }
                validationSchema={validationSchema}
                initialValues={{
                  name: "",
                  account_id: "",
                  contact_person: "",
                  contact_number: "",
                  region: "SG",
                }}
              >
                {(formikRenderProps) => (
                  <Form>
                    <div className="flex flex-row">
                      <div className="w-full">
                        <div className="flex flex-row items-center my-8">
                          <div className="w-1/8 font-300 text-right pr-12">
                            Account Name:
                          </div>
                          <div className="w-full pr-8">
                            <Field
                              name="name"
                              component={TextFieldFormik}
                              fullWidth
                            />
                          </div>
                        </div>

                        <div className="flex flex-row items-center my-8">
                          <div className="w-1/8 font-300 text-right pr-12">
                            Account ID:
                          </div>
                          <div className="w-full pr-8">
                            <Field
                              name="account_id"
                              component={TextFieldFormik}
                              fullWidth
                            />
                          </div>
                        </div>

                        <div className="flex flex-row items-center my-8">
                          <div className="w-1/8 font-300 text-right pr-12">
                            Contact Person:
                          </div>
                          <div className="w-full pr-8">
                            <Field
                              name="contact_person"
                              component={TextFieldFormik}
                              fullWidth
                            />
                          </div>
                        </div>

                        <div className="flex flex-row items-center my-8">
                          <div className="w-1/8 font-300 text-right pr-12">
                            Contact Number:
                          </div>
                          <div className="w-full pr-8">
                            <Field
                              name="contact_number"
                              component={TextFieldFormik}
                              fullWidth
                            />
                          </div>
                        </div>

                        <div className="flex flex-row items-center my-8">
                          <div className="w-1/8 font-300 text-right pr-12">
                            Region:
                          </div>
                          <div className="w-full pr-8">
                            <Field
                              name="region"
                              component={SelectFormik}
                              options={[
                                {
                                  label: "SG",
                                  value: "SG",
                                },
                              ]}
                              fullWidth
                            />
                          </div>
                        </div>

                        <div className="flex justify-center">
                          <SubmitButtonFormik
                            formikRenderProps={formikRenderProps}
                            className={classes.createButton}
                          >
                            Create
                          </SubmitButtonFormik>
                          <Button
                            className={classes.cancelButton}
                            component={Link}
                            to="/manage_account"
                          >
                            Exit To Account List
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSuccessMessage: Actions.showSuccessMessage,
      showErrorMessage: Actions.showErrorMessage,
    },
    dispatch
  );
}

export default withStyles(styles, { withTheme: true })(
  connect(null, mapDispatchToProps)(NewAccountPage)
);
