export const sectorSelect = [
  {
    label: "Central",
    value: "central"
  },
  {
    label: "North",
    value: "north"
  },
  {
    label: "East",
    value: "east"
  },
  {
    label: "South",
    value: "south"
  },
  {
    label: "West",
    value: "west"
  }
];

export const timezoneSelect = [
  {
    label: "GMT +8",
    value: "UTC+8"
  }
];

export const statusSelect = [
  {
    label: "Active",
    value: "true"
  },
  {
    label: "Inactive",
    value: "false"
  }
];
