import React, { Component } from "react";
import AlertsAlarmsTab from "./tabs/AlertsAlarmsTab";

const styles = (theme) => ({});

class MaintenancePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: this.props.match.params.accountId,
      locationId: this.props.match.params.locationId,
    };
  }

  render() {
    return (
      <div>
        <AlertsAlarmsTab
          locationId={this.state.locationId}
          accountId={this.state.accountId}
        />
      </div>
    );
  }
}

export default MaintenancePage;
