import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { authRoles } from "auth";

export const LoginConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/login",
      component: LoginForm,
    },
    {
      path: "/forgot-password",
      component: ForgotPasswordForm,
    },
  ],
};
