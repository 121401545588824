
import axios from 'axios';
import { BASE_URL } from 'fuse-configs/apiConfig';

export const GET_TOTAL_ENERGY_GENERATED = "[PYLON_ENERGY] GET TOTAL SUCCESS";
export const LOADING_GET_TOTAL_ENERGY_GENERATED = "[PYLON_ENERGY] GET TOTAL LOADING";
export const ERROR_GET_TOTAL_ENERGY_GENERATED = "[PYLON_ENERGY] GET TOTAL ERROR";

export const GET_USER_PROFILE = "[PYLON] GET USER PROFILE";
export const ERROR_GET_USER_PROFILE = "[PYLON] GET USER PROFILE ERROR";

export function getTotalEnergyGenerated(accountId) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios.get(BASE_URL + `/account/${accountId}`)
                .then(response => {
                    if (response.data) {
                        const data = {
                            totalEnergyGeneratedToday: (response.data.accountenergy * 1).toFixed(2),
                            totalPowerGenerated: (response.data.accountpower * 1).toFixed(2),
                            totalEnergyGenerated: Math.random()
                        }
                        resolve(response.data.message);

                        return dispatch({
                            type: GET_TOTAL_ENERGY_GENERATED,
                            payload: data
                        });
                    }
                    else {
                        return dispatch({
                            type: LOADING_GET_TOTAL_ENERGY_GENERATED
                        });
                    }
                });
        });

    };
}

export function getUserProfile() {
    return (dispatch) => {
        setTimeout(() => {
            const data = {
                name: '',
                userRole: '',
                imgURL: '',
                _test: Math.random()
            }

            return dispatch({
                type: GET_USER_PROFILE,
                payload: data
            });
        }, 5000);
    };
}