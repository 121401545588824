import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button, Typography, InputAdornment } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import {
  TextFieldFormik,
  SubmitButtonFormik,
  SelectFormik
} from '@massiveinfinity/components/Form/FormikInputs';

import { addLocationToAccount } from '../api';
import locationValidationSchemaForNonMaster from './locationValidationSchemaForNonMaster';
import locationValidationSchema from './locationValidationSchema';
import { sectorSelect, timezoneSelect, statusSelect } from '../const';
import * as Actions from 'store/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto'
  },
  breadcrumbText: {
    color: '#363636 !important',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontWeight: '300'
  },
  titleText: {
    textTransform: 'uppercase',
    textDecoration: 'none'
  },
  linkText: {
    marginLeft: '1.5rem',
    marginTop: '0.5rem'
  },
  textLastEdited: {
    color: '#898989',
    fontStyle: 'italic',
    marginTop: '0.5rem',
    marginLeft: '2rem',
    fontSize: '1.125rem'
  },
  textSupportLink: {
    color: '#01c0ff !important',
    fontWeight: '300',
    fontStyle: 'italic',
    fontSize: '1.3rem'
  },
  submitButton: {
    backgroundColor: '#68f72a',
    margin: theme.spacing.unit * 2,
    textTransform: 'uppercase'
  },
  cancelButton: {
    backgroundColor: '#5d5e5c',
    margin: theme.spacing.unit * 2,
    textTransform: 'uppercase',
    color: '#cfd0d1'
  }
});

class NewLocationPage extends Component {
  state = {};
  render() {
    const { classes } = this.props;

    return (
      <div className="w-full pb-64 mb-32">
        <div className="flex flex-col">
          <div className="flex mt-40 ml-64 flex-wrap">
            <Typography
              to="/dashboard"
              component={Link}
              className={classes.breadcrumbText}
            >
              Dashboard
            </Typography>
            <p>&nbsp;>&nbsp;</p>
            <Typography
              to="/manage_account"
              component={Link}
              className={classes.breadcrumbText}
            >
              Manage Account
            </Typography>
            <p>&nbsp;>&nbsp;</p>
            <Typography className={classes.breadcrumbText}>
              Add Location
            </Typography>
          </div>
          <div className="flex mt-16 ml-64">
            <Typography className={classes.titleText} variant="h5">
              Location Information
            </Typography>
          </div>
          <div className="mt-16 ml-64">
            <Formik
              onSubmit={(values, { setSubmitting }) =>
                addLocationToAccount(this.props.match.params.acc_id, {
                  ...values,
                  status: false,
                  location_id:
                    values.pre_filled_location_id + values.location_id
                })
                  .then(res => {
                    this.props.showSuccessMessage(
                      'Location successfully added'
                    );
                    this.props.history.goBack();
                    setSubmitting(false);
                  })
                  .catch(err => {
                    this.props.showErrorMessage(err.response.data.message);
                    setSubmitting(false);
                  })
              }
              validationSchema={
                this.props.role == 99
                  ? locationValidationSchema
                  : locationValidationSchemaForNonMaster
              }
              initialValues={{
                timezone: 'UTC+8',
                pre_filled_location_id: this.props.match.params.acc_id + '_'
              }}
            >
              {formikRenderProps => (
                <Form>
                  <div className="flex flex-row">
                    <div className="w-full">
                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Location name:</div>
                        <div className="w-full pr-8">
                          <Field
                            name="name"
                            label="Location Name"
                            component={TextFieldFormik}
                            fullWidth
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Location ID:</div>
                        <div className="w-full pr-8 flex">
                          <Field
                            name="pre_filled_location_id"
                            // label="Location ID"
                            component={TextFieldFormik}
                            fullWidth
                            disabled
                          />
                          <Field
                            name="location_id"
                            label="Location ID"
                            component={TextFieldFormik}
                            fullWidth
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Sector:</div>
                        <div className="w-full pr-8">
                          <Field
                            name="sector"
                            label="Sector"
                            component={SelectFormik}
                            options={sectorSelect}
                            fullWidth
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Address:</div>
                        <div className="w-full pr-8">
                          <Field
                            name="address"
                            label="Address"
                            multiline
                            component={TextFieldFormik}
                            fullWidth
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Timezone:</div>
                        <div className="w-full pr-8">
                          <Field
                            name="timezone"
                            label="Time zone"
                            component={SelectFormik}
                            options={timezoneSelect}
                            isDisabled
                            fullWidth
                          />
                        </div>
                      </div>

                      {this.props.role == 99 ? (
                        <div className="flex flex-row items-center my-8">
                          <div className="w-2/5">Invested Amount:</div>
                          <div className="w-full pr-8">
                            <Field
                              name="invested_amt"
                              label="SGD $"
                              component={TextFieldFormik}
                              fullWidth
                            />
                          </div>
                        </div>
                      ) : (
                        <div />
                      )}

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Tariff:</div>
                        <div className="w-full pr-8">
                          <Field
                            name="tariff"
                            label="SGD $"
                            component={TextFieldFormik}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  /kWh
                                </InputAdornment>
                              )
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Panel Brand:</div>
                        <div className="w-full">
                          <Field
                            name="panel_brand"
                            label="Panel brand"
                            component={TextFieldFormik}
                            fullWidth
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Panel Count:</div>
                        <div className="w-full">
                          <Field
                            type="number"
                            name="panel_count"
                            label="Panel count"
                            component={TextFieldFormik}
                            fullWidth
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Panel Rating:</div>
                        <div className="w-full">
                          <Field
                            type="number"
                            name="panel_rating"
                            label="Panel rating"
                            component={TextFieldFormik}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  /W
                                </InputAdornment>
                              )
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">System Size:</div>
                        <div className="w-full">
                          <Field
                            type="number"
                            name="system_size"
                            label="System size"
                            component={TextFieldFormik}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  /kWp
                                </InputAdornment>
                              )
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Area of one panel:</div>
                        <div className="w-full">
                          <Field
                            type="number"
                            name="panel_area"
                            label="Area of one panel"
                            component={TextFieldFormik}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  m<sup>2</sup>
                                </InputAdornment>
                              )
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex flex-row items-center my-8">
                        <div className="w-2/5">Panel Efficiency:</div>
                        <div className="w-full">
                          <Field
                            type="number"
                            name="panel_efficiency"
                            label="Panel Efficiency"
                            component={TextFieldFormik}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              )
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex flex-row my-8">
                        <SubmitButtonFormik
                          formikRenderProps={formikRenderProps}
                        >
                          Submit
                        </SubmitButtonFormik>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSuccessMessage: Actions.showSuccessMessage,
      showErrorMessage: Actions.showErrorMessage
    },
    dispatch
  );
}

function mapStateToProps({ auth }) {
  const { role } = auth.user;

  return {
    role
  };
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(NewLocationPage)
);
