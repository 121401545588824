import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Moment from "react-moment";
import moment from "moment";

const styles = theme => ({});

class PylonClock extends Component {
  state = {
    currentTime: new Date().toLocaleString()
  };

  componentDidMount() {
    // Update time at every second.
    setInterval(() => {
      let now = new Date().toLocaleString();
      this.setState({
        currentTime: now
      });
    }, 1000);
  }

  render() {
    const { currentTime } = this.state;

    return (
      <Typography
        component="span"
        className="normal-case mr-16 text-11 italic font-200"
      >
        {//   <Moment format="D MMM YYYY, HH:mm:ss" parse="DD/MM/YYYY, HH:mm:ss">
        //     {currentTime}
        //   </Moment>
        moment().format("H:mm dddd, D MMM YYYY")}
      </Typography>
    );
  }
}

export default withStyles(styles, {
  withTheme: true
})(PylonClock);
