import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { resetIdCounter } from 'downshift';

const propTypes = {
    isLoading: PropTypes.bool,
    success: PropTypes.bool,
    data: PropTypes.number,
    dataStyle: PropTypes.string,
    dataUnits: PropTypes.string
}

const defaultProps = {};

const styles = theme => ({
    lightProgress: {
        color: theme.loader.light
    }
});

class PylonToolbarGeneratedEnergy extends Component {

    state = {
        width: window.innerWidth
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined)
        }
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };


    render() {
        const { classes, content, isLoading, success, data, dataStyle, dataUnits } = this.props;
        const { width } = this.state;
        const isMobile = width <= 500;

        if (isMobile) {
            return (
                <div>
                    {
                        isLoading
                            ?
                            (
                                <CircularProgress
                                    className={classNames(classes.lightProgress, "ml-12")}
                                    size={20}
                                    color="secondary"
                                />
                            )
                            :
                            success
                                ?
                                (
                                    <div className="flex items-center">
                                        <Typography variant="h5" className={classNames(dataStyle, "text-12 font-600 normal-case ml-4 mr-4")}>
                                            {data}
                                        </Typography>
                                        <Typography component="span" className="normal-case text-10 ml-4">
                                            {dataUnits}
                                        </Typography>
                                    </div>
                                )
                                :
                                (
                                    <Typography component="span" className="normal-case text-10  ml-4 italic">
                                        Error
                                    </Typography>
                                )
                    }
                </div>
            );
        } else {
            return (
                <div>
                    {
                        isLoading
                            ?
                            (
                                <CircularProgress
                                    className={classNames(classes.lightProgress, "ml-12")}
                                    size={20}
                                    color="secondary"
                                />
                            )
                            :
                            success
                                ?
                                (
                                    <div className="flex items-center">
                                        <Typography variant="h5" className={classNames(dataStyle, "normal-case ml-4 mr-4")}>
                                            {data}
                                        </Typography>
                                        <Typography component="span" className="normal-case text-16 ml-4">
                                            {dataUnits}
                                        </Typography>
                                    </div>
                                )
                                :
                                (
                                    <Typography component="span" className="normal-case text-16 ml-4 italic">
                                        Error
                                    </Typography>
                                )
                    }
                </div>
            );
        }
    }

}

PylonToolbarGeneratedEnergy.propTypes = propTypes;
PylonToolbarGeneratedEnergy.defaultProps = defaultProps;

export default withStyles(
    styles,
    {
        withTheme: true
    }
)(PylonToolbarGeneratedEnergy);