import PropTypes from "prop-types";

export default PropTypes.shape({
  dirty: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.string),
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleReset: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  isValidating: PropTypes.bool,
  resetForm: PropTypes.func,
  setErrors: PropTypes.func,
  setFieldError: PropTypes.func,
  setFieldTouched: PropTypes.func,
  submitForm: PropTypes.func,
  submitCount: PropTypes.number,
  setFieldValue: PropTypes.func,
  setStatus: PropTypes.func,
  setSubmitting: PropTypes.func,
  setTouched: PropTypes.func,
  setValues: PropTypes.func,
  status: PropTypes.any,
  touched: PropTypes.objectOf(PropTypes.bool),
  values: PropTypes.shape,
  validateForm: PropTypes.func,
  validateField: PropTypes.func,
});
