import React, { useState, useEffect } from "react";
import {
  AppBar,
  Button,
  Toolbar,
  withStyles,
  Typography,
  Grid,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { getRevenueLog } from "../tabs/utils";
import { isEmpty } from "lodash";
import ChartDisplay from "./ChartDisplay";

const styles = (theme) => ({
  inactiveButton: {
    backgroundColor: "#eaf1fe",
    color: "#83a8f5",
    margin: "0 5px",
    fontWeight: 300,
  },
  activeButton: {
    backgroundColor: "#00000014",
    margin: "0 5px",
    fontWeight: 300,
  },
  gridCard: {
    border: "1px solid #e8e8e8",
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: "1.2rem",
  },
});

const RevenueGrid = (props) => {
  const { classes, accountId, locationId } = props;

  const [chartTimeline, setChartTimeline] = useState("monthly");
  const [currentEV, setCurrentEV] = useState(0);
  const [currentCEC, setCurrentCEC] = useState(0);
  const [currentUDV, setCurrentUDV] = useState(0);
  const [totalRev, setTotalRev] = useState(0);
  const [chartSeries, setChartSeries] = useState(null);
  const [chartOptions, setChartOptions] = useState(null);
  const [showEV, setShowEV] = useState(true);
  const [showCEC, setShowCEC] = useState(true);
  const [showUDV, setShowUDV] = useState(true);

  const getChartData = () => {
    let categories = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    switch (chartTimeline) {
      case "yearly":
        categories = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];
        break;
      case "monthly":
        categories = Array.from(Array(moment().daysInMonth()), (_, i) => i + 1);
        break;
    }

    getRevenueLog(accountId, locationId, chartTimeline).onSnapshot(
      (collectionSnapshot) => {
        const cecRevenue = [],
          evRevenue = [],
          udvRevenue = [];
        if (!isEmpty(collectionSnapshot.docs)) {
          const latest = collectionSnapshot.docs
            .at(collectionSnapshot.docs.length - 1)
            .data();

          setCurrentEV(latest.ev_revenue ? latest.ev_revenue : 0);
          setCurrentCEC(latest.cec_revenue ? latest.cec_revenue : 0);
          setCurrentUDV(latest.udv_revenue ? latest.udv_revenue : 0);
          const total =
            latest.ev_revenue && latest.cec_revenue && latest.udv_revenue
              ? latest.ev_revenue + latest.cec_revenue + latest.udv_revenue
              : 0;
          setTotalRev(total);
        } else {
          setCurrentEV(0);
          setCurrentCEC(0);
          setCurrentUDV(0);
          setTotalRev(0);
        }
        collectionSnapshot.forEach(function(doc) {
          evRevenue.push(doc.data().ev_revenue ? doc.data().ev_revenue : 0);
          cecRevenue.push(doc.data().cec_revenue ? doc.data().cec_revenue : 0);
          udvRevenue.push(doc.data().udv_revenue ? doc.data().udv_revenue : 0);
        });
        const series = {
          labels: categories,
          datasets: [
            {
              label: "EV",
              data: showEV ? evRevenue : [],
              backgroundColor: "#e8634b",
            },
            {
              label: "CEC",
              data: showCEC ? cecRevenue : [],
              backgroundColor: "#405986",
            },
            {
              label: "UDV",
              data: showUDV ? udvRevenue : [],
              backgroundColor: "#18b39f",
            },
          ],
        };

        setChartSeries(series);
      }
    );
  };

  useEffect(() => {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "bottom",
        },
      },
    };
    setChartOptions(options);
  }, []);

  useEffect(() => {
    getChartData();
  }, [accountId, locationId, chartTimeline, showEV, showCEC, showUDV]);

  const toggleDisplay = (rev) => {
    switch (rev) {
      case "EV":
        setShowEV(!showEV);
        break;
      case "CEC":
        setShowCEC(!showCEC);
        break;
      case "UDV":
        setShowUDV(!showUDV);
        break;
    }
  };

  return (
    <div className="bg-white w-full">
      <AppBar position="static" color="default" className="bg-white">
        <Toolbar>
          <div className="flex items-center justify-between flex-grow">
            <Typography className="text-16 font-300">Revenue</Typography>
            <div>
              <Button
                classes={{
                  root:
                    chartTimeline === "monthly"
                      ? classes.activeButton
                      : classes.inactiveButton,
                }}
                onClick={() => {
                  setChartTimeline("monthly");
                }}
              >
                Monthly
              </Button>
              <Button
                classes={{
                  root:
                    chartTimeline === "yearly"
                      ? classes.activeButton
                      : classes.inactiveButton,
                }}
                onClick={() => {
                  setChartTimeline("yearly");
                }}
              >
                Yearly
              </Button>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <div>
        <div className="flex whitespace-pre-wrap">
          <Button
            variant="text"
            className={classes.gridCard}
          >{`$${totalRev}\nTenant`}</Button>
          <Button
            variant="text"
            className={classes.gridCard}
            onClick={() => toggleDisplay("EV")}
          >{`$${currentEV}\nEV`}</Button>
          <Button
            variant="text"
            className={classes.gridCard}
            onClick={() => toggleDisplay("CEC")}
          >{`$${currentCEC}\nCEC`}</Button>
          <Button
            variant="text"
            className={classes.gridCard}
            onClick={() => toggleDisplay("UDV")}
          >{`$${currentUDV}\nUDV`}</Button>
        </div>
        <div className="p-6">
          <ChartDisplay
            options={chartOptions}
            series={chartSeries}
            type="bar"
          />
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({});
}

function mapStateToProps({}) {
  return {};
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(RevenueGrid))
);
