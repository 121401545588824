import React, { useState, useEffect } from "react";
import moment from "moment";
import { Typography } from "@material-ui/core";

const TimeWrapper = () => {
  const [currentTime, setCurrentTime] = useState();

  useEffect(() => {
    setInterval(() => {
      setCurrentTime(moment().format("hh : mm : ss A"));
    }, 1000);
  }, []);

  return (
    <div className="flex items-center w-128 justify-center">
      <i class="las la-clock text-16"></i>
      <Typography className="ml-8 font-300">{currentTime}</Typography>
    </div>
  );
};

export default TimeWrapper;
