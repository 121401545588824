import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles/index";
import {
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Grid,
} from "@material-ui/core";
import FirebaseLoginTab from "./tabs/FirebaseLoginTab";
import logo from "./assets/pwm_logo.png";
import { PUBLIC_URL } from "config";
import { FuseAnimate } from "@fuse";
 

const styles = (theme) => ({
  root: {
    background: "url('/assets/pylon/ppt_bg.png') repeat-y",
    backgroundSize: "cover",
    backgroundColor: "#02BFFF",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    backgroundImage: `url(${PUBLIC_URL}/AuthBackground.jpg)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  subtitleHeaderStyle: {
    color: "#ffffff",
    letterSpacing: "4.8px",
    fontSize: "48px",
    fontWeight: 400,
    marginBottom: "20px",
  },
  intro: {
    color: "#ffffff",
  },
  card: {
    width: "100%",
    maxWidth: 400,
  },
});

class Login extends Component {
  render() {
    const { classes, children } = this.props;

    return (
      <body className={classes.body}>
        <div className="flex flex-row flex-auto flex-no-shrink items-center justify-around p-128">
          <div className="flex flex-1 items-center justify-center">
            <Grid item xs={12} md={11} lg={10}>
              <Typography variant="h1" className={classes.subtitleHeaderStyle}>
                <span className="font-100">THE</span> INTERNET OF ENERGY
              </Typography>
              <Typography variant="subtitle1" className="text-white font-300">
                Nexus is able to aggregate energy and power data in real-time
                from from multiple locations to better aid you in analysis and
                data visualisation.
              </Typography>
            </Grid>
          </div>
          <FuseAnimate animation="transition.expandIn">
            <div>
              {children}
              {/* <FirebaseLoginTab className="items-center" /> */}
            </div>
          </FuseAnimate>
        </div>
      </body>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(Login));
