import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { FusePageSimple } from "@fuse";
import AddLocationHeader from "./AddLocationComponent/AddLocationHeader";
import AddLocationContent from "./AddLocationComponent/AddLocationContent";

const styles = theme => ({
  layoutRoot: {
    backgroundColor: "#e8e8e8",
  },
  toolBarStyle: {
    borderBottom: "0px",
  },
  contentStyle: {
    marginBottom: "10rem",
  },
});

class AddLocation extends Component {
  render() {
    const { classes } = this.props;
    return (
      <FusePageSimple
        classes={{
          root: classes.layoutRoot,
          toolbar: classes.toolBarStyle,
          content: classes.contentStyle
        }}
        className="min-h-72 h-72 sm:h-136 sm:min-h-136"
        contentToolbar={<AddLocationHeader />}
        content={<AddLocationContent history={this.props.history} />}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(AddLocation));
