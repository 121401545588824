import { authRoles } from "auth";
import Location from "./Location";
import AssetPage from "./AssetPage";
import MaintenancePage from "./MaintenancePage";

export const LocationConfig = {
  settings: {
    layout: {
      config: {
        toolbar: {
          simple: false,
        },
        // navbar: {
        //   display: false
        // },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          disply: false,
        },
      },
    },
  },
  auth: authRoles.new,
  routes: [
    {
      path: "/location/:accountId/:locationId",
      exact: true,
      component: Location,
    },
    {
      path: "/location/:accountId/:locationId/assets",
      exact: true,
      component: AssetPage,
    },
    {
      path: "/location/:accountId/:locationId/maintenance",
      exact: true,
      component: MaintenancePage,
    },
  ],
};
