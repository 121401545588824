import React from "react";
import { Icon, ListItem, ListItemText } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";
import { NavLink, withRouter } from "react-router-dom";
import classNames from "classnames";
import FuseNavBadge from "./../FuseNavBadge";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "store/actions";
import { matches } from "lodash";

const propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
    exact: PropTypes.bool,
    auth: PropTypes.arrayOf(PropTypes.oneOf([PropTypes.string, PropTypes.number])),
    matches: PropTypes.arrayOf(PropTypes.string),
  }),
};

const defaultProps = {};

const styles = (theme) => ({
  item: {
    height: 40,
    width: "calc(100% - 16px)",
    borderRadius: "0 20px 20px 0",
    paddingRight: 12,
    "&.active": {
      // backgroundColor            : theme.palette.secondary.main,
      color: "#00BFFE" + "!important",
      pointerEvents: "none",
      transition: "border-radius .15s cubic-bezier(0.4,0.0,0.2,1)",
      "& .list-item-text-primary": {
        color: "inherit",
        fontWeight: 700,
      },
      "& .list-item-icon": {
        color: "inherit",
      },
    },
    "&.square, &.active.square": {
      width: "100%",
      borderRadius: "0",
    },
    "& .list-item-icon": {},
    "& .list-item-text": {},
    color: "inherit!important",
    textDecoration: "none!important",
  },
});

function FuseNavVerticalItem({
  item,
  classes,
  nestedLevel,
  userRole,
  navbarCloseMobile,
  active,
  selectedLocation,
}) {
  if (
    item.auth &&
    (!item.auth.includes(userRole) ||
      (userRole !== "guest" &&
        item.auth.length === 1 &&
        item.auth.includes("guest")))
  ) {
    return null;
  }

  let url = item.url;

  if (selectedLocation.data) {
    let locationId = selectedLocation.data.location_id;
    let accountId = selectedLocation.data.accountId;
    if (url.includes(":accountId") && url.includes(":locationId")) {
      url = url.replace(":accountId", accountId);
      url = url.replace(":locationId", locationId);
    }
  }

  let paddingValue = 40 + nestedLevel * 16;
  const listItemPadding =
    nestedLevel > 0 ? "pl-" + (paddingValue > 80 ? 80 : paddingValue) : "pl-24";

  return (
    <ListItem
      button
      component={NavLink}
      to={url}
      activeClassName="active"
      className={classNames(classes.item, listItemPadding, "list-item", active)}
      onClick={navbarCloseMobile}
      exact={item.exact}
      isActive={(match, location) => {
        if (match) {
          return true;
        }
        if (item.matches) {
          return item.matches.some((path) => {
            if (location.pathname.includes(path)) {
              return true;
            }

            return false;
          });
        }

        return false;
      }}
    >
      {nestedLevel > 0 && <p className="font-700 list-item-text-primary text-white text-14">-</p>}
      {item.icon && (
        <i
          class={`${item.icon} list-item-icon text-14 flex-no-shrink text-white`}
        ></i>
      )}
      <ListItemText
        className="list-item-text"
        primary={item.title}
        classes={{
          primary:
            nestedLevel > 0
              ? "text-14 list-item-text-primary text-white font-100"
              : "text-14 list-item-text-primary text-white font-300",
        }}
      />
      {item.badge && <FuseNavBadge badge={item.badge} />}
    </ListItem>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      navbarCloseMobile: Actions.navbarCloseMobile,
    },
    dispatch
  );
}

function mapStateToProps({ auth, fuse }) {
  return {
    userRole: auth.user.role,
  };
}

FuseNavVerticalItem.propTypes = propTypes;
FuseNavVerticalItem.defaultProps = defaultProps;

const NavVerticalItem = withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(FuseNavVerticalItem)
  )
);

export default NavVerticalItem;
