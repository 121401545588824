import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { withRouter, NavLink } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';

const styles = theme => ({
    root: {
    },
    breadcrumbButton: {
        padding: '0px',
        minHeight: '0px',
        '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline'
        }
    },
    breadcrumbText: {
        color: 'black !important',
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontWeight: '300'
    },
});

class SupportTab extends Component {

    isUserRoleNewAccount = () => {
        const { user } = this.props;

        if (!user) {
            return false;
        }

        if (user.role == undefined) {
            return false;
        }

        if (user.role != 0) {
            return false;
        }

        return true;
    };

    onClickGoBackButton = () => {
        this.props.history.goBack();
    };

    render() {
        const { classes } = this.props;

        return (
            <div className="flex flex-col">

                {
                    !this.isUserRoleNewAccount() && (
                        <div className="flex mt-8">
                            <p>&nbsp;&#60;&nbsp;</p>
                            <Button className={classes.breadcrumbButton} onClick={this.onClickGoBackButton}>
                                <Typography className={classes.breadcrumbText}>Go back</Typography>
                            </Button>
                        </div>
                    )
                }

                <div className="flex mt-4">
                    <Typography
                        className="uppercase flex text-24"
                    >
                        Account Support
                    </Typography>
                </div>

                <div className="flex mt-28 mb-28">
                    <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSdBTwl75xWsGEd7cjtVDGP3lJ6aGZ-k75kip7MFH7qFS88Rfg/viewform?embedded=true"
                        width="640"
                        height="980"
                        frameborder="0"
                        marginheight="0"
                        marginwidth="0"
                    >
                        Loading...
                    </iframe>
                </div>
            </div>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

function mapStateToProps({ auth }) {
    const user = auth.user;
    return {
        user: user
    };
}

export default withStyles(
    styles,
    {
        withTheme: true
    }
)(withRouter(connect(mapStateToProps, mapDispatchToProps)(SupportTab)));