import React from 'react';

const TriangleAlertIcon = ({ colorCode, height, width }) => (
  <svg
    // width="50"
    // height="30"
    height={height}
    width={width}
    viewBox="0 0 130.69 175.31"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={colorCode}
      d="M183.435,135.077L117.261,13.433c-10.632-17.899-36.549-17.923-47.195,0L3.895,135.077
				c-10.869,18.29,2.29,41.45,23.592,41.45h132.35C181.122,176.527,194.304,153.386,183.435,135.077z M93.664,154.575
				c-6.051,0-10.976-4.925-10.976-10.976s4.925-10.976,10.976-10.976c6.051,0,10.976,4.925,10.976,10.976
				S99.714,154.575,93.664,154.575z M104.64,110.67c0,6.051-4.925,10.976-10.976,10.976c-6.051,0-10.976-4.925-10.976-10.976V55.789
				c0-6.051,4.925-10.976,10.976-10.976c6.051,0,10.976,4.925,10.976,10.976V110.67z"
    />
  </svg>
);

TriangleAlertIcon.defaultProps = {
  height: 30,
  width: 50
};

export default TriangleAlertIcon;
