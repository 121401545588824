/*export const BASE_URL = (window.location.hostname==="dashboard.capitastar-uat.gamify.space" || window.location.hostname==="localhost") ? "https://gamify-api-uat.azurewebsites.net" : "https://gamify-api.azurewebsites.net";

export const SECRET_VALUE = (window.location.hostname==="dashboard.capitastar-uat.gamify.space" || window.location.hostname==="localhost") ? "kJUMQ9Ua8bli417XdlJFWon23bpMnb2zhdXfe51lj6waTDwxXiZ0qA==" : "cmskOaojbwJbawu/iww8fYsUNPzmlymimNHUn9Ml9wLusjd39IJwbDpqA==";*/

const devConfig = "https://powermon-api-staging.pyloncity.co";
const prodConfig = "https://powermon-api.pyloncity.co";

// export const BASE_URL = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;
export const BASE_URL =
  process.env.REACT_APP_KEY === "dev" ? devConfig : prodConfig;

export const SECRET_VALUE = "";

export const GOOGLE_BROWSER_KEY =
  process.env.REACT_APP_KEY === "dev"
    ? "AIzaSyBQsILQPIHKPNs6LQAI4lCOESoMGz06_SM"
    : "AIzaSyChWuzvsD3DzH6uFGlR6M4lxsGLx10bF_4";


