export const fuseNavigationConfig = [
  {
    id: "system",
    title: "Systems",
    type: "group",
    conditional: true,
    children: [
      {
        id: "overview",
        title: "Overview",
        type: "item",
        icon: "las la-bullseye",
        url: "/location/:accountId/:locationId",
        exact: true,
      },
      {
        id: "performance",
        title: "Performance",
        type: "collapse",
        icon: "las la-chart-area",
        open: false,
        fixed: false,
        children: [
          {
            id: "assets",
            title: "Assets",
            type: "item",
            url: "/location/:accountId/:locationId/assets",
            exact: true,
          },
          {
            id: "maintenance",
            title: "Maintenance",
            type: "item",
            url: "/location/:accountId/:locationId/maintenance",
            exact: true,
          },
        ],
      },
    ],
  },
  {
    id: "management",
    title: "Management",
    type: "group",
    children: [
      {
        id: "accounts",
        title: "Accounts",
        permission: true,
        type: "item",
        url: "/manage_account",
        icon: "las la-database",
        auth: [99, 30],
      },
      {
        id: "locations",
        title: "Locations",
        type: "item",
        url: "/manage_locations",
        icon: "las la-map-marker",
      },
      {
        id: "users",
        title: "Users",
        permission: true,
        type: "item",
        url: "/manage_users",
        icon: "las la-address-book",
        auth: [99, 30],
      },
    ],
  },
  // {
  //   id: "accounts",
  //   title: "ACCOUNTS",
  //   type: "collapse",
  //   icon: "group",
  //   open: true,
  //   fixed: true,
  //   children: [
  //     {
  //       id: "add-account",
  //       title: "Add account",
  //       type: "item",
  //       url: "/manage_account/new",
  //       exact: true,
  //     },
  //     {
  //       id: "manage-accounts",
  //       title: "Manage accounts",
  //       type: "item",
  //       url: "/manage_account",
  //       exact: true,
  //     },
  //   ],
  // },
  // {
  //   id: "pylons",
  //   title: "PYLONS",
  //   type: "collapse",
  //   icon: "group",
  //   open: true,
  //   fixed: true,
  //   children: [
  //     {
  //       id: "add-pylon",
  //       title: "Add a Location",
  //       type: "item",
  //       url: "/manage_locations/add_location",
  //       exact: true,
  //     },
  //     {
  //       id: "manage-pylons",
  //       title: "Manage Locations",
  //       type: "item",
  //       url: "/manage_locations",
  //       exact: true,
  //     },
  //   ],
  // },
  // {
  //   id: "users",
  //   title: "USERS & ACCESS",
  //   type: "collapse",
  //   icon: "group",
  //   open: true,
  //   fixed: true,
  //   children: [
  //     {
  //       id: "add-user",
  //       title: "Add user",
  //       type: "item",
  //       url: "/manage_users/new_user",
  //       exact: true,
  //     },
  //     {
  //       id: "manage-users",
  //       title: "Manage users",
  //       type: "item",
  //       url: "/manage_users",
  //       exact: true,
  //     },
  //   ],
  // },
];
