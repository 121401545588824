import React from "react";
import Login from "./Login";
import ForgotPassword from "./tabs/ForgotPasswordTab";

const LoginFormView = () => {
  return (
    <Login>
      <ForgotPassword />
    </Login>
  );
};

export default LoginFormView;

// tbd
