import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import * as locationActions from "../store/actions";
import ExpansionPanel from "./AlertsAndAlarmsExpansionPanel";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import LeftArrowIcon from "../../../assets/leftArrowIcon";
import RightArrowIcon from "../../../assets/rightArrowIcon";
import IconButton from "@material-ui/core/IconButton";
import _ from "lodash";
import {
  utilGetInitialPageAlarmAndAlerts,
  utilGetNextPageAlarmAndAlerts,
  utilGetPrevPageAlarmsAndAlerts,
  utilGetAlarmCollectionCount,
} from "./utils";
import ReactPaginate from "react-paginate";

const styles = (theme) => ({
  progress: {
    margin: theme.spacing.unit * 2,
  },
  title: {
    fontSize: "2.0rem",
    textTransform: "uppercase",
  },
  wrapper: {
    height: "100%",
    marginBottom: "100px",
  },
  pagination: {
    display: "flex",
    flexDirection: "row",
    listStyle: "none",
    alignItems: "center",
  },
  pageItem: {
    color: "#00BFFE",
    fontSize: "11px",
    display: "flex",
    alignItems: "center",
    height: 25,
    width: 25,
    justifyContent: "center",
    border: "1px solid #d7d7d7",
    borderRadius: 5,
    margin: "0px 5px",
  },
  active: {
    backgroundColor: "#00BFFE",
    color: "#fff",
  },
});

class AlertsAlarmsTab extends Component {
  state = {
    isLoading: false,
    data: [],
    lastField: "",
    firstField: "",
    page: 1,
    alarmCount: 0,
    currentItems: [],
    itemOffset: 0,
    pageCount: 0,
  };

  setIsLoadingTrue = () => {
    this.setState({
      isLoading: true,
    });
  };

  setIsLoadingFalse = () => {
    this.setState({
      isLoading: false,
    });
  };

  getInitialQueryAlarmAndAlerts = (accountId, locationId) => {
    utilGetInitialPageAlarmAndAlerts(accountId, locationId).onSnapshot(
      (documentSnapshot) => {
        let data = [];
        let lastField = documentSnapshot.docs[documentSnapshot.docs.length - 1];
        documentSnapshot.forEach((doc) => {
          data.push(doc.data());
        });

        const endOffset = this.state.itemOffset + 15;

        this.setState(
          {
            data,
            lastField,
            page: 1,
            currentItems: data.slice(this.state.itemOffset, endOffset),
            pageCount: Math.ceil(data.length / 15),
          },
          () => this.setIsLoadingFalse()
        );
      }
    );
  };

  componentDidUpdate(prevProps, prevState) {
    const { data, itemOffset } = this.state;
    if (prevState.itemOffset !== itemOffset) {
      const endOffset = itemOffset + 15;
      this.setState({
        currentitems: data.slice(itemOffset, endOffset),
        pageCount: Math.ceil(data.length / 15),
      });
    }
  }

  handlePageClick = (event) => {
    const { data } = this.state;
    const newOffset = (event.selected * 15) % data.length;
    this.setState({
      itemOffset: newOffset,
    });
  };

  getNextPageAlarmAndAlerts = (accountId, locationId, paramLastField) => {
    utilGetNextPageAlarmAndAlerts(
      accountId,
      locationId,
      paramLastField
    ).onSnapshot((documentSnapshot) => {
      let data = [];
      let lastField = documentSnapshot.docs[documentSnapshot.docs.length - 1];
      let firstField = documentSnapshot.docs[0];

      documentSnapshot.forEach((doc) => {
        data.push(doc.data());
      });

      this.setState(
        {
          data,
          lastField,
          firstField,
        },
        () => {
          this.setIsLoadingFalse();
        }
      );
    });
    this.setState({
      page: this.state.page + 1,
    });
  };

  getPrevPageAlarmAndAlerts = (accountId, locationId, paramFirstField) => {
    utilGetPrevPageAlarmsAndAlerts(
      accountId,
      locationId,
      paramFirstField
    ).onSnapshot((documentSnapshot) => {
      let data = [];
      let lastField = documentSnapshot.docs[documentSnapshot.docs.length - 1];
      let firstField = documentSnapshot.docs[0];

      documentSnapshot.forEach((doc) => {
        data.push(doc.data());
      });

      this.setState(
        {
          data,
          lastField,
          firstField,
        },
        () => {
          this.setIsLoadingFalse();
        }
      );
    });
    this.setState({
      page: this.state.page - 1,
    });
  };

  getAlarmCount = (accountId, locationId) => {
    utilGetAlarmCollectionCount(accountId, locationId).onSnapshot(
      (collectionSnapshot) => {
        let collectionCount = collectionSnapshot.docs.length;
        this.setState({
          alarmCount: collectionCount,
        });
        this.getInitialQueryAlarmAndAlerts(
          this.props.match.params.accountId,
          this.props.match.params.locationId
        );
      }
    );
  };

  componentDidMount() {
    const { match, getLocation } = this.props;
    const { accountId, locationId } = match.params;

    getLocation(accountId, locationId);
    this.setIsLoadingTrue();
    this.getInitialQueryAlarmAndAlerts(accountId, locationId);
    this.getAlarmCount(accountId, locationId);
  }

  render() {
    const {
      isLoading,
      data,
      lastField,
      firstField,
      alarmCount,
      page,
      pageCount,
      currentItems,
    } = this.state;

    const { match, classes } = this.props;
    const { accountId, locationId } = match.params;

    return (
      <>
        <AppBar position="static" color="default">
          <Toolbar>
            <div className="flex justify-between w-full">
              <Typography>Alerts, Alarms & Logs</Typography>
              <div className="flex">
                <Typography className="text-16 font-300">
                  Showing {currentItems.length} of {data.length} Results
                </Typography>
                <ReactPaginate
                  nextLabel={<i class="las la-arrow-right"></i>}
                  previousLabel={<i class="las la-arrow-left"></i>}
                  onPageChange={this.handlePageClick}
                  pageRangeDisplayed={3}
                  pageCount={pageCount}
                  containerClassName={classes.pagination}
                  activeClassName={classes.active}
                  pageClassName={classes.pageItem}
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </Toolbar>
        </AppBar>
        {/* <div className="flex w-full justify-between">

          <div>
            <Typography>
              Page : {page} /{alarmCount === 0 ? 1 : Math.ceil(alarmCount / 15)}
            </Typography>
            <IconButton
              onClick={() => {
                this.setIsLoadingTrue();
                this.getPrevPageAlarmAndAlerts(
                  accountId,
                  locationId,
                  firstField
                );
              }}
              disabled={page === 1}
            >
              <LeftArrowIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                this.setIsLoadingTrue();
                this.getNextPageAlarmAndAlerts(
                  accountId,
                  locationId,
                  lastField
                );
              }}
              disabled={alarmCount <= page * 15}
            >
              <RightArrowIcon />
            </IconButton>
          </div>
        </div> */}

        {isLoading ? (
          <div className="w-full flex justify-center">
            <CircularProgress className={classes.progress} />
          </div>
        ) : (
          <div className={classes.wrapper}>
            {data.map((alertData, index) => {
              return (
                <ExpansionPanel
                  data={alertData}
                  location={locationId}
                  page={page}
                  index={index}
                />
              );
            })}
          </div>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLocation: locationActions.getLocation,
    },
    dispatch
  );
}

function mapStateToProps({}) {
  return {};
}

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(AlertsAlarmsTab)
  )
);

// export default compose(
//   withStyles(styles),
//   withRouter(
//     connect(
//       mapStateToProps,
//       mapDispatchToProps
//     )
//   )
// )(AlertsAlarmsTab);
