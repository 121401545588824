import * as Yup from "yup";

export default Yup.object().shape({
  start_date: Yup.date(),
  end_date: Yup.date().when("start_date", (startDateValue, schema) => {
    return Yup.date().min(
      startDateValue,
      "End date must not be earlier than start date"
    );
  })
});
