import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import { Link } from "react-router-dom";
import {
  Button,
  Typography,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  InputAdornment,
  Divider,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Breadcrumbs } from "@material-ui/lab";
import moment, { fn } from "moment";
import _, { isEmpty } from "lodash";
import {
  getAllAccount,
  getAnAccount,
  getLocation,
  removeAccount,
} from "../api";
import { connect } from "react-redux";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  breadcrumbText: {
    color: "#363636 !important",
    textDecoration: "none",
    textTransform: "uppercase",
    fontWeight: "300",
  },
  titleText: {
    textTransform: "uppercase",
    textDecoration: "none",
  },
  linkText: {
    marginLeft: "1.5rem",
    marginTop: "0.5rem",
  },
  textLastEdited: {
    color: "#898989",
  },
  table: {
    minWidth: 700,
    overflowX: "auto",
    border: "1px solid #e8e8e8",
  },
  deleteButton: {
    backgroundColor: "#c32a3b",
    color: "white",
  },
  searchButton: {
    backgroundColor: "#3e75b4",
    borderRadius: 0,
    textTransform: "none",
  },
  input: {
    padding: "6px 0 7px",
  },
  outline: {
    borderRadius: 0,
  },
  resetButton: {
    backgroundColor: "#dfe5f1",
    borderRadius: 0,
    textTransform: "none",
  },
  addAccountButton: {
    textTransform: "none",
    color: "white",
  },
  tableRow: {
    "&:nth-of-type(even)": {
      backgroundColor: "#e8e8e8",
    },
  },
  editButton: {
    backgroundColor: "#22292f",
    color: "white",
  },
  actionCell: {
    backgroundColor: "white",
    border: "1px solid #e8e8e8",
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "0.6rem",
    backgroundColor: "#e8e8e8",
  },
});

class AccountTab extends Component {
  state = {
    accounts: [],
    isLoading: false,
    page: 0,
    rowsPerPage: 5,
    editedAccounts: [],
    searchBar: "",
    noOfItems: 0,
    deleteDialog: false,
    selectedAccount: null,
  };
  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.loadData();
  }

  loadData = () => {
    if (this.props.role == 99) {
      setTimeout(() => {
        getAllAccount().then((res) => {
          this.setState({
            accounts: res.data,
            isLoading: false,
            editedAccounts: _.slice(
              res.data,
              this.state.page,
              this.state.rowsPerPage
            ),
            noOfItems: res.data.length,
          });
        });
        // firestoreService.init();
        // firestoreService.getAllAccount().then((res) => {
        //   let accounts = [];
        //   res.map((account) => !isEmpty(account) && accounts.push(account));
        //   const arrPromise = accounts.map(async (account) => {
        //     const location = await getLocation(account.account_id);
        //     return { ...account, location: location.data };
        //   });

        //   Promise.all(arrPromise).then((arrLocation) => {
        //     this.setState({
        //       accounts: arrLocation,
        //       isLoading: false,
        //       editedAccounts: _.slice(
        //         arrLocation,
        //         this.state.page,
        //         this.state.rowsPerPage
        //       ),
        //       noOfItems: arrLocation.length,
        //     });
        //   });
        // });
      }, 1000);
    } else if (this.props.role == 30) {
      async function batchGetMultipleAccounts(accounts) {
        let allAssignedAccounts = [];
        for (let x in accounts) {
          const accounts = await getAnAccount(accounts[x]);
          const location = await getLocation(accounts.account_id);
          allAssignedAccounts.push({
            ...accounts,
            location: location.data,
          });
          return allAssignedAccounts;
        }
      }
      batchGetMultipleAccounts(this.props.assigned_accounts).then((val) => {
        this.setState({
          accounts: val,
          isLoading: false,
          editedAccounts: _.slice(val, this.state.page, this.state.rowsPerPage),
          noOfItems: val.length,
        });
      });
    }
  };

  handleChangePage = (event, page) => {
    this.setState({
      page,
    });
    page == 0
      ? this.setState({
          editedAccounts: _.slice(
            this.state.accounts,
            page,
            this.state.rowsPerPage
          ),
        })
      : this.setState({
          editedAccounts: _.slice(
            this.state.accounts,
            this.state.rowsPerPage * page,
            this.state.rowsPerPage * page + this.state.rowsPerPage
          ),
        });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: event.target.value,
      editedAccounts: _.slice(
        this.state.accounts,
        this.state.page,
        event.target.value
      ),
    });
  };

  searchKeyword = () => {
    const { searchBar } = this.state;
    this.setState({ isLoading: true });

    setTimeout(() => {
      const results = this.state.editedAccounts.filter(
        (item) =>
          item.account_id.toLowerCase().includes(searchBar.toLowerCase()) ||
          item.contact_person.toLowerCase().includes(searchBar.toLowerCase()) ||
          item.contact_number.includes(searchBar)
      );
      this.setState({
        isLoading: false,
        editedAccounts: results,
        noOfItems: results.length,
      });
    }, 3000);
  };

  resetSearch = () => {
    this.setState({ isLoading: true, searchBar: "" });
    setTimeout(() => {
      this.setState({
        isLoading: false,
        editedAccounts: _.slice(
          this.state.accounts,
          this.state.page,
          this.state.rowsPerPage
        ),
        noOfItems: this.state.accounts.length,
      });
    }, 3000);
  };

  openDialog = (account) => {
    this.setState({
      deleteDialog: true,
      selectedAccount: account,
    });
  };

  closeDialog = () => {
    this.setState({
      deleteDialog: false,
      selectedAccount: null,
    });
  };

  deleteAccount = () => {
    const { selectedAccount } = this.state;
    this.setState({ isLoading: true });
    removeAccount(selectedAccount.accountdata.account_id).then(() => {
      setTimeout(() => {
        this.loadData();
      }, 3000);
    });
  };

  render() {
    const { classes } = this.props;
    const { accounts, isLoading, editedAccounts, searchBar } = this.state;

    return (
      <div className="w-full">
        <div className="flex flex-col">
          <div className={classes.header}>
            <i class="las la-database text-24 mr-12"></i> MANAGE ACCOUNTS
          </div>
          <Paper square>
            <Breadcrumbs
              separator={<i class="las la-angle-right"></i>}
              arial-label="Breadcrumb"
              className="p-6"
            >
              <Link to="/dashboard">
                <Typography className="flex font-600">
                  <i class="las la-home text-20"></i>
                </Typography>
              </Link>
              <Link to={`/dashboard`}>
                <Typography className="font-600">Dashboard</Typography>
              </Link>
              <Link to={`/manage_account`}>
                <Typography className="font-600">Account List</Typography>
              </Link>
            </Breadcrumbs>
          </Paper>
          <Paper square className="mt-12">
            <div className="flex justify-between items-center p-6">
              <Typography className="ml-12">ACCOUNT TABLE</Typography>
              {this.props.role !== 30 && this.props.role !== 20 && (
                <div className="mr-12">
                  <Button
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to={"/manage_account/new"}
                    classes={{ containedSecondary: classes.addAccountButton }}
                  >
                    Add Account
                  </Button>
                </div>
              )}
            </div>
            <Divider className="bg-grey" />
            <div className="ml-12 m-12 w-1/2">
              <div className="flex justify-between items-center">
                <Typography>
                  <i class="las la-filter mr-6"></i>
                  <span className="text-grey">Filter</span>
                </Typography>
                <Tooltip title="Search by Account Name, Contact Person, Contact Number">
                  <IconButton>
                    <i class="las la-info-circle"></i>
                  </IconButton>
                </Tooltip>
              </div>
              <TextField
                label="Keyword"
                variant="outlined"
                margin="normal"
                value={searchBar}
                fullWidth
                onChange={(event) =>
                  this.setState({ searchBar: event.target.value })
                }
                InputProps={{
                  classes: {
                    input: classes.input,
                    notchedOutline: classes.outline,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <i class="las la-search"></i>
                    </InputAdornment>
                  ),
                }}
              />
              <div className="flex">
                <Button
                  className="mx-6 text-white"
                  classes={{ root: classes.searchButton }}
                  onClick={this.searchKeyword}
                >
                  Search
                </Button>
                <Button
                  className="mx-6"
                  classes={{ root: classes.resetButton }}
                  onClick={this.resetSearch}
                >
                  Reset
                </Button>
              </div>
            </div>
            {isLoading ? (
              <div className="flex justify-center my-64">
                <CircularProgress size={48} />
              </div>
            ) : (
              <div className="flex flex-col">
                {/* <Paper className={classes.root}> */}
                <div className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Locations</TableCell>
                        <TableCell>Users</TableCell>
                        <TableCell>Contact Person</TableCell>
                        <TableCell>Contact Number</TableCell>
                        <TableCell>Date Created</TableCell>
                        <TableCell className={classes.actionCell}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {editedAccounts.map((account) => (
                        <TableRow
                          key={account.accountdata.account_id}
                          hover={true}
                          classes={{ root: classes.tableRow }}
                        >
                          <TableCell>{account.accountdata.name}</TableCell>
                          <TableCell>{account.locations}</TableCell>
                          <TableCell>{account.account_users.length}</TableCell>
                          <TableCell>
                            {account.accountdata.contact_person}
                          </TableCell>
                          <TableCell>
                            {account.accountdata.contact_number}
                          </TableCell>
                          <TableCell>
                            {account.accountdata.created_on
                              ? moment
                                  .unix(account.accountdata.created_on._seconds)
                                  .format("DD MMMM YYYY @ HH:mm")
                              : "-"}
                          </TableCell>
                          <TableCell className={classes.actionCell}>
                            <div className="flex justify-center">
                              <Button
                                variant="contained"
                                classes={{ contained: classes.editButton }}
                                component={Link}
                                to={`/manage_account/${account.accountdata.account_id}`}
                              >
                                <i class="las la-pen text-20"></i>
                              </Button>
                              <Button
                                variant="contained"
                                classes={{ contained: classes.deleteButton }}
                                onClick={() => this.openDialog(account)}
                              >
                                <i class="las la-trash text-20"></i>
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={this.state.noOfItems}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
                {/* </Paper> */}
              </div>
            )}
          </Paper>
          {this.state.selectedAccount && (
            <Dialog open={this.state.deleteDialog} onClose={this.closeDialog}>
              <DialogTitle>Delete Account</DialogTitle>
              <DialogContent>
                <DialogContentText>{`Are you sure you want to delete ${this.state.selectedAccount.name}?`}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.deleteAccount()}>Confirm</Button>
                <Button onClick={this.closeDialog}>Cancel</Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  const { role, uid, data } = auth.user;
  const { assigned_accounts, assigned_locations } = data;

  return {
    role,
    uid,
    assigned_accounts,
    assigned_locations,
  };
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, null)(AccountTab)
);
