import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import BellAlert from "../../../assets/bellAlert";
import TriangleAlert from "../../../assets/triangleAlert";
import classNames from "classnames";

const constColorCode = {
  catastrophic: "#FF0000",
  major: "#f7ec13",
  minor: "#f79420",
  warning: "#f7ec13",
  default: "#666766",
  deactivated: "#5751BA",
};

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // maxWidth: '25%',
    // width: 25%
    flexWrap: "wrap",
    // paddingRight: '5px',
    padding: "5px",
    color: "#7D7D7D",
    fontWeight: 300,
  },
  heading2: {
    fontSize: theme.typography.pxToRem(12),
    // width: '35%',
    flexWrap: "wrap",
    paddingRight: "5px",
    color: "#7D7D7D",
    fontWeight: 300,
  },
  fullWidthText: {
    fontSize: theme.typography.pxToRem(15),
    width: "100%",
    flexWrap: "wrap",
    padding: "5px",
    color: "#7D7D7D",
    fontWeight: 300,
  },
  deactivedExpansionSummary: {
    opacity: 0.6,
    backgroundColor: "#B0B2B0",
  },
  deactivatedExpansionDetails: {
    opacity: 0.6,
    backgroundColor: "#B0B2B0",
  },
  expansionDetails: {
    padding: "0px 24px",
  },
  expansionSummary: {
    margin: "0px",
  },
});

class ControlledExpansionPanels extends Component {
  state = {
    expanded: null,
  };

  componentDidMount() {
    const { classes, data, location, page, index } = this.props;

    if (page === 1 && index <= 2) {
      this.setState({
        expanded: `panel${index}`,
      });
    }
  }

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  getIconDisplay = (status) => {
    let iconToDisplay = "";
    switch (status) {
      case "catatrosphic":
        iconToDisplay = (
          <div
            className="flex justify-center items-center"
            style={{ backgroundColor: constColorCode.major, width: "50px" }}
          >
            <i class="las la-skull-crossbones text-30 text-white"></i>
          </div>
        );
      case "major":
        iconToDisplay = (
          <div
            className="flex justify-center items-center"
            style={{ backgroundColor: constColorCode.major, width: "50px" }}
          >
            <i class="las la-exclamation text-30 text-white"></i>
          </div>
        );
        break;
      case "minor":
        iconToDisplay = (
          <div
            className="flex justify-center items-center"
            style={{ backgroundColor: constColorCode.major, width: "50px" }}
          >
            <i class="las la-exclamation-triangle text-30 text-white"></i>
          </div>
        );
        break;
      case "warning":
        iconToDisplay = (
          <BellAlert colorCode={constColorCode.warning}></BellAlert>
        );
        break;
      default:
        iconToDisplay = (
          <BellAlert colorCode={constColorCode.default}></BellAlert>
        );
        break;
    }
    return iconToDisplay;
  };

  getStatusColorText = (status) =>
    status ? (
      <span style={{ color: `${constColorCode.catastrophic}` }}> Active</span>
    ) : (
      <span style={{ color: `${constColorCode.deactivated}` }}>
        Deactivated
      </span>
    );

  getSeverityColorText = (severity) => {
    let severityColoredtext = "";
    switch (severity) {
      case "catastrophic":
      case "major":
        severityColoredtext = (
          <span style={{ color: constColorCode.catastrophic, fontWeight: 700 }}>
            {severity.toUpperCase()}
          </span>
        );
        break;
      case "minor":
        severityColoredtext = (
          <span style={{ color: constColorCode.minor }}>
            {severity.toUpperCase()}
          </span>
        );
        break;
      case "warning":
        severityColoredtext = (
          <span style={{ color: constColorCode.warning }}>
            {severity.toUpperCase()}
          </span>
        );
        break;
      default:
        severityColoredtext = <p></p>;
        break;
    }
    return severityColoredtext;
  };

  render() {
    const { classes, data, location, page, index } = this.props;
    const {
      alarm,
      asset,
      cause_id,
      severity,
      solution,
      state,
      status_code,
      timestamp,
    } = data;
    const { expanded } = this.state;

    return (
      <div className={classes.root}>
        <ExpansionPanel
          style={{
            boxShadow: "none",
            border: "2px solid #DBDBDD",
            margin: "10px",
          }}
          expanded={expanded === `panel${index}`}
          onChange={this.handleChange(`panel${index}`)}
        >
          <div className="flex flex-row">
            {this.getIconDisplay(severity)}
            <div className="w-full p-12">
              <ExpansionPanelSummary
                classes={{ content: classes.expansionSummary }}
                className={!state ? classes.deactivedExpansionSummary : null}
              >
                <div className="w-full">
                  <div
                    className={
                      window.screen.width <= 500
                        ? "w-full flex justify-between flex-wrap flex-col"
                        : "w-full flex justify-between flex-wrap"
                    }
                  >
                    <Typography className={classes.heading}>
                      Alarm: <span className="text-black">{alarm}</span>
                    </Typography>
                    <Typography className={classes.heading}>
                      <p className="float-left pr-4">Alarm Status: </p>
                      {this.getStatusColorText(state)}
                    </Typography>
                    <Typography className={classes.heading}>
                      <p className="float-left pr-4">Severity: </p>
                      {this.getSeverityColorText(severity)}
                    </Typography>
                    <Typography className={classes.heading2}>
                      Alert entry on{" "}
                      <strong>
                        {moment
                          .unix(timestamp.seconds)
                          .format("dddd, DD/MM/YYYY [at] HHmm") + " hrs"}
                      </strong>
                    </Typography>
                  </div>

                  <div
                    className={
                      window.screen.width <= 500
                        ? "w-full flex flex-col"
                        : "w-full flex"
                    }
                  >
                    <Typography className={classes.heading}>
                      Location: <span className="text-black">{location}</span>
                    </Typography>
                    <Typography className={classes.heading}>
                      Asset: <span className="text-black">{asset}</span>
                    </Typography>
                    <Typography className={classes.heading}></Typography>
                    <Typography className={classes.heading2}></Typography>
                  </div>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                classes={{ root: classes.expansionDetails }}
                className={!state ? classes.deactivatedExpansionDetails : null}
              >
                <div className="flex-col w-full text-justify">
                  <Typography className={classes.fullWidthText}>
                    Cause: <span className="text-black">{cause_id}</span>
                  </Typography>

                  <Typography className={classes.fullWidthText}>
                    Solution: <span className="text-black">{solution}</span>
                  </Typography>
                </div>
              </ExpansionPanelDetails>
            </div>
          </div>
        </ExpansionPanel>
      </div>
    );
  }
}

export default withStyles(styles)(ControlledExpansionPanels);
