import React from 'react';

const AssetIcon = ({ colorCode }) => (
  <svg
    width="50"
    height="30"
    viewBox="0 0 130.69 175.31"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={colorCode}
      d="M172.279,37.045l-0.152-0.396l-32.78,32.782l24.329,24.329l0.193-0.244C176.34,77.598,179.562,55.96,172.279,37.045
      L172.279,37.045z M172.279,37.045 M100.513,47.043c0-2.608,1.014-5.063,2.86-6.906l36.123-36.123L139.1,3.862
      C132.441,1.301,125.431,0,118.266,0c-15.459,0-29.989,6.019-40.919,16.949C60.962,33.334,56.48,57.18,63.891,77.653L7.165,134.376
      C2.544,138.997,0,145.143,0,151.678c0,6.536,2.544,12.678,7.165,17.299c4.623,4.621,10.765,7.165,17.302,7.168
      c6.536,0,12.678-2.547,17.299-7.168l56.698-56.698c6.263,2.277,12.94,3.466,19.8,3.466c0.003,0,0.003,0,0.003,0
      c12.692,0,24.736-4.026,34.819-11.639l0.253-0.19L103.374,53.95C101.527,52.106,100.513,49.654,100.513,47.043L100.513,47.043z
       M29.566,158.317c-3.242,3.243-8.497,3.243-11.742,0c-3.242-3.242-3.242-8.5,0.003-11.742c3.242-3.242,8.497-3.242,11.739,0
      C32.809,149.817,32.809,155.075,29.566,158.317L29.566,158.317z M81.911,65.373c-0.207-1.913,1.174-3.63,3.09-3.837l13.617-1.482
      c1.913-0.21,3.63,1.175,3.837,3.085c0.21,1.915-1.175,3.632-3.084,3.842L85.75,68.46C83.838,68.667,82.121,67.286,81.911,65.373
      L81.911,65.373z M122.158,82.844c0.209,1.913-1.175,3.63-3.088,3.837l-13.617,1.482c-1.913,0.207-3.632-1.175-3.84-3.087
      c-0.207-1.913,1.175-3.63,3.088-3.84l13.617-1.479C120.233,79.548,121.95,80.932,122.158,82.844L122.158,82.844z M112.307,72.992
      c0.207,1.913-1.174,3.632-3.087,3.84L95.6,78.31c-1.913,0.21-3.63-1.171-3.837-3.084c-0.207-1.912,1.175-3.632,3.087-3.84
      l13.617-1.481C110.381,69.698,112.101,71.08,112.307,72.992L112.307,72.992z M112.307,72.992"
    />
  </svg>
);

export default AssetIcon;
