import axios from "axios";

export const getAllLocations = () => axios.get(`/location`);

export const getALocation = (location_id) =>
  axios.get(`/location/${location_id}`);

export const getLocationsForAcc = (account_id) =>
  axios.get(`/account/${account_id}/location`);

export const deleteALocation = (location_id) =>
  axios.delete(`/location/${location_id}`);
