import React from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import {withRouter} from 'react-router-dom';
import classNames from 'classnames';
import { Typography, Icon } from '@material-ui/core';
import logo from './assets/pwm_logo.png';

const styles = theme => ({
    root      : {
        display                       : 'flex',
        alignItems                    : 'center',
        '& .logo-text, & .react-badge': {
            transition: theme.transitions.create('opacity', {
                duration: theme.transitions.duration.shortest,
                easing  : theme.transitions.easing.easeInOut
            })
        },
        width: '100%'
    },
    logo      : {
        width: '100%'
    },
    logoIcon  : {
        height    : 24,
        transition: theme.transitions.create(['width', 'height'], {
            duration: theme.transitions.duration.shortest,
            easing  : theme.transitions.easing.easeInOut
        })
    },
    reactBadge: {
        backgroundColor: 'rgba(0,0,0,0.6)',
        color          : '#61dafb'
    },
    logoText: {
        color: '#FFFFFF'
    }
});

function MainNavbarHeader({classes})
{
    return (
        <div className={classes.root}>
            <div className={classNames(classes.logo, "flex flex-row items-center")}>
                <img className={classNames(classes.logoIcon, "logo-icon")} src={logo} alt="logo"/>
                <Typography className={classNames(classes.logoText, "text-16 ml-8 uppercase")}>PYLON CITY</Typography>
                <Typography className={classNames(classes.logoText, "text-12 ml-4")}>:</Typography>
                <Typography className={classNames(classes.logoText, "text-12 ml-4 uppercase")}>POWERMON</Typography>

                <div className="flex-1"></div>

                <Icon className={classNames(classes.logoText, "mr-8")}>more_vert</Icon>
            </div>
        </div>
    );
};

export default withStyles(styles, {withTheme: true})(withRouter(MainNavbarHeader));
