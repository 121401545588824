import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { FusePageSimple } from "@fuse";
import EditUserHeader from "./EditUserComponent/EditUserHeader";
import EditUserContent from "./EditUserComponent/EditUserContent";
const styles = theme => ({
  layoutRoot: {
    backgroundColor: "#e8e8e8",
  },
  toolBarStyle: {
    borderBottom: "0px",
    backgroundColor: "#e8e8e8",
  },
  contentStyle: {
    marginBottom: "10rem",
  },
});

class AddUser extends Component {
  render() {
    const { classes } = this.props;
    return (
      <FusePageSimple
        classes={{
          root: classes.layoutRoot,
          toolbar: classes.toolBarStyle,
          content: classes.contentStyle
        }}
        className="min-h-72 h-72 sm:h-136 sm:min-h-136"
        contentToolbar={<EditUserHeader userid={this.props.match.params.userid} />}
        content={
          <EditUserContent
            userid={this.props.match.params.userid}
            history={this.props.history}
          />
        }
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(AddUser));
