import React from "react";
import { TextField } from "@material-ui/core";
import { fieldRenderProps } from "../../types";

const TextFieldFormik = ({
  field,
  form: { touched, errors },
  ...otherProps
}) => {
  const isFieldTouched = touched[field.name];
  const fieldError = errors[field.name];
  const isFieldErrored = Boolean(fieldError);

  return (
    <TextField
      error={isFieldTouched && isFieldErrored}
      helperText={isFieldTouched && fieldError}
      {...field}
      {...otherProps}
    />
  );
};

TextFieldFormik.propTypes = fieldRenderProps;

TextFieldFormik.defaultProps = {
  variant: "outlined"
};

export default TextFieldFormik;
