import PropTypes from "prop-types";
import formRenderProps from "./formRenderProps";

export default PropTypes.shape({
  field: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  }),
  form: formRenderProps,
});
