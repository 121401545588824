import firebaseService from "firebaseService";
import jwtService from "jwtService";

export const UPLOAD_LOCATION_AVATAR = "[PYLON_LOCATION_AVATAR] UPLOAD";
export const REMOVE_LOCATION_AVATAR = "[PYLON_LOCATION_AVATAR] REMOVE";
export const ERROR_LOCATION_AVATAR = "[PYLON_LOCATION_AVATAR] ERROR";
export const CLEAR_STATE_AVATAR = "[PYLON_AVATAR] CLEAR_STATE";

export function clearAvatarState() {
  return {
    type: CLEAR_STATE_AVATAR
  };
}

export function uploadLocationAvatar(locId, file) {
  return dispatch => {
    firebaseService
      .uploadLocationImage(locId, file)
      .then(result => {
        // Result is the image URL.
        return jwtService.updateLocationImage(locId, result);
      })
      .then(result => {
        // Result is the image URL.
        console.log("## result", result);
        return dispatch({
          type: UPLOAD_LOCATION_AVATAR,
          payload: result
        });
      })
      .catch(error => {
        console.log(error);

        if (typeof error == "string") {
          return dispatch({
            type: ERROR_LOCATION_AVATAR,
            payload: error
          });
        }

        return dispatch({
          type: ERROR_LOCATION_AVATAR,
          payload: error.message
        });
      });
  };
}

export function removeLocationAvatar(locId, imageURL) {
  return dispatch => {
    firebaseService
      .removeLocationImage(imageURL)
      .then(() => {
        return jwtService.updateLocationImage(locId, "");
      })
      .then(() => {
        return dispatch({
          type: REMOVE_LOCATION_AVATAR
        });
      })
      .catch(error => {
        console.log(error);

        if (typeof error == "string") {
          return dispatch({
            type: ERROR_LOCATION_AVATAR,
            payload: error
          });
        }

        return dispatch({
          type: ERROR_LOCATION_AVATAR,
          payload: error.message
        });
      });
  };
}
