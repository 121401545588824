import config from "./firebaseServiceConfig";
import firebase, { firestore } from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import jwtService from "jwtService";
import * as Global from "fuse-configs/globalConfig";

import axios from "axios";
import { BASE_URL } from "fuse-configs/apiConfig";

const url = require("url");
class firebaseService {
  init() {
    if (firebase.apps.length) {
      this.db = firebase.database();
      this.auth = firebase.auth();
      this.storage = firebase.storage();
      return;
    }
    firebase.initializeApp(config);
    this.db = firebase.database();
    this.auth = firebase.auth();
    this.storage = firebase.storage();
  }

  getUserData = (userId) => {
    if (!firebase.apps.length) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.db
        .ref(`users/${userId}`)
        .once("value")
        .then((snapshot) => {
          const user = snapshot.val();
          resolve(user);
        });
    });
  };

  updateUserData = (user) => {
    if (!firebase.apps.length) {
      return;
    }
    return this.db.ref(`users/${user.uid}`).set(user);
  };

  onAuthStateChanged = (callback) => {
    if (!this.auth) {
      return;
    }
    this.auth.onAuthStateChanged(callback);
  };

  signInWithEmailAndPassword = (email, password) => {
    return (
      this.useLocalPersistence()
        // Perform login with email and password to Firebase.
        .then(() => {
          return this.auth.signInWithEmailAndPassword(email, password);
        })
        // If success, obtain the JWT token from Firebase.
        .then((user) => {
          return this.getJWTToken(user);
        })
        // If success, obtain the user's role
        // It can be super admin, account admin, location admin, etc.
        .then((userObject) => {
          return this.getUserRole(userObject);
        })
        // Uncomment once API is ready to be consumed and used.
        .then(({ user, idToken }) => {
          return new Promise((resolve, reject) => {
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + idToken;
            axios
              .post(BASE_URL + "/user/auth", {
                idToken: idToken,
              })
              .then((response) => {
                resolve({
                  ...user,
                  uid: response.data.uid,
                  idToken: idToken,
                });
              })
              .catch((error) => {
                reject(error);
              });
          });
        })
        .then((userObject) => {
          return new Promise((resolve, reject) => {
            jwtService
              .getFormattedUserDataForMemoryFromServer(userObject.uid)
              .then((user) => {
                userObject.user = user;
                resolve(userObject);
              })
              .catch((error) => {
                reject(error);
              });
          });
        })
        .catch((err) => {
          console.log("login error: ", err);
        })
    );
  };

  authenticateWithEmailAndPassword = (email, password) => {
    return (
      this.useLocalPersistence()
        // Perform login with email and password to Firebase.
        .then(() => {
          return this.auth.signInWithEmailAndPassword(email, password);
        })
    );
  };

  useLocalPersistence = () => {
    return new Promise((resolve, reject) => {
      if (this.auth) {
        this.auth
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        this.init();
      }
    });
  };

  getJWTToken = (user) => {
    return new Promise((resolve, reject) => {
      this.auth.currentUser
        .getIdToken()
        .then((idToken) => {
          resolve({
            user: user,
            idToken: idToken,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getUserRole = (userObject) => {
    return new Promise((resolve, reject) => {
      this.auth.currentUser
        .getIdTokenResult()
        .then((idTokenResult) => {
          console.log(idTokenResult);

          resolve({
            ...userObject,
            idTokenResult: idTokenResult,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  uploadImage = (userId, file) => {
    return new Promise((resolve, reject) => {
      if (!this.storage) {
        reject("Firebase not initialised");
        return;
      }

      let now = new Date().getTime();
      let fileExtension = file.name.split(".").pop();

      const fileName = "user/" + userId + "/" + now + "." + fileExtension;

      let storageRef = this.storage.ref();
      let imageRef = storageRef.child(fileName);

      imageRef
        .put(file)
        .then((snapshot) => {
          return snapshot.ref.getDownloadURL();
        })
        .then((url) => {
          resolve(url);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  uploadAccountImage = (accId, file) => {
    return new Promise((resolve, reject) => {
      if (!this.storage) {
        reject("Firebase not initialised");
        return;
      }

      let now = new Date().getTime();
      let fileExtension = file.name.split(".").pop();

      const fileName = "account/" + accId + "/" + now + "." + fileExtension;

      let storageRef = this.storage.ref();
      let imageRef = storageRef.child(fileName);

      imageRef
        .put(file)
        .then((snapshot) => {
          return snapshot.ref.getDownloadURL();
        })
        .then((url) => {
          resolve(url);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  uploadLocationImage = (locId, file) => {
    return new Promise((resolve, reject) => {
      if (!this.storage) {
        reject("Firebase not initialised");
        return;
      }

      let now = new Date().getTime();
      let fileExtension = file.name.split(".").pop();

      const fileName = "location/" + locId + "/" + now + "." + fileExtension;

      let storageRef = this.storage.ref();
      let imageRef = storageRef.child(fileName);

      imageRef
        .put(file)
        .then((snapshot) => {
          return snapshot.ref.getDownloadURL();
        })
        .then((url) => {
          resolve(url);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  removeImage = (imageURL) => {
    return new Promise((resolve, reject) => {
      if (!this.storage) {
        reject("Firebase not initialised");
        return;
      }

      // https://firebasestorage.googleapis.com/v0/b/pylon-powermon.appspot.com/o/user%2F%20JGGxtKeH2dT3h2Nx9fdUpeuEOK63%2F1551858675720.png?alt=media&token=175b890d-97a9-47af-858e-7ead439de8df
      let urlObject = url.parse(imageURL);
      let pathname = decodeURIComponent(urlObject.pathname);
      let fileName = pathname
        .split("/")
        .slice(-3)
        .join("/");

      let storageRef = this.storage.ref();
      let imageRef = storageRef.child(fileName);

      imageRef
        .delete()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  removeAccountImage = (imageURL) => {
    return new Promise((resolve, reject) => {
      if (!this.storage) {
        reject("Firebase not initialised");
        return;
      }

      // https://firebasestorage.googleapis.com/v0/b/pylon-powermon.appspot.com/o/user%2F%20JGGxtKeH2dT3h2Nx9fdUpeuEOK63%2F1551858675720.png?alt=media&token=175b890d-97a9-47af-858e-7ead439de8df
      let urlObject = url.parse(imageURL);
      let pathname = decodeURIComponent(urlObject.pathname);
      let fileName = pathname
        .split("/")
        .slice(-3)
        .join("/");

      let storageRef = this.storage.ref();
      let imageRef = storageRef.child(fileName);

      imageRef
        .delete()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  removeLocationImage = (imageURL) => {
    return new Promise((resolve, reject) => {
      if (!this.storage) {
        reject("Firebase not initialised");
        return;
      }

      // https://firebasestorage.googleapis.com/v0/b/pylon-powermon.appspot.com/o/user%2F%20JGGxtKeH2dT3h2Nx9fdUpeuEOK63%2F1551858675720.png?alt=media&token=175b890d-97a9-47af-858e-7ead439de8df
      let urlObject = url.parse(imageURL);
      let pathname = decodeURIComponent(urlObject.pathname);
      let fileName = pathname
        .split("/")
        .slice(-3)
        .join("/");

      let storageRef = this.storage.ref();
      let imageRef = storageRef.child(fileName);

      imageRef
        .delete()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  signOut = () => {
    return new Promise((resolve, reject) => {
      if (!this.auth) {
        resolve(true);
        return;
      }

      this.auth
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

const instance = new firebaseService();

export default instance;
