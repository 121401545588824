import React from "react";
import DateWrapper from "./DateWrapper";
import TimeWrapper from "./TimeWrapper";
import LocationWrapper from "./LocationWrapper";
import USEPWrapper from "./USEPWrapper";
import { Typography } from "@material-ui/core";

const NavBarContent = () => {
  return (
    <div className="flex flex-1">
      <div className="flex">
        <DateWrapper />
        <Typography className="mx-20 text-center opacity-25 text-19 font-100">
          |
        </Typography>
        <TimeWrapper />
        <Typography className="mx-20 text-center opacity-25 text-19 font-100">
          |
        </Typography>
        <LocationWrapper />
        <Typography className="mx-20 text-center opacity-25 text-19 font-100">
          |
        </Typography>
        <USEPWrapper />
      </div>
    </div>
  );
};

export default NavBarContent;
