import { combineReducers } from "redux";
import fuse from "./fuse";
import auth from "auth/store/reducers";
import quickPanel from "main/quickPanel/store/reducers";
import pylon from "./pylon";
import accounts from "./accounts";
import locations from "./locations";
import users from "./users";

const createReducer = asyncReducers =>
  combineReducers({
    auth,
    fuse,
    quickPanel,
    ...asyncReducers,
    pylon,
    accounts,
    locations,
    users
  });

export default createReducer;
