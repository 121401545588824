import React, { useEffect, useState } from "react";
import {
  Typography,
  withStyles,
  Button,
  AppBar,
  Toolbar,
  Grid,
  Paper,
} from "@material-ui/core";
import ChartDisplay from "./ChartDisplay";
import {
  BsArrowLeftRight,
  BsArrowUpShort,
  BsArrowDownShort,
  BsCloudSun,
  BsThermometerHigh,
} from "react-icons/bs";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as locationActions from "../store/actions";
import * as Actions from "store/actions";
import ApexCharts from "apexcharts";
import { isEmpty } from "lodash";
import { getOverview } from "../store/actions";
import { getOverviewLog } from "../tabs/utils";
import moment from "moment";

// all values here to be changed

const styles = (theme) => ({
  active: {
    opacity: 1,
    fontSize: 16,
    fontWeight: 300,
  },
  inactive: {
    opacity: 0.4,
    fontSize: 16,
    fontWeight: 300,
  },
  inactiveButton: {
    backgroundColor: "#eaf1fe",
    color: "#83a8f5",
    margin: "0 5px",
    fontWeight: 300,
  },
  activeButton: {
    backgroundColor: "#00000014",
    margin: "0 5px",
    fontWeight: 300,
  },
  grow: {
    flexGrow: 1,
  },
  status: {
    backgroundColor: "#00000014",
    display: "flex",
    alignItems: "center",
    padding: "0px 5px",
    borderRadius: 5,
  },
  up: {
    color: "#5ec9bb",
    fontSize: 12,
  },
  down: {
    color: "#f07464",
    fontSize: 12,
  },
  text: {
    color: "#707070",
    fontSize: 12,
    marginLeft: 10,
  },
  whiteBg: {
    backgroundColor: "#ffffff",
  },
  chart: {
    width: "100%",
    margin: 10,
    height: "100%",
  },
});

const OverviewCard = ({
  classes,
  header,
  type,
  value,
  chartTimeline,
  currentOverview,
  previousData,
}) => {
  let unit = type === "power" ? "kw" : "kwh";
  let color = "#FFFFFF";
  let icon = "las la-bolt";
  let percentage = 0;
  let status = "up";
  let prevTime = "month";

  const numberWithCommas = (x) => {
    if (x) {
      return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return 0;
  };

  switch (header) {
    case "Generation":
      icon = "las la-bolt";
      break;
    case "Import":
      icon = "las la-plug";
      break;
    case "Export":
      icon = "las la-file-export";
      break;
    case "Consumption":
      icon = "lab la-gg";
      break;
  }

  switch (chartTimeline) {
    case "yearly":
      prevTime = "month";
      break;
    case "monthly":
      prevTime = "day";
      break;
    case "daily":
      prevTime = "hour";
      break;
    case "half_hourly":
      prevTime = "½ hour";
      break;
  }

  if (type === "power") {
    switch (header) {
      case "Generation":
        color = "#5ec9bb";
        break;
      case "Import":
        color = "#f07464";
        break;
      case "Consumption":
        color = "#e5a74a";
        break;
      case "Export":
        color = "#e7c5a7";
        break;
    }
  } else {
    switch (header) {
      case "Generation":
        color = "#96fb00";
        percentage =
          ((value - previousData.energy_generated) /
            previousData.energy_generated) *
          100;
        break;
      case "Import":
        color = "#e38f00";
        percentage =
          ((value - previousData.grid_energy_consumed) /
            previousData.grid_energy_consumed) *
          100;
        break;
      case "Consumption":
        color = "#4ca9df";
        percentage =
          ((value - previousData.energy_consumed) /
            previousData.energy_consumed) *
          100;
        break;
      case "Export":
        color = "#bda5ea";
        percentage =
          ((value - previousData.energy_exported) /
            previousData.energy_exported) *
          100;
        break;
    }
  }

  status = percentage >= 0 ? "up" : "down";

  return (
    <Paper elevation={1}>
      <div style={{ borderLeft: `10px solid ${color}`, padding: 10 }}>
        <div className="flex items-center justify-between">
          <Typography>{header}</Typography>
          <i class={`${icon}`} style={{ color: color, fontSize: 24 }}></i>
        </div>
        <div className="flex items-center w-4/5 justify-between">
          <Typography className="text-36 font-300">
            {numberWithCommas(value)}
          </Typography>
          <Typography>{unit}</Typography>
        </div>
        {currentOverview === "energy" && (
          <div className="flex items-center">
            <div className={classes.status}>
              {status === "up" ? (
                <BsArrowUpShort size={12} className={classes.up} />
              ) : (
                <BsArrowDownShort size={12} className={classes.down} />
              )}
              <Typography
                className={status === "up" ? classes.up : classes.down}
              >
                {percentage !== Infinity ? percentage.toFixed(2) : 0.0} %
              </Typography>
            </div>
            <Typography className={classes.text}>
              vs. previous {prevTime}
            </Typography>
          </div>
        )}
      </div>
    </Paper>
  );
};

const OverviewGrid = (props) => {
  const { classes, accountId, locationId } = props;
  const [currentOverview, setCurrentOverview] = useState("power");
  const [chartTimeline, setChartTimeline] = useState("yearly");
  const [totalPowerGenerated, setTotalPowerGenerated] = useState(0);
  const [totalPowerImported, setTotalPowerImported] = useState(0);
  const [totalPowerExported, setTotalPowerExported] = useState(0);
  const [totalPowerConsumed, setTotalPowerConsumed] = useState(0);
  const [totalEnergyGenerated, setTotalEnergyGenerated] = useState(0);
  const [totalEnergyImported, setTotalEnergyImported] = useState(0);
  const [totalEnergyExported, setTotalEnergyExported] = useState(0);
  const [totalEnergyConsumed, setTotalEnergyConsumed] = useState(0);
  const [powerChartOptions, setPowerChartOptions] = useState(null);
  const [energyChartOptions, setEnergyChartOptions] = useState(null);
  const [powerChartSeries, setPowerChartSeries] = useState(null);
  const [energyChartSeries, setEnergyChartSeries] = useState(null);
  const [previousData, setPreviousData] = useState({
    energy_generated: 0,
    energy_consumed: 0,
    energy_exported: 0,
    grid_energy_consumed: 0,
  });

  const changeOverview = () => {
    if (currentOverview === "power") {
      setCurrentOverview("energy");
    } else {
      setCurrentOverview("power");
    }
  };

  useEffect(() => {
    const energyOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "bottom",
        },
      },
      interaction: {
        mode: "index",
        intersect: false,
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    };

    const powerOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "bottom",
        },
      },
      scales: {
        x: {
          display: false,
        },
      },
    };
    setPowerChartOptions(powerOptions);
    setEnergyChartOptions(energyOptions);
  }, [chartTimeline]);

  const getChartData = () => {
    let duration = moment.duration(moment().diff(moment().startOf("day")));
    let hours = Math.round(duration.asHours());
    let halfHour = [];
    let counter = -1;
    for (let i = 0; i < hours * 2; i++) {
      if (i % 2) {
        halfHour.push(moment(counter, "HH").format("HH:30"));
      } else {
        counter += 1;
        halfHour.push(moment(counter, "HH").format("HH:mm"));
      }
    }

    let categories = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    switch (chartTimeline) {
      case "yearly":
        categories = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];
        break;
      case "monthly":
        categories = Array.from(Array(moment().daysInMonth()), (_, i) => i + 1);
        break;
      case "daily":
        categories = Array.from(Array(hours), (_, i) =>
          moment(i, "HH").format("HH:mm")
        );
        break;
      case "half_hourly":
        categories = halfHour;
        break;
    }

    getOverviewLog(accountId, locationId, chartTimeline).onSnapshot(
      (collectionSnapshot) => {
        const generationArray = [],
          consumptionArray = [],
          exportArray = [],
          importArray = [];
        let counter = 0;
        if (chartTimeline === "daily") {
          const sumObjectsByKey = (...objs) => {
            const res = objs.reduce((a, b) => {
              for (let k in b) {
                if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k];
              }
              return a;
            }, {});
            return res;
          };

          if (collectionSnapshot.docs.length > 1) {
            const latest = sumObjectsByKey(
              collectionSnapshot.docs
                .at(collectionSnapshot.docs.length - 1)
                .data(),
              collectionSnapshot.docs
                .at(collectionSnapshot.docs.length - 2)
                .data()
            );
            setTotalEnergyGenerated(latest.energy_generated.toFixed(2));
            setTotalEnergyConsumed(latest.energy_consumed.toFixed(2));
            setTotalEnergyExported(latest.energy_exported.toFixed(2));
            setTotalEnergyImported(latest.grid_energy_consumed.toFixed(2));
          } else {
            setTotalEnergyGenerated(0.0);
            setTotalEnergyConsumed(0.0);
            setTotalEnergyExported(0.0);
            setTotalEnergyImported(0.0);
          }
          if (collectionSnapshot.docs.length > 3) {
            const prev = sumObjectsByKey(
              collectionSnapshot.docs
                .at(collectionSnapshot.docs.length - 3)
                .data(),
              collectionSnapshot.docs
                .at(collectionSnapshot.docs.length - 4)
                .data()
            );
            setPreviousData(prev);
          } else {
            setPreviousData({
              energy_generated: 0,
              energy_consumed: 0,
              energy_exported: 0,
              grid_energy_consumed: 0,
            });
          }
        } else {
          if (!isEmpty(collectionSnapshot.docs)) {
            const latest = collectionSnapshot.docs
              .at(collectionSnapshot.docs.length - 1)
              .data();
            if (collectionSnapshot.docs.length > 1) {
              const prev = collectionSnapshot.docs
                .at(collectionSnapshot.docs.length - 2)
                .data();
              setPreviousData(prev);
            } else {
              setPreviousData({
                energy_generated: 0,
                energy_consumed: 0,
                energy_exported: 0,
                grid_energy_consumed: 0,
              });
            }

            setTotalEnergyGenerated(latest.energy_generated.toFixed(2));
            setTotalEnergyConsumed(latest.energy_consumed.toFixed(2));
            setTotalEnergyExported(latest.energy_exported.toFixed(2));
            setTotalEnergyImported(latest.grid_energy_consumed.toFixed(2));
          } else {
            setTotalEnergyGenerated(0);
            setTotalEnergyConsumed(0);
            setTotalEnergyExported(0);
            setTotalEnergyImported(0);
          }
        }
        collectionSnapshot.forEach(function(doc) {
          if (chartTimeline === "daily") {
            if (counter + 1 < collectionSnapshot.docs.length) {
              if (counter % 2) {
                const generation =
                  doc.data().energy_generated.toFixed(2) +
                  collectionSnapshot.docs
                    .at(counter + 1)
                    .data()
                    .energy_generated.toFixed(2);
                const consumption =
                  doc.data().energy_consumed.toFixed(2) +
                  collectionSnapshot.docs
                    .at(counter + 1)
                    .data()
                    .energy_consumed.toFixed(2);
                const expt =
                  doc.data().energy_exported.toFixed(2) +
                  collectionSnapshot.docs
                    .at(counter + 1)
                    .data()
                    .energy_exported.toFixed(2);
                const impt =
                  doc.data().grid_energy_consumed.toFixed(2) +
                  collectionSnapshot.docs
                    .at(counter + 1)
                    .data()
                    .grid_energy_consumed.toFixed(2);
                generationArray.push(generation);
                consumptionArray.push(consumption);
                exportArray.push(expt);
                importArray.push(impt);
                counter++;
              } else {
                counter++;
              }
            }
          } else {
            generationArray.push(doc.data().energy_generated.toFixed(2));
            consumptionArray.push(doc.data().energy_consumed.toFixed(2));
            exportArray.push(doc.data().energy_exported.toFixed(2));
            importArray.push(doc.data().grid_energy_consumed.toFixed(2));
          }
        });
        const series = {
          labels: categories,
          datasets: [
            {
              label: "Import",
              data: importArray,
              backgroundColor: "#e38f00",
              stack: "Stack 0",
            },
            {
              label: "Generation",
              data: generationArray,
              backgroundColor: "#96fb00",
              stack: "Stack 0",
            },
            {
              label: "Export",
              data: exportArray,
              backgroundColor: "#bda5ea",
              stack: "Stack 1",
            },
            {
              label: "Consumption",
              data: consumptionArray,
              backgroundColor: "#4ca9df",
              stack: "Stack 1",
            },
          ],
        };

        setEnergyChartSeries(series);
      }
    );
    getOverviewLog(accountId, locationId, "minute").onSnapshot(
      (collectionSnapshot) => {
        const generationArray = [],
          consumptionArray = [],
          exportArray = [],
          importArray = [];
        if (!isEmpty(collectionSnapshot.docs)) {
          const latest = collectionSnapshot.docs
            .at(collectionSnapshot.docs.length - 1)
            .data();
          setTotalPowerGenerated(latest.total_power_generated.toFixed(2));
          setTotalPowerConsumed(latest.total_power_consumed.toFixed(2));
          setTotalPowerExported(latest.total_power_exported.toFixed(2));
          setTotalPowerImported(latest.total_power_imported.toFixed(2));
        } else {
          setTotalPowerGenerated(0);
          setTotalPowerConsumed(0);
          setTotalPowerExported(0);
          setTotalPowerImported(0);
        }
        collectionSnapshot.forEach(function(doc) {
          generationArray.push(doc.data().total_power_generated.toFixed(2));
          consumptionArray.push(doc.data().total_power_consumed.toFixed(2));
          exportArray.push(doc.data().total_power_exported.toFixed(2));
          importArray.push(doc.data().total_power_imported.toFixed(2));
        });
        const series = {
          labels: generationArray.map((item, index) => index),

          datasets: [
            {
              label: "Generation",
              data: generationArray,
              borderColor: "#5ec9bb",
            },
            {
              label: "Import",
              data: importArray,
              borderColor: "#f07464",
            },
            {
              label: "Consumption",
              data: consumptionArray,
              borderColor: "#e5a74a",
            },
            {
              label: "Export",
              data: exportArray,
              borderColor: "#e7c5a7",
            },
          ],
        };
        setPowerChartSeries(series);
      }
    );
  };

  useEffect(() => {
    getChartData();
  }, [chartTimeline, accountId, locationId]);

  return (
    <div className="bg-white w-full">
      <AppBar
        position="static"
        color="default"
        classes={{ colorDefault: classes.whiteBg }}
      >
        <Toolbar>
          <div className="flex items-center flex-grow">
            <Button onClick={changeOverview}>
              <Typography
                className={
                  currentOverview === "power"
                    ? classes.active
                    : classes.inactive
                }
              >
                POWER
              </Typography>
              <i class="las la-exchange-alt mx-12 opacity-50"></i>
              <Typography
                className={
                  currentOverview === "energy"
                    ? classes.active
                    : classes.inactive
                }
              >
                ENERGY
              </Typography>
            </Button>
            <Typography className={classNames([classes.inactive, "ml-20"])}>
              tap to swap
            </Typography>
          </div>
          {currentOverview === "energy" && (
            <div>
              <Button
                classes={{
                  root:
                    chartTimeline === "half_hourly"
                      ? classes.activeButton
                      : classes.inactiveButton,
                }}
                onClick={() => {
                  setChartTimeline("half_hourly");
                }}
              >
                ½ HR
              </Button>
              <Button
                classes={{
                  root:
                    chartTimeline === "daily"
                      ? classes.activeButton
                      : classes.inactiveButton,
                }}
                onClick={() => {
                  setChartTimeline("daily");
                }}
              >
                Daily
              </Button>
              <Button
                classes={{
                  root:
                    chartTimeline === "monthly"
                      ? classes.activeButton
                      : classes.inactiveButton,
                }}
                onClick={() => {
                  setChartTimeline("monthly");
                }}
              >
                Monthly
              </Button>
              <Button
                classes={{
                  root:
                    chartTimeline === "yearly"
                      ? classes.activeButton
                      : classes.inactiveButton,
                }}
                onClick={() => {
                  setChartTimeline("yearly");
                }}
              >
                Yearly
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <div className="p-20">
        <Grid container>
          <Grid container xs={5} spacing={16}>
            <Grid item xs={6}>
              <OverviewCard
                classes={classes}
                header="Generation"
                type={currentOverview}
                value={
                  currentOverview === "power"
                    ? totalPowerGenerated
                    : totalEnergyGenerated
                }
                chartTimeline={chartTimeline}
                currentOverview={currentOverview}
                previousData={previousData}
              />
            </Grid>
            <Grid item xs={6}>
              <OverviewCard
                classes={classes}
                header="Import"
                type={currentOverview}
                value={
                  currentOverview === "power"
                    ? totalPowerImported
                    : totalEnergyImported
                }
                chartTimeline={chartTimeline}
                currentOverview={currentOverview}
                previousData={previousData}
              />
            </Grid>
            <Grid item xs={6}>
              <OverviewCard
                classes={classes}
                header="Consumption"
                type={currentOverview}
                value={
                  currentOverview === "power"
                    ? totalPowerConsumed
                    : totalEnergyConsumed
                }
                chartTimeline={chartTimeline}
                currentOverview={currentOverview}
                previousData={previousData}
              />
            </Grid>
            <Grid item xs={6}>
              <OverviewCard
                classes={classes}
                header="Export"
                type={currentOverview}
                value={
                  currentOverview === "power"
                    ? totalPowerExported
                    : totalEnergyExported
                }
                chartTimeline={chartTimeline}
                currentOverview={currentOverview}
                previousData={previousData}
              />
            </Grid>
          </Grid>
          <Grid container xs={7}>
            {currentOverview === "power" ? (
              <ChartDisplay
                options={powerChartOptions}
                series={powerChartSeries}
                type="line"
              />
            ) : (
              <ChartDisplay
                options={energyChartOptions}
                series={energyChartSeries}
                type="bar"
              />
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getOverview: locationActions.getOverview,
      getOverviewChartByHour: locationActions.getOverviewChartByHour,
      getOverviewChartByDay: locationActions.getOverviewChartByDay,
      getOverviewChartByMonth: locationActions.getOverviewChartByMonth,
      getOverviewChartByYear: locationActions.getOverviewChartByYear,
      showSuccessMessage: Actions.showSuccessMessage,
      showErrorMessage: Actions.showErrorMessage,
    },
    dispatch
  );
}

function mapStateToProps({ loc, auth }) {
  return {
    overview: loc.location.overview,
    chart: loc.location.chart,
    user: auth.user,
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(OverviewGrid))
);
