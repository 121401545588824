import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import { Link } from "react-router-dom";
import {
  Avatar,
  Button,
  Typography,
  CircularProgress,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Divider,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import moment from "moment";
import _, { isEmpty } from "lodash";

import {
  deleteALocation,
  getAllLocations,
  getALocation,
  getLocationsForAcc,
} from "./api";
import { connect } from "react-redux";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  breadcrumbText: {
    color: "#363636 !important",
    textDecoration: "none",
    textTransform: "uppercase",
    fontWeight: "300",
  },
  titleText: {
    textTransform: "uppercase",
    textDecoration: "none",
  },
  linkText: {
    marginLeft: "1.5rem",
    marginTop: "0.5rem",
  },
  textLastEdited: {
    color: "#898989",
  },
  table: {
    minWidth: 350,
    overflowX: "auto",
    border: "1px solid #e8e8e8",
  },
  avatar: {
    margin: 10,
  },
  addLocationButton: {
    textTransform: "none",
    color: "white",
    borderRadius: 0,
  },
  actionCell: {
    backgroundColor: "white",
    border: "1px solid #e8e8e8",
  },
  deleteButton: {
    backgroundColor: "#c32a3b",
    color: "white",
  },
  editButton: {
    backgroundColor: "#22292f",
    color: "white",
  },
  searchButton: {
    backgroundColor: "#3e75b4",
    borderRadius: 0,
    textTransform: "none",
  },
  resetButton: {
    backgroundColor: "#dfe5f1",
    borderRadius: 0,
    textTransform: "none",
  },
  input: {
    padding: "6px 0 7px",
  },
  outline: {
    borderRadius: 0,
  },
});

class ManageLocationsContent extends Component {
  state = {
    locations: [],
    isLoading: false,
    rowsPerPage: 5,
    page: 0,
    editedLocations: [],
    searchBar: "",
    noOfItems: 0,
    deleteDialog: false,
    selectedLocation: null,
  };

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.loadData();
  }

  loadData = () => {
    if (this.props.role == 99) {
      setTimeout(() => {
        getAllLocations()
          .then((res) => {
            let locations = [];
            res.data.map(
              (location) =>
                !isEmpty(location) &&
                location.account_id &&
                locations.push(location)
            );
            this.setState({
              locations,
              isLoading: false,
              editedLocations: _.slice(
                locations,
                this.state.page,
                this.state.rowsPerPage
              ),
              noOfItems: locations.length,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
    } else if (this.props.role == 30) {
      let allAssignedLocations = [];
      let myAssignedLocations = this.props.assigned_locations;
      let myAssignedAccounts = this.props.assigned_accounts;

      async function batchGetMyAssignedLocation(locations) {
        for (let locationIndex in locations) {
          const resolved = await getALocation(locations[locationIndex]);
          allAssignedLocations.push(resolved.data);
        }
      }

      async function batchGetLocationsForAssignedAccounts(accounts) {
        for (let accountIndex in accounts) {
          const resolved = await getLocationsForAcc(accounts[accountIndex]);
          allAssignedLocations.push(...resolved.data);
        }
      }

      batchGetMyAssignedLocation(myAssignedLocations)
        .then(() => batchGetLocationsForAssignedAccounts(myAssignedAccounts))
        .then(() => {
          let uniqueLocationsArray = _.uniqBy(
            allAssignedLocations,
            "location_id"
          );
          this.setState({
            isLoading: false,
            locations: uniqueLocationsArray,
            editedLocations: _.slice(
              uniqueLocationsArray,
              this.state.page,
              this.state.rowsPerPage
            ),
            noOfItems: uniqueLocationsArray.length,
          });
        })
        .catch((err) => console.log(err));
    } else if (this.props.role == 20) {
      let locArr = [];
      let myAssignedLocation = this.props.assigned_locations;
      getALocation(myAssignedLocation[0])
        // .then(res => console.log(res.data))
        .then((res) => {
          locArr.push(res.data);
          this.setState({
            isLoading: false,
            editedLocations: _.slice(
              locArr,
              this.state.page,
              this.state.rowsPerPage
            ),
            locations: locArr,
            noOfItems: locArr.length,
          });
        })
        .catch((err) => console.log(err));
    }
  };

  handleChangePage = (event, page) => {
    this.setState({
      page,
    });
    page == 0
      ? this.setState({
          editedLocations: _.slice(
            this.state.locations,
            page,
            this.state.rowsPerPage
          ),
        })
      : this.setState({
          editedLocations: _.slice(
            this.state.locations,
            this.state.rowsPerPage * page,
            this.state.rowsPerPage * page + this.state.rowsPerPage
          ),
        });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: event.target.value,
      editedLocations: _.slice(
        this.state.locations,
        this.state.page,
        event.target.value
      ),
    });
  };

  searchKeyword = () => {
    const { searchBar } = this.state;
    this.setState({ isLoading: true });

    setTimeout(() => {
      const results = this.state.editedLocations.filter(
        (item) =>
          item.name.toLowerCase().includes(searchBar.toLowerCase()) ||
          item.account_id.toLowerCase().includes(searchBar.toLowerCase())
      );
      this.setState({
        isLoading: false,
        editedLocations: results,
        noOfItems: results.length,
      });
    }, 3000);
  };

  resetSearch = () => {
    this.setState({ isLoading: true, searchBar: "" });
    setTimeout(() => {
      this.setState({
        isLoading: false,
        editedLocations: _.slice(
          this.state.locations,
          this.state.page,
          this.state.rowsPerPage
        ),
        noOfItems: this.state.locations.length,
      });
    }, 3000);
  };

  openDialog = (locationid) => {
    let location = this.state.locations.find(
      (item) => item.location_id === locationid
    );

    this.setState({
      deleteDialog: true,
      selectedLocation: location,
    });
  };

  closeDialog = () => {
    this.setState({
      deleteDialog: false,
      selectedLocation: null,
    });
  };

  deleteLocation = () => {
    const { selectedLocation } = this.state;
    this.setState({ isLoading: true });
    deleteALocation(selectedLocation.location_id).then(() => {
      setTimeout(() => {
        this.loadData();
      }, 3000);
    });
  };

  render() {
    const { classes } = this.props;
    const { locations, isLoading, editedLocations, searchBar } = this.state;

    return (
      <Paper square className="mt-12">
        <div className="flex justify-between items-center p-6">
          <Typography className="ml-12">LOCATION TABLE</Typography>
          {this.props.role !== 30 && this.props.role !== 20 && (
            <div className="mr-12">
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to={"/manage_locations/add_location"}
                classes={{ containedSecondary: classes.addLocationButton }}
              >
                Add Location
              </Button>
            </div>
          )}
        </div>
        <Divider className="bg-grey" />
        <div className="ml-12 m-12 w-1/2">
          <div className="flex justify-between items-center">
            <Typography>
              <i class="las la-filter mr-6"></i>
              <span className="text-grey">Filter</span>
            </Typography>
            <Tooltip title="Search by Location Name, Assigned Account">
              <IconButton>
                <i class="las la-info-circle"></i>
              </IconButton>
            </Tooltip>
          </div>
          <TextField
            label="Keyword"
            variant="outlined"
            margin="normal"
            value={searchBar}
            fullWidth
            onChange={(event) =>
              this.setState({ searchBar: event.target.value })
            }
            InputProps={{
              classes: {
                input: classes.input,
                notchedOutline: classes.outline,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <i class="las la-search"></i>
                </InputAdornment>
              ),
            }}
          />
          <div className="flex">
            <Button
              className="mx-6 text-white"
              classes={{ root: classes.searchButton }}
              onClick={this.searchKeyword}
            >
              Search
            </Button>
            <Button
              className="mx-6"
              classes={{ root: classes.resetButton }}
              onClick={this.resetSearch}
            >
              Reset
            </Button>
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center py-64">
            <CircularProgress size={48} />
          </div>
        ) : (
          <div className="flex flex-col">
            <div className={classes.root}>
              {/* <div className="flex ml-64 items-end"> */}
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {/* <TableCell /> */}
                    <TableCell>Location Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Assigned Account</TableCell>
                    <TableCell>Last Activity</TableCell>
                    <TableCell>Date Created</TableCell>
                    <TableCell className={classes.actionCell}>
                      Actions
                    </TableCell>
                    {/* <TableCell /> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {editedLocations.map(
                    ({
                      name,
                      location_id,
                      status,
                      account_id,
                      updated_on,
                      created_on,
                    }) => (
                      <TableRow key={location_id} hover={true}>
                        <TableCell>{name}</TableCell>
                        <TableCell>{status ? "Active" : "Inactive"}</TableCell>
                        <TableCell>{account_id}</TableCell>
                        <TableCell>
                          {updated_on
                            ? moment
                                .unix(updated_on._seconds)
                                .format("DD MMMM YYYY @ HH:mm")
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {created_on
                            ? moment
                                .unix(created_on._seconds)
                                .format("DD MMMM YYYY @ HH:mm")
                            : "-"}
                        </TableCell>
                        <TableCell className={classes.actionCell}>
                          <div className="flex justify-center">
                            <Button
                              variant="contained"
                              classes={{ contained: classes.editButton }}
                              component={Link}
                              to={{
                                pathname: `/manage_locations/${account_id}/location/${location_id}`,
                                state: { formManageLocation: true },
                              }}
                            >
                              <i class="las la-pen text-20"></i>
                            </Button>
                            <Button
                              variant="contained"
                              classes={{ contained: classes.deleteButton }}
                              onClick={() => this.openDialog(location_id)}
                            >
                              <i class="las la-trash text-20"></i>
                            </Button>
                          </div>
                          {/* <Button
                          component={Link}
                          // to={`/manage_account/${account_id}/location/${location_id}`}
                          to={{
                            pathname: `/manage_account/${account_id}/location/${location_id}`,
                            state: { fromManageLocation: true },
                          }}
                        >
                          <Icon>edit_icon</Icon>
                        </Button> */}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
              {/* </div> */}
            </div>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={this.state.noOfItems}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </div>
        )}
        {this.state.selectedLocation && (
          <Dialog open={this.state.deleteDialog} onClose={this.closeDialog}>
            <DialogTitle>Delete Location</DialogTitle>
            <DialogContent>
              <DialogContentText>{`Are you sure you want to delete ${this.state.selectedLocation.name}?`}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.deleteLocation()}>Confirm</Button>
              <Button onClick={this.closeDialog}>Cancel</Button>
            </DialogActions>
          </Dialog>
        )}
      </Paper>
    );
  }
}

function mapStateToProps({ auth }) {
  const { role, uid, data } = auth.user;
  const { assigned_accounts, assigned_locations } = data;

  return {
    role,
    uid,
    assigned_accounts,
    assigned_locations,
  };
}
export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, null)(ManageLocationsContent)
);
