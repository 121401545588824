import axios from "axios";

export const getAllUsers = () => axios.get(`/user`);

export const postNewUser = (body) => axios.post("/user/", body);

export const getAUser = (userid) => axios.get(`/user/${userid}`);

export const editUser = (userid, body) =>
  axios
    .put(`/user/${userid}`, body)
    .then((res) => axios.post(`/user/${userid}/claims`));

export const getUserOfAnAccount = (accid) =>
  axios.get(`/account/${accid}/user`);

export const deleteAUser = (userid) => axios.delete(`/user/${userid}`);
