import { authRoles } from "auth";
import ManageUsers from "./ManageUsers";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
export const UsersConfig = {
  settings: {
    layout: {
      config: {
        toolbar: {
          simple: false
        }
      }
    }
  },
  auth: authRoles.account,
  routes: [
    {
      path: "/manage_users",
      component: ManageUsers,
      exact: true
    },
    {
      path: "/manage_users/new_user",
      component: AddUser,
      exact: true
    },
    {
      path: "/manage_users/:userid",
      component: EditUser,
      exact: true
    }
  ]
};
