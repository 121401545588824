import "babel-polyfill";
import "typeface-muli";
import React from "react";
import ReactDOM from "react-dom";
import history from "./history";
import "./react-table-defaults";
import "./styles/index.css";
import JssProvider from "react-jss/lib/JssProvider";
import { create } from "jss";
import { createGenerateClassName, jssPreset } from "@material-ui/core/styles";
import registerServiceWorker from "./registerServiceWorker";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { routes } from "./fuse-configs/fuseRoutesConfig";
import { FuseLayout, FuseTheme, FuseAuthorization } from "@fuse";
import MainToolbar from "./main/MainToolbar";
import MainSimpleToolbar from "./main/MainSimpleToolbar";
import MainNavbarContent from "./main/MainNavbarContent";
import MainNavbarHeader from "./main/MainNavbarHeader";
import MainFooter from "./main/MainFooter";
import jssExtend from "jss-extend";
import store from "store";
import { Auth } from "auth";
import { CookiesProvider } from "react-cookie";
import { MIJWTAuth, MIJWTAuthorization } from "@massiveinfinity";
import "./fuse-configs/axiosConfig";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()]
});

jss.options.insertionPoint = document.getElementById("jss-insertion-point");
const generateClassName = createGenerateClassName();

ReactDOM.render(
  <JssProvider jss={jss} generateClassName={generateClassName}>
    <Provider store={store}>
      <CookiesProvider>
        <MIJWTAuth>
          <Router history={history}>
            <MIJWTAuthorization routes={routes}>
              <FuseTheme>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <FuseLayout
                    routes={routes}
                    toolbar={<MainToolbar />}
                    simpleToolbar={<MainSimpleToolbar />}
                    navbarContent={<MainNavbarContent />}
                  />
                </MuiPickersUtilsProvider>
              </FuseTheme>
            </MIJWTAuthorization>
          </Router>
        </MIJWTAuth>
      </CookiesProvider>
    </Provider>
  </JssProvider>,
  document.getElementById("root")
);

registerServiceWorker();
