import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import TriangleAlert from "../../../../assets/triangleAlert";
import BellAlert from "../../../../assets/bellAlert";
import InverterIcon from "../../../../assets/inverterIcon";
import _ from "lodash";

const constColorCode = {
  major: "#ec2027",
  minor: "#f79420",
  warning: "#f7ec13",
  offline: "#666766",
  online: "#6ABD45",
  default: "#666766",
};

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  heading: {
    color: constColorCode.default,
    fontSize: theme.typography.pxToRem(10),
    flexWrap: "wrap",
    padding: "5px",
    display: "flex",
    alignItems: "baseline",
  },
  deactivedExpansionPanel: {
    opacity: 0.6,
    backgroundColor: "#B0B2B0",
  },
  expansionPanel: {
    boxShadow: "none",
    border: "2px solid #DBDBDD",
    margin: "10px",
  },
  panelHeaderText: {
    fontSize: theme.typography.pxToRem(10),
    color: constColorCode.default,
  },
  labelText: {
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(20),
    paddingLeft: "1rem",
  },
  defaultText: {
    color: constColorCode.default,
    fontSize: theme.typography.pxToRem(12),
  },
  energyNumber: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(30),
    paddingLeft: "1rem",
    paddingRight: "0.5rem",
  },
  powerNumber: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(30),
    paddingLeft: "1rem",
    paddingRight: "0.5rem",
  },
  paddingLeftRight: {
    paddingLeft: "1rem",
    paddingRight: "0.5rem",
  },
});

class ControlledExpansionPanels extends Component {
  getInverterIconDisplay = (status) => {
    let iconToDisplay = "";
    switch (status) {
      case "major":
        iconToDisplay = (
          <InverterIcon colorCode={constColorCode.major}></InverterIcon>
        );
        break;
      case "minor":
        iconToDisplay = (
          <BellAlert colorCode={constColorCode.minor}></BellAlert>
        );
        break;
      case "warning":
        iconToDisplay = (
          <BellAlert colorCode={constColorCode.warning}></BellAlert>
        );
        break;
      case "online":
        iconToDisplay = (
          <InverterIcon colorCode={constColorCode.online}></InverterIcon>
        );
        break;
      case "offline":
        iconToDisplay = (
          <InverterIcon colorCode={constColorCode.offline}></InverterIcon>
        );
        break;
      default:
        iconToDisplay = (
          <InverterIcon colorCode={constColorCode.default}></InverterIcon>
        );
        break;
    }
    return iconToDisplay;
  };

  getAlertIconDisplay = (status) => {
    let iconToDisplay = "";
    switch (status) {
      case "major":
        iconToDisplay = (
          <TriangleAlert
            colorCode={constColorCode.major}
            height={20}
            width={30}
          ></TriangleAlert>
        );
        break;
      case "minor":
        iconToDisplay = (
          <BellAlert
            colorCode={constColorCode.minor}
            height={20}
            width={30}
          ></BellAlert>
        );
        break;
      case "warning":
        iconToDisplay = (
          <BellAlert
            colorCode={constColorCode.warning}
            height={20}
            width={30}
          ></BellAlert>
        );
        break;
      case "online":
        iconToDisplay = (
          <TriangleAlert
            colorCode={constColorCode.online}
            height={20}
            width={30}
          ></TriangleAlert>
        );
        break;
      case "offline":
        iconToDisplay = (
          <TriangleAlert
            colorCode={constColorCode.offline}
            height={20}
            width={30}
          ></TriangleAlert>
        );
        break;
      default:
        iconToDisplay = (
          <TriangleAlert
            colorCode={constColorCode.default}
            height={20}
            width={30}
          ></TriangleAlert>
        );
        break;
    }
    return iconToDisplay;
  };

  render() {
    const { classes, data } = this.props;
    const {
      inverter_id,
      inverter_serial_no,
      label,
      status,
      energy,
      power,
      frequency,
      current,
      temperature,
      voltage,
      alarmLog,
    } = data;

    let roundedUpPowerGenerated = (power * 1).toFixed(2);
    let roundedUpEnergyGenerated = (energy * 1).toFixed(2);
    return (
      <div className={classes.root}>
        <ExpansionPanel className={classes.expansionPanel}>
          <ExpansionPanelSummary
            className={
              status === "offline" ? classes.deactivedExpansionPanel : null
            }
          >
            <div className="w-full flex flex-col flex-wrap">
              <div className="w-full flex justify-start flex-wrap">
                <Typography className={classes.panelHeaderText}>
                  <strong>Serial No</strong>: {inverter_serial_no}
                </Typography>
              </div>

              <div className="w-full flex mt-8 items-center justify-between flex-wrap">
                <div className={classes.heading}>
                  <Typography className={classes.labelText}>{label}</Typography>
                </div>
                <div className={classes.heading}>
                  <Typography className={classes.defaultText}>
                    ENERGY GENERATION
                  </Typography>
                  <Typography className={classes.energyNumber}>
                    {roundedUpEnergyGenerated.toString().length < 10
                      ? roundedUpEnergyGenerated
                      : ((roundedUpEnergyGenerated * 1) / 1000).toFixed(2)}
                  </Typography>
                  <Typography className={classes.defaultText}>
                    {roundedUpEnergyGenerated.toString().length < 10
                      ? "kWh"
                      : "MWh"}
                  </Typography>
                </div>
                <div className={classes.heading}>
                  <Typography className={classes.defaultText}>
                    POWER GENERATION
                  </Typography>
                  <Typography className={classes.powerNumber}>
                    {roundedUpPowerGenerated.toString().length < 10
                      ? roundedUpPowerGenerated
                      : ((roundedUpPowerGenerated * 1) / 1000).toFixed(2)}
                  </Typography>
                  <Typography className={classes.defaultText}>
                    {roundedUpPowerGenerated.toString().length < 10
                      ? "kW"
                      : "MW"}
                  </Typography>
                </div>
                <div className={classes.heading}>
                  {/* {_.unionBy(testItem, 'severity').map(item => {
                    return this.getAlertIconDisplay(item.severity);
                  })} */}
                  {alarmLog &&
                    _.unionBy(alarmLog, "severity").map((item) =>
                      this.getAlertIconDisplay(item.severity)
                    )}
                </div>
                <div className={classes.heading}>
                  {this.getInverterIconDisplay(status)}
                </div>
              </div>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            className={
              status === "offline" ? classes.deactivedExpansionPanel : null
            }
          >
            <div className="flex flex-col w-full">
              <div className="flex justify-between">
                <Typography className={classes.defaultText}>
                  <strong>Inverter ID </strong>: {inverter_id}
                </Typography>
              </div>
              <div className="flex justify-between">
                <Typography className={classes.defaultText}>
                  <strong>Frequency </strong> : {(frequency * 1).toFixed(2)}
                </Typography>
              </div>
              <div className="flex justify-between">
                {current &&
                  Object.keys(current).map(function(key) {
                    return (
                      <div className={classes.heading}>
                        <Typography className={classes.defaultText}>
                          <strong>
                            {key
                              .split("_")
                              .join(" ")
                              .toUpperCase()}
                          </strong>
                        </Typography>
                        <Typography className={classes.paddingLeftRight}>
                          :
                        </Typography>
                        <Typography className={classes.defaultText}>
                          {(current[key] * 1).toFixed(2)}
                        </Typography>
                      </div>
                    );
                  })}
              </div>
              <div className="flex justify-between">
                {temperature &&
                  Object.keys(temperature).map(function(key) {
                    return (
                      <div className={classes.heading}>
                        <Typography className={classes.defaultText}>
                          <strong>
                            {key
                              .split("_")
                              .join(" ")
                              .toUpperCase()}
                          </strong>
                        </Typography>
                        <Typography className={classes.paddingLeftRight}>
                          :
                        </Typography>
                        <Typography className={classes.defaultText}>
                          {(temperature[key] * 1).toFixed(2)}
                        </Typography>
                      </div>
                    );
                  })}
              </div>
              <div className="flex justify-between">
                {voltage &&
                  Object.keys(voltage).map(function(key) {
                    return (
                      <div className={classes.heading}>
                        <Typography className={classes.defaultText}>
                          <strong>
                            {key
                              .split("_")
                              .join(" ")
                              .toUpperCase()}
                          </strong>
                        </Typography>
                        <Typography className={classes.paddingLeftRight}>
                          :
                        </Typography>
                        <Typography className={classes.defaultText}>
                          {(voltage[key] * 1).toFixed(2)}
                        </Typography>
                      </div>
                    );
                  })}
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

ControlledExpansionPanels.defaultProps = {
  alarmLog: [],
};

export default withStyles(styles)(ControlledExpansionPanels);
