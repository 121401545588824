import { get } from "lodash";

const fieldToError = ({ field, form: { touched, errors } }) => {
  const isFieldTouched = get(touched, field.name);
  const fieldError = isFieldTouched && get(errors, field.name);
  const isFieldErrored = Boolean(fieldError);

  return {
    error: isFieldErrored,
    errorMessage: fieldError,
  };
};

export default fieldToError;
