import React from 'react';
import LocationTab from './tabs/LocationTab';
import AlertsAlarmsTab from './tabs/AlertsAlarmsTab';
import AssetTab from './tabs/AssetTab';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TriangleAlertIcon from '../../assets/triangleAlert';
import EnerygyIcon from '../../assets/enerygyIcon';
import AssetIcon from '../../assets/assetIcon';

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    position: 'sticky'
  },
  appBar: {
    flex: 1,
    display: 'flex',
    background: 'white',
    position: '-webkit-sticky',
    position: 'sticky',
    top: 0,
    boxShadow: 'none'
  },
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});

class LocationPageContent extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { classes, theme, accountId, locationId, inverters } = this.props;
    return (
      <div
        style={{
          height: window.innerHeight,
          display: 'flex',
          flexDirection: 'column',
          flex: 1
        }}
      >
        <AppBar position="sticky" color="default" className={classes.appBar}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="false"
            variant="scrollable"
            style={{
              boxShadow: 'none',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Tab
              style={
                this.state.value === 0
                  ? { background: '#B3B4B3' }
                  : { opacity: 0.3 }
              }
              label={
                <div className="flex justfiy-center items-center">
                  <EnerygyIcon />
                  <Typography>ENERGY & POWER</Typography>
                </div>
              }
            />
            <Tab
              style={
                this.state.value === 1
                  ? { background: '#B3B4B3' }
                  : { opacity: 0.3 }
              }
              label={
                <div className="flex justfiy-center items-center">
                  <TriangleAlertIcon />
                  <Typography>ALERT & ALARMS</Typography>
                </div>
              }
            />
            <Tab
              style={
                this.state.value === 2
                  ? { background: '#B3B4B3' }
                  : { opacity: 0.3 }
              }
              label={
                <div className="flex justfiy-center items-center">
                  <AssetIcon />
                  <Typography>ASSETS & MAINTENANCE</Typography>
                </div>
              }
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.value}
          onChangeIndex={this.handleChangeIndex}
        >
          <TabContainer dir={theme.direction}>
            <LocationTab accountId={accountId} locationId={locationId} />
          </TabContainer>
          <TabContainer dir={theme.direction}>
            <AlertsAlarmsTab />
          </TabContainer>
          <TabContainer>
            <AssetTab
              accountId={accountId}
              locationId={locationId}
              inverters={inverters}
            />
          </TabContainer>
        </SwipeableViews>
      </div>
    );
  }
}

LocationPageContent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(LocationPageContent);
