import {authRoles} from 'auth';
import Profile from './Profile';

export const ProfileConfig = {
    settings: {
        layout: {
            config: {
                toolbar: {
                    simple: false
                },
                navbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    disply: false
                }
            }
        }
    },
    auth: authRoles.new,
    routes: [
        {
            path: '/profile',
            component: Profile
        }
    ]
}